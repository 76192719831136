import { createSlice } from "@reduxjs/toolkit";
import { getGeneralLevels, getGeneralLevelsByUser } from "./thunk";

export const initialState = {
    levels: [],
    error: {},
    loading: false,
    levelsByUser: { levels: [], error: {}, loading: false },
};

const LevelChartSlice = createSlice({
    name: "GeneralLevels",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGeneralLevels.pending, (state, action) => {
            state.levels = [];
            state.loading = true;
        });
        builder.addCase(getGeneralLevels.fulfilled, (state, action) => {
            state.levels = action.payload;
            state.loading = false;
        });
        builder.addCase(getGeneralLevels.rejected, (state, action) => {
            state.error = action?.error?.message || null;
            state.loading = false;
        });
        builder.addCase(getGeneralLevelsByUser.pending, (state, action) => {
            state.levelsByUser.levels = [];
            state.levelsByUser.loading = true;
        });
        builder.addCase(getGeneralLevelsByUser.fulfilled, (state, action) => {
            state.levelsByUser.levels = action.payload;
            state.levelsByUser.loading = false;
        });
        builder.addCase(getGeneralLevelsByUser.rejected, (state, action) => {
            state.levelsByUser.error = action?.error?.message || null;
            state.levelsByUser.loading = false;
        });
    },
});

export const { } = LevelChartSlice.actions;

export default LevelChartSlice.reducer;
