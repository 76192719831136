import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
import UserLoginReducer from "./login/reducer";
import DepartmentReducer from "./departments/reducer";
import JobDescriptionReducer from "./jobDescription/reducer";
import JobArchitectureReducer from "./jobArchitecture/reducer";
import LevelsReducer from "./levels/reducer";
import GeneralLevelsReducer from "./generalLevels/reducer";
import LevelMappingReducer from "./levelMapping/reducer";
import MarketPricing from "./marketPricing/reducer";
import Benchmarking from "./benchmarking/reducer";
import MarketRanges from "./Ranges/reducer";
import IncumbentAnalysis from "./IncumbentAnalysis/reducer";
import Dashboard from "./dashboard/reducer";
import SurveyData from "./surveyData/reducer";
import InternalFields from "./InternalFields/reducer";
import Payzone from "./Payzone/reducer";
import GeoPayzone from "./GeoPayzone/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  // Login: LoginReducer,
  Login: UserLoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Department: DepartmentReducer,
  Dashboard: Dashboard,
  JobDescription: JobDescriptionReducer,
  JobArchitecture: JobArchitectureReducer,
  Levels: LevelsReducer,
  GeneralLevels: GeneralLevelsReducer,
  LevelMapping: LevelMappingReducer,
  Benchmarking: Benchmarking,
  MarketPricing: MarketPricing,
  MarketRanges: MarketRanges,
  IncumbentAnalysis: IncumbentAnalysis,
  SurveyData: SurveyData,
  InternalFields: InternalFields,
  Payzone: Payzone,
  GeoPayzone: GeoPayzone,
});

export default rootReducer;
