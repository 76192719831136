import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { parseQueryParams } from "../../utils/queryParams";
import DisplayFilters from "../Common/DisplayFilters";

const TableColumnFilter = ({
  columns,
  toggleVisibleColumns,
  isFilterBtn,
  filterAction,
  dispatchTableData,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns?.map((column) => ({
      ...column,
    }))
  );
  const queryParams = parseQueryParams();
  const { page, limit, ...filterQueryParams } = queryParams;

  useEffect(() => {
    toggleVisibleColumns(visibleColumns);
  }, [visibleColumns, toggleVisibleColumns]);

  const handleToggle = (accessor) => {
    const updatedColumns = visibleColumns.map((column) =>
      column.accessor === accessor
        ? { ...column, visible: !column.visible }
        : column
    );
    const count = visibleColumns?.filter((column) => !column?.visible).length;
    const isFilterActive = visibleColumns?.some((column) => !column?.visible);
    setVisibleColumns(updatedColumns);
    toggleVisibleColumns(accessor);
    setActiveFilter(true);
    setActiveFilterCount(count);
    setIsFilterActive(isFilterActive);
  };

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleShowAll = () => {
    const showAllColumns = columns?.map((column) => ({
      ...column,
      visible: true,
    }));
    setVisibleColumns(showAllColumns);
    toggleVisibleColumns(null, false, true);
    setShowDropdown(showDropdown);
    setActiveFilter(true);
  };

  const handleReset = () => {
    setVisibleColumns([...columns]);
    toggleVisibleColumns(null, true);
    setShowDropdown(showDropdown);
    setActiveFilter(false);
  };

  const handleButtonClick = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    if (type == "show-all") {
      handleShowAll();
    } else {
      handleReset();
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        {isFilterBtn && (
          <div
            className="icon-container text-nowrap d-flex align-items-center"
            role="button"
            onClick={filterAction}
          >
            <span className="ranges_filter_columns">
              <span className="fw-bold me-1 text-primary">
                {Object?.entries(filterQueryParams)?.length}{" "}
              </span>
              {"  "}Filters
            </span>
          </div>
        )}
        <UncontrolledDropdown
          isOpen={showDropdown}
          toggle={handleDropdown}
          className="column-visibility"
        >
          <DropdownToggle
            tag="div"
            className={`filter-icon ${showDropdown ? "active" : ""}`}
            onClick={() => setShowDropdown((prevState) => !prevState)}
            role="button"
          >
            <div className="icon-container mx-2 text-nowrap d-flex align-items-center">
              <span className="hidden_columns">
                <span className="fw-bold me-1">{activeFilterCount}</span> hidden
                columns
              </span>
              {isFilterActive && (
                <div className="active bg-danger d-none"></div>
              )}
            </div>
          </DropdownToggle>
          {showDropdown && (
            <DropdownMenu>
              <ul className="dropdown-list mb-0 ps-0">
                <ReactSortable
                  filter=".addImageButtonContainer"
                  dragClass="sortableDrag"
                  list={visibleColumns}
                  setList={setVisibleColumns}
                  animation="200"
                  easing="ease-out"
                >
                  {visibleColumns?.map((column) => (
                    <DropdownItem key={column.accessor}>
                      <div
                        className="form-check d-flex align-items-center"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={column.visible}
                          onChange={() => handleToggle(column?.accessor)}
                          id={`toggle-${column.accessor}`}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor={`toggle-${column.accessor}`}
                          onClick={() => handleToggle(column?.accessor)}
                        >
                          {column.Header}
                        </label>
                      </div>
                    </DropdownItem>
                  ))}
                </ReactSortable>
                <hr className="border border-dashed border-end-0 border-top-0 border-start-0 border-dark m-1" />
                <div className="d-flex justify-content-center align-items-center py-1">
                  <button
                    onClick={(event) => {
                      handleButtonClick(event, "show-all");
                    }}
                    className="w-75 btn-link  btn  btn-sm fs-6 badge badge-soft-secondary"
                  >
                    Show All
                  </button>
                </div>
              </ul>
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      </div>
      <Row className="mx-1">
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex flex-wrap mt-2 justify-content-end px-2 align-items-center"
        >
          <DisplayFilters dispatchTableData={dispatchTableData} isUserID={false}/>
        </Col>
      </Row>
    </div>
  );
};

export default TableColumnFilter;
