import React, { useEffect } from "react";
import {
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Card,
  CardBody,
  UncontrolledAccordion,
  AccordionHeader,
  AccordionBody,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ViewItem from "../../Components/Custom/ViewItem";
import { IdView } from "../../Components/Custom/IdView";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeById, getJobArchitectureById } from "../../slices/thunks";
import ColumnLoader from "../../Components/Custom/ColumnLoader";
import { active } from "sortablejs";
import RangeSpread from "../../Components/Custom/RangeSpread";
import ColumnCharts from "../../Components/Charts/ColumnCharts";
import SimpleBar from "simplebar-react";
import { formatSalary } from "../../utils/dataFormat";
import DownloadView from "../../Components/Custom/Download";

export const IncumbentView = ({ isOpen, onCloseClick, id }) => {
  const dispatch = useDispatch();
  const storeActiveIncumbentAnalysis = useSelector(
    (state) => state?.IncumbentAnalysis?.active
  );

  useEffect(() => {
    if (isOpen && id) {
      dispatch(getEmployeeById(id));
    }
  }, [isOpen]);

  return (
    <div>
      <Modal
        className="incumbent-impact-modal"
        fade={true}
        isOpen={isOpen}
        toggle={onCloseClick}
        centered={true}
        size="xl"
      >
        <ModalHeader
          className="bg-secondary p-3 px-4"
          toggle={() => onCloseClick(!isOpen)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-white d-flex align-items-center">
              Incumbent Impact Analysis
              <IdView id={id} />
            </span>
            <DownloadView
              id={"modal-body"}
              isOpen={isOpen}
              fileName={`Employee - ${storeActiveIncumbentAnalysis?.employee?.empName}`}
              isWordDoc={false}
            />
          </div>
        </ModalHeader>
        <SimpleBar className="modalbody-scroll">
          <ModalBody className="p-4" id="modal-body">
            <Row>
              <Col md={12}>
                <UncontrolledAccordion
                  defaultOpen={[1]}
                  stayOpen
                  className="bg-light modal-rounded"
                >
                  <div className="modal-rounded employee-card">
                    <AccordionHeader
                      targetId={1}
                      className="bg-white modal-rounded"
                    >
                      <h5 className="mb-0">
                        {storeActiveIncumbentAnalysis?.employee?.empName || ""}
                      </h5>
                    </AccordionHeader>
                    <AccordionBody
                      accordionId={1}
                      className="bg-white rounded-bottom-4 border border-0 border-light"
                    >
                      <div className="d-flex">
                        <Badge className="details-badge px-3 py-2 bg-light me-2 fw-normal rounded-pill mb-2 d-flex align align-items-center">
                          <p className="mb-0 text-dark fs-6">
                            <span className="text-dark me-1">
                              Department :{" "}
                            </span>
                            <span className="text-dark fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee
                                ?.department || ""}
                            </span>
                          </p>
                        </Badge>

                        <Badge className="details-badge px-3 py-2 bg-light fw-normal rounded-pill me-2 mb-2 fs-6 d-flex align align-items-center">
                          <p className="mb-0 text-dark">
                            <span className="text-dark me-1 ">
                              Position Code :{" "}
                            </span>
                            <span className="fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee
                                ?.positionCode || ""}
                            </span>
                          </p>
                        </Badge>
                        <Badge className="details-badge px-3 py-2 bg-light fw-normal rounded-pill mb-2 me-2 fs-6 d-flex align align-items-center">
                          <p className="mb-0 text-dark">
                            <span className="text-dark me-1 ">Job Code : </span>

                            <span className="fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee
                                ?.jobCode || ""}
                            </span>
                          </p>
                        </Badge>
                        <Badge className="details-badge px-3 py-2 bg-light me-2 fw-normal rounded-pill mb-2 fs-6 d-flex align align-items-center">
                          <p className="mb-0 text-dark">
                            <span className="text-dark me-1 ">State : </span>
                            <span className="fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.state ||
                                ""}
                            </span>
                          </p>
                        </Badge>
                        <Badge className="details-badge px-3 py-2 bg-light me-2 fw-normal rounded-pill mb-2 fs-6 d-flex align align-items-center">
                          <p className="mb-0 text-dark">
                            <span className="text-dark me-1 ">City : </span>
                            <span className="fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.city ||
                                ""}
                            </span>
                          </p>
                        </Badge>
                      </div>
                      <Row>
                        <Col md={12}>
                          <div>
                            <div className=" d-flex py-2 align-items-center my-1 mt-2 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0 ">
                              <div>
                                <h6>Job Title</h6>
                              </div>
                              <div>
                                <h6 className="">
                                  {storeActiveIncumbentAnalysis?.employee
                                    ?.jobTitle || ""}
                                </h6>
                              </div>
                            </div>
                            <div className=" d-flex py-2 align-items-center my-1 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0">
                              <div>
                                <h6>Current Salary</h6>
                              </div>
                              <div>
                                <h6 className="">
                                  $
                                  {formatSalary(
                                    storeActiveIncumbentAnalysis?.employee
                                      ?.currentSalary
                                  ) || ""}
                                </h6>
                              </div>
                            </div>
                            <div className=" d-flex  py-2 align-items-center my-1 justify-content-between">
                              <div>
                                <h6>Manager</h6>
                              </div>
                              <div>
                                <h6 className="">
                                  {storeActiveIncumbentAnalysis?.employee
                                    ?.manager || "-"}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AccordionBody>
                  </div>
                </UncontrolledAccordion>

                {storeActiveIncumbentAnalysis?.employee?.message ==
                  "Please get Benchmarks" && (
                  <div className="bg-white rounded p-2 text-center">
                    <h6 className="mb-0">
                      {storeActiveIncumbentAnalysis?.employee?.message}
                    </h6>{" "}
                  </div>
                )}
              </Col>
            </Row>
            {!(
              storeActiveIncumbentAnalysis?.employee?.message ==
              "Please get Benchmarks"
            ) && (
              <Row>
                {" "}
                <Col md={6} className="pay-data-container">
                  <Card className="rounded-4">
                    <CardBody className="p-0">
                      <div className="px-3 py-3 ">
                        <h5 className="mb-0 text-primary">Pay Data</h5>
                      </div>
                      <div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 mt-2 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0 ">
                          <div>
                            <h6 className="mb-0">PayZone</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.payData
                                ?.payZone || "-"}
                            </h6>
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0">
                          <div>
                            <h6 className="mb-0">Geo Diff %</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.payData
                                ?.geoDiff || "0"}
                              %
                            </h6>
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between">
                          <div>
                            <h6 className="mb-0">National Midpoint</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.payData
                                ?.nationalMidPoint
                                ? `$${formatSalary(
                                    storeActiveIncumbentAnalysis?.employee
                                      ?.payData?.nationalMidPoint
                                  )}`
                                : "-"}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="rounded-4">
                    <CardBody className="p-0">
                      <div className="px-3 py-3 ">
                        <h5 className="mb-0 text-primary">
                          Pay Range after Geo Diff Adjustment
                        </h5>
                      </div>
                      <div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 mt-2 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0 ">
                          <div>
                            <h6 className="mb-0">Minimum</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              $
                              {formatSalary(
                                parseInt(
                                  storeActiveIncumbentAnalysis?.employee
                                    ?.payAdjustments?.minimum
                                )
                              ) || "-"}
                            </h6>
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0">
                          <div>
                            <h6 className="mb-0">Midpoint</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              $
                              {formatSalary(
                                parseInt(
                                  storeActiveIncumbentAnalysis?.employee
                                    ?.payAdjustments?.midPoint
                                )
                              ) || "-"}
                            </h6>
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between">
                          <div>
                            <h6 className="mb-0">Maximum</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              $
                              {formatSalary(
                                parseInt(
                                  storeActiveIncumbentAnalysis?.employee
                                    ?.payAdjustments?.maximum
                                )
                              ) || "-"}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="rounded-4">
                    <CardBody className="p-0">
                      <div className="px-3 py-3 ">
                        <h5 className="mb-0 text-primary">
                          Compa-Ratio Analysis
                        </h5>
                      </div>
                      <div style={{ height: "300px" }}>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 mt-2 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0 ">
                          <div>
                            <h6 className="mb-0">Compa-Ratio of the EE</h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.analysis
                                ?.compRationEE || "-"}
                            </h6>
                          </div>
                        </div>
                        <div className=" mx-3 py-2 align-items-center my-1 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0 pb-3">
                          <div>
                            <h6 className="mb-0">Range Penetration</h6>
                          </div>
                          <div>
                            <RangeSpread
                              min={parseInt(
                                storeActiveIncumbentAnalysis?.employee
                                  ?.payAdjustments?.minimum
                              )}
                              max={parseInt(
                                storeActiveIncumbentAnalysis?.employee
                                  ?.payAdjustments?.maximum
                              )}
                              mid={parseInt(
                                storeActiveIncumbentAnalysis?.employee
                                  ?.payAdjustments?.midPoint
                              )}
                              salary={
                                storeActiveIncumbentAnalysis?.employee
                                  ?.currentSalary
                              }
                              percentage={
                                storeActiveIncumbentAnalysis?.employee?.analysis
                                  ?.compRationEE
                              }
                            />
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0">
                          <div>
                            <h6 className="mb-0">Outlier</h6>
                          </div>
                          <div>
                            <h6
                              className={
                                storeActiveIncumbentAnalysis?.employee?.analysis
                                  ?.outlier == "Within Range"
                                  ? "text-success mb-0 fw-semibold"
                                  : "text-danger mb-0 fw-semibold"
                              }
                            >
                              {storeActiveIncumbentAnalysis?.employee?.analysis
                                ?.outlier || "-"}
                            </h6>
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between border-bottom-dashed border border-1 border-top-0 border-end-0 border-start-0">
                          <div>
                            <h6 className="mb-0">
                              % increase for "Below Min" cases
                            </h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.analysis
                                ?.increasePercentage || "-"}
                            </h6>
                          </div>
                        </div>
                        <div className=" d-flex mx-3 py-2 align-items-center my-1 justify-content-between">
                          <div>
                            <h6 className="mb-0">
                              $ increase for "Below Min" cases
                            </h6>
                          </div>
                          <div>
                            <h6 className="mb-0 fw-semibold">
                              {storeActiveIncumbentAnalysis?.employee?.analysis
                                ?.increaseSalary
                                ? `$${storeActiveIncumbentAnalysis?.employee?.analysis?.increaseSalary}`
                                : "-"}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="rounded-4">
                    <CardBody className="p-0">
                      <div className="px-3 py-3 ">
                        <h5 className="mb-0 text-primary">
                          Internal Equity Analysis
                        </h5>
                      </div>

                      <div>
                        {" "}
                        <ColumnCharts
                          dataColors='["#143D59"]'
                          data={
                            storeActiveIncumbentAnalysis?.employee?.empID
                              ? [
                                  storeActiveIncumbentAnalysis?.employee
                                    ?.internalEquity
                                    ?.highest_salary_in_payzone_department,
                                  storeActiveIncumbentAnalysis?.employee
                                    ?.internalEquity
                                    ?.current_salary_of_employee,
                                  storeActiveIncumbentAnalysis?.employee
                                    ?.internalEquity
                                    ?.lowest_salary_in_payzone_department,
                                ]
                              : []
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </ModalBody>
          {storeActiveIncumbentAnalysis?.loading && <ColumnLoader />}
        </SimpleBar>
      </Modal>
    </div>
  );
};
export default IncumbentView;
