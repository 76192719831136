import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { FileImport } from "../../Components/Custom/FileImport";
import ImportSurveyData from "./importModal";
import MapSurveyData from "./mapModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import { FileList } from "./Files";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getExternalSurveyData } from "../../slices/thunks";
import moment from "moment";
import Loader from "../../Components/Custom/Loader";
import PageHeader from "../../Components/Common/Header";

export const SurveyData = () => {
  const [importModal, setImportModal] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalType, setModalType] = useState("data-set");
  const [hoveredId, setHoveredId] = useState(null);
  const [activeID, setActiveID] = useState(null);
  const dispatch = useDispatch();
  const storeSurveyData = useSelector((state) => state?.SurveyData?.SurveyData);
  const [activeData, setActiveData] = useState({});

  useEffect(() => {
    dispatch(getExternalSurveyData());
  }, []);

  return (
    <div>
      <div className="page-content survey-data">
        <PageHeader
          pageTitle={"Survey Data"}
          isAddBtn={false}
          isImportBtn={false}
        />
        <Row className=" d-flex flex-wrap">
          <Col sm={6} md={6} lg={4} xl={4}>
            <Card className="card-container  mb-3">
              <CardBody>
                <div
                  className="d-flex justify-content-center border border-2 border-dashed rounded"
                  role="button"
                  onClick={() => {
                    setImportModal(!importModal);
                    setModalType("data-set");
                  }}
                >
                  <i
                    className=" text-info fw-bold ri-add-line align-bott fs-1 text-center my-3 lh-lg"
                    role="button"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {storeSurveyData?.List?.length > 0 &&
            storeSurveyData?.List?.map((ele, ind) => {
              return (
                <Col
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  className=""
                  onMouseEnter={() => {
                    setHoveredId(ind);
                  }}
                  onMouseLeave={() => {
                    setHoveredId(null);
                  }}
                >
                  <Card className="card-container  mb-3" key={ind}>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div className="data-container">
                            <div>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5 className="survey-title mb-0 text-uppercase text-truncate-two-lines">
                                  {ele?.providerName || "-"}
                                </h5>
                                <div className="d-flex align-items-center">
                                  {ele?.mapped ? (
                                    <Button
                                      role="presentation"
                                      className="border border-0 btn btn-sm text-success rounded p-1 px-2 bg-soft-success "
                                      onClick={() => {
                                        setMapModal(!mapModal);
                                        setActiveID(ele?.extSurveyID);
                                      }}
                                    >
                                      Mapped
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn btn-sm btn-secondary"
                                      color="secondary"
                                      onClick={() => {
                                        setMapModal(!mapModal);
                                        setActiveID(ele?.extSurveyID);
                                      }}
                                    >
                                      Map fields
                                    </Button>
                                  )}
                                  <UncontrolledDropdown
                                    direction="end"
                                    className="custom-action-dropdown "
                                  >
                                    <DropdownToggle
                                      tag="button"
                                      className="btn btn-link text-muted fw-semibold pe-0 ps-1  py-0 text-decoration-none fs-4"
                                    >
                                      <i className="bx bx-dots-vertical-rounded "></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end custom-dropdown py-0">
                                      <DropdownItem
                                        className="d-flex align-items-center p-3 "
                                        onClick={() => {
                                          setImportModal(!importModal);
                                          setModalType("data-sub-set");
                                          setActiveData(ele);
                                        }}
                                      >
                                        <i className="bx bx-plus fs-5 me-2 text-dark"></i>
                                        <h6 className="mb-0">
                                          {" "}
                                          Add New Data Cut
                                        </h6>
                                      </DropdownItem>
                                      <div
                                        className={"dropdown-divider my-0"}
                                      ></div>
                                      <DropdownItem className="d-flex align-items-center p-3 ">
                                        <i className="ri-delete-bin-5-line align-bottom me-2 text-dark"></i>
                                        <h6 className="mb-0">Delete</h6>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </div>
                            </div>
                            <div className="my-2">
                              <FileList data={ele} />
                              <p className="mb-0 text-muted my-2">
                                Total Records:{" "}
                                <span className="fw-medium text-dark">
                                  {ele?.totalCount}
                                </span>
                              </p>
                            </div>
                            <div className="d-flex justify-content-between  flex-wrap">
                              <p className="mb-0 text-muted ">
                                Created At:{" "}
                                <span className="text-dark fw-medium">
                                  {moment(ele?.createAt).format("Do MMM YY")}
                                </span>
                              </p>
                              <p className="mb-0 text-muted ">
                                Updated At:{" "}
                                <span className="text-dark fw-medium">
                                  {moment(ele?.updatedAt).format("Do MMM YY")}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}

          <ImportSurveyData
            show={importModal}
            onCloseClick={setImportModal}
            onClick={() => {}}
            modalType={modalType}
            activeData={activeData}
          />
          <MapSurveyData
            show={mapModal}
            onCloseClick={setMapModal}
            onClick={() => {}}
            activeID={activeID}
          />
          <DeleteModal show={deleteModal} onCloseClick={setDeleteModal} />
          {storeSurveyData?.loading && <Loader />}
        </Row>
      </div>
    </div>
  );
};
export default SurveyData;
