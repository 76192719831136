import React from "react";
import { RiseLoader } from "react-spinners";
const ColumnLoader = () => {
    return (
        <React.Fragment>
            <div className="loader-col">
                <RiseLoader color="white" />
            </div>
        </React.Fragment>
    );
};

export default ColumnLoader;
