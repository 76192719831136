import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMarketRanges as getAllMarketRangesApi } from "../../helpers/custom_backend_helper";

export const getMarketRanges = createAsyncThunk(
  "market/getAllMarketPricing",
  async (query) => {
    try {
      const response = await getAllMarketRangesApi(query);
      const data = await response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
