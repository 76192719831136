import { createSlice } from "@reduxjs/toolkit";
import {
  getJobDescriptions,
  getJobDescriptionById,
  getMarketPricing,
  getMarketData,
} from "./thunk";

export const initialState = {
  marketPricing: { marketPricing: {}, error: {}, loading: false },
  marketData: {
    marketData: [],
    error: {},
    loading: false,
    meta: {
      limit: 10,
      page: 1,
      count: null,
      totalPages: null,
    },
  },
};

const MarketPricingSlice = createSlice({
  name: "MarketPricing",
  initialState,
  reducers: {
    updateMarketData(state, action) {
      state.marketData.marketData = state?.marketData?.marketData?.map((item) =>
        item?.jobDescriptionId == action?.payload?.jobDescriptionId
          ? { ...item, ...action?.payload }
          : item
      );
    },
    pushMarketPricing(state, action) {
      state.marketPricing.marketPricing = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketPricing.pending, (state, action) => {
      state.marketPricing.marketPricing = [];
      state.marketPricing.loading = true;
    });
    builder.addCase(getMarketPricing.fulfilled, (state, action) => {
      state.marketPricing.marketPricing = action.payload;
      state.marketPricing.loading = false;
    });
    builder.addCase(getMarketPricing.rejected, (state, action) => {
      state.marketPricing.error = action?.error?.message || null;
      state.marketPricing.loading = false;
    });
    builder.addCase(getMarketData.pending, (state, action) => {
      state.marketData.marketData = [];
      state.marketData.loading = true;
    });
    builder.addCase(getMarketData.fulfilled, (state, action) => {
      state.marketData.marketData = action.payload.data;
      state.marketData.loading = false;
      state.marketData.meta = action.payload.meta;
    });
    builder.addCase(getMarketData.rejected, (state, action) => {
      state.marketData.error = action?.error?.message || null;
      state.marketData.loading = false;
    });
  },
});

export const { updateMarketData, pushMarketPricing} = MarketPricingSlice.actions;

export default MarketPricingSlice.reducer;
