import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllGeoPayzones as getAllGeoPayzonesApi,
  getGeoPayzoneByID as getGeoPayzoneByIDApi,
} from "../../helpers/custom_backend_helper";

export const getGeoPayzones = createAsyncThunk(
  "payzone/getAllGeoPayzones",
  async () => {
    try {
      const response = await getAllGeoPayzonesApi();
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getGeoPayzoneById = createAsyncThunk(
  "payzone/getAllGeoPayzoneById",
  async (id) => {
    try {
      const response = await getGeoPayzoneByIDApi(id);
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
