import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLevelMappingByUser as getAllLevelMappingByUserApi, getDefaultMapping as getDefaultMappingApi } from "../../helpers/custom_backend_helper";
export const getLevelMappingByUser = createAsyncThunk(
    "levelMapping/getAllLevelMappingByUser",
    async (id) => {
        try {
            const response = await getAllLevelMappingByUserApi(id);
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);
export const getDefaulLeveltMapping = createAsyncThunk(
    "levelMapping/getDefaulLeveltMapping",
    async (body) => {
        try {
            const response = await getDefaultMappingApi(body);
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);