import React from "react";
import { RiseLoader, CircleLoader } from "react-spinners";
const Loader = () => {
  return (
    <React.Fragment>
      {/* <div id="global_spinner">
        <CircleLoader color="#faaa1d" size={100} />
      </div> */}
      <div class="pl">
          <div class="pl__bars">
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
            <div class="pl__bar">
              <div class="pl__bar-s"></div>
              <div class="pl__bar-t"></div>
              <div class="pl__bar-l"></div>
              <div class="pl__bar-r"></div>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};

export default Loader;
