import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import ActionButton from "../../Components/Common/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllJobArchitectures,
  getDashboardCount,
  getJobArchitectures,
  getJobFamily,
} from "../../slices/thunks";
import Loader from "../../Components/Custom/Loader";
import {
  PostJobArchitectureFile,
  putJobProfile,
} from "../../helpers/custom_backend_helper";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import { FileImport } from "../../Components/Custom/FileImport";
import { getUserData } from "../../utils/auth";
import StatusBadge from "../../Components/Custom/StatusBadge";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { buildOptions, getOption } from "../../utils/dataFormat";
import { updateJobProfile } from "../../slices/jobArchitecture/reducer";
import { PulseLoader } from "react-spinners";
import useUpdateRouteWithQueryParams from "../../Components/Hooks/queryParams";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import JAFilters from "./Filters";
import { useLocation } from "react-router-dom";

export const JobArchitecture = () => {
  const dispatch = useDispatch();
  const userData = getUserData();
  const location = useLocation();
  const storeJobArchitecture = useSelector((state) => state.JobArchitecture);
  const storeFamilyGroup = useSelector(
    (state) => state?.JobArchitecture?.jobFamilyGroup
  );
  const storeFamily = useSelector((state) => state?.JobArchitecture?.jobFamily);
  const storeCount = useSelector((state) => state?.Dashboard?.count);
  const updateRouteWithParams = useUpdateRouteWithQueryParams();
  const queryParams = parseQueryParams();
  const { page, limit, ...filterQueryParams } = queryParams;
  const [activeId, setActiveId] = useState();
  const [loading, setLoading] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [familyGroup, setFamilyGroup] = useState(null);
  const [family, setFamily] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [inputValue, setInputValue] = useState();
  const [pageIndex, setPageIndex] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);

  const columns = [
    {
      Header: "Job Family Group",
      accessor: "familyGroup",
      filterable: true,
      visible: true,
      maxWidth: "250px",
      Cell: (cellProps) => {
        return (
          <>
            {cellProps.row.original?.profileId == isEdit ? (
              <ReactSelect
                id="groupId"
                className=" mb-0"
                name="groupId"
                value={familyGroup}
                options={buildOptions(
                  storeFamilyGroup?.familyGroup,
                  "familyGroup",
                  "groupId"
                )}
                onChange={(event) => {
                  setActiveId(cellProps.row.original?.profileId);
                  setFamilyGroup(event);
                  setFamily(null);
                  dispatch(getJobFamily(`${userData?.userId}/${event?.value}`));
                }}
              />
            ) : (
              <>{cellProps?.value || "-"}</>
            )}
          </>
        );
      },
    },
    {
      Header: " Job Family",
      accessor: "familyName",
      filterable: true,
      visible: true,
      maxWidth: "250px",
      Cell: (cellProps) => {
        return (
          <>
            {cellProps.row.original?.profileId == isEdit ? (
              <ReactSelect
                id="familyId"
                className=" mb-0"
                name="familyId"
                value={family}
                isLoading={
                  cellProps.row.original?.profileId == activeId &&
                  storeFamily?.loading
                }
                options={buildOptions(
                  storeFamily?.family,
                  "familyName",
                  "familyId"
                )}
                onChange={(event) => {
                  setActiveId(cellProps.row.original?.profileId);
                  setFamily(event);
                }}
              />
            ) : (
              <>{cellProps?.value || "-"}</>
            )}
          </>
        );
      },
    },
    {
      Header: "Position ID",
      accessor: "positionCode",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return <>{cellProps?.value || "-"}</>;
      },
    },
    {
      Header: "Position Title",
      accessor: "positionTitle",
      filterable: true,
      visible: true,
      maxWidth: "250px",
      // isInputField: activeId == isEdit ? true : false,
      isInputField: true,
      Cell: (cellProps) => {
        return <>{cellProps?.value || "-"}</>;
      },
    },
    {
      Header: "Job Id",
      accessor: "jobCode",
      filterable: true,
      visible: true,
    },
    {
      Header: "Job Title",
      accessor: "jobTitle",
      filterable: true,
      visible: true,
      maxWidth: "200px",
    },

    {
      Header: "Job Level",
      accessor: "level",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return <>{cellProps?.value || "-"}</>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return <StatusBadge status={cellProps?.value} />;
      },
    },
    {
      Header: "Department",
      accessor: "department",
      filterable: true,
      visible: false,
    },
    {
      Header: "Location",
      accessor: "location",
      filterable: true,
      visible: false,
    },
    {
      Header: "Actions",
      accessor: "Actions",
      filterable: false,
      visible: true,
      Cell: (cellProps) => {
        return (
          <div>
            <ul className="list-inline hstack gap-1 mb-0 px-3">
              {isEdit == cellProps.row.original?.profileId ? (
                <>
                  {loading ? (
                    <PulseLoader size={8} color="#08569a" />
                  ) : (
                    <>
                      <li className="list-inline-item d-fle">
                        <Button
                          className="btn btn-sm theme-cancel-btn mb-1 w-100 P-0"
                          onClick={() => {
                            setIsEdit(null);
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          className="btn btn-sm px-2 w-100"
                          onClick={() => {
                            handleSubmit(cellProps?.row?.original);
                          }}
                          disabled={
                            inputValue && familyGroup?.value && family?.value
                              ? false
                              : true
                          }
                        >
                          Save
                        </Button>
                      </li>
                    </>
                  )}
                </>
              ) : (
                <>
                  <li className="list-inline-item edit">
                    <ActionButton
                      action="edit"
                      onClick={() => {
                        setActiveId(cellProps.row.original?.profileId);
                        setIsEdit(cellProps.row.original?.profileId);
                        cellProps.row.original?.positionTitle
                          ? setInputValue(cellProps.row.original?.positionTitle)
                          : setInputValue(null);
                        cellProps?.row?.original?.groupId
                          ? setFamilyGroup(
                              getOption(
                                cellProps.row.original?.familyGroup,
                                cellProps?.row?.original?.groupId
                              )
                            )
                          : setFamilyGroup(null);
                        cellProps?.row?.original?.familyId
                          ? setFamily(
                              getOption(
                                cellProps.row.original?.familyName,
                                cellProps?.row?.original?.familyId
                              )
                            )
                          : setFamily(null);
                      }}
                    />
                  </li>
                </>
              )}
            </ul>
          </div>
        );
      },
    },
  ];

  const downloadColumns = [
    {
      Header: "Job Description",
      accessor: "jobDescription",
      visible: true,
    },
  ];

  useEffect(() => {
    if (Object?.entries(storeCount?.list)?.length == 0) {
      dispatch(getDashboardCount());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      let query = getQueryString(queryParams);
      let body = `${userData?.userId}${query}`;
      dispatch(getJobArchitectures(body));
      setPageIndex(queryParams?.page);
    } else {
      dispatch(getJobArchitectures(userData?.userId));
    }
  }, []);

  useEffect(() => {
    if (Object.entries(filterQueryParams)?.length > 0) {
      let query = getQueryString({
        ...filterQueryParams,
        limit: 9,
      });
      let body = `${userData?.userId}${query}`;
      dispatch(getAllJobArchitectures(body));
    } else if (
      !storeCount.loading &&
      storeCount?.list?.architectureCount &&
      Object.entries(filterQueryParams)?.length == 0
    ) {
      dispatch(
        getAllJobArchitectures(
          `${userData?.userId}?limit=${storeCount?.list?.architectureCount}`
        )
      );
    }
  }, [storeCount, location?.search]);

  const handlePageClick = (value) => {
    if (value) {
      setPageIndex(value);
      let queryObj = { ...queryParams, page: value, limit: 10 };
      let query = getQueryString(queryObj);
      let body = `${userData?.userId}${query}`;
      updateRouteWithParams(queryObj);
      dispatch(getJobArchitectures(body));
    }
  };

  const handleSubmit = (values) => {
    let body = {
      groupId: familyGroup?.value,
      familyId: family?.value,
      userId: userData?.userId,
      positionTitle: inputValue,
      jobDescriptionId: values?.jdID,
      profileId: values?.profileId,
    };
    const successMessage = `Job Architecture Updated Successfully!`;
    const errorMessage = `Unable to Update Job Architecture !`;
    setLoading(true);
    putJobProfile(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          dispatch(updateJobProfile(res.data));
          toasterSuccess(successMessage);
          setLoading(false);
        } else {
          toasterError(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setIsEdit(false);
      });
  };

  function handleAcceptedFiles(acceptedFiles) {
    const file = acceptedFiles[0];
    if (file) {
      const formData = new FormData();
      formData.append("userId", userData?.userId);
      Object.keys(file).forEach((key) => {
        formData.append(`jdxl`, file);
      });
      setLoader(true);
      PostJobArchitectureFile(formData)
        .then((res) => {
          if (
            res?.metadata?.status === 200 ||
            res?.metadata?.message == "Success"
          ) {
            setImportModal(!importModal);
            setLoader(false);
            dispatch(getJobArchitectures(userData?.userId));
          }
        })
        .catch((error) => {
          console.error(error);
          setLoader(false);
        });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col md={12} lg={12} xxl={12}>
            <TableContainer
              loading={storeJobArchitecture?.loading}
              columns={columns}
              data={storeJobArchitecture?.jobArchitectures}
              isGlobalFilter={true}
              columnFilter={true}
              isAddUserList={false}
              customPageSize={10}
              showPagination={true}
              divClass="table-responsive table-card mb-0 p-3"
              tableClass="align-middle table-wrap"
              theadClass="table-light"
              SearchPlaceholder="Search for..."
              addAction={() => {}}
              buttonAction={() => {
                setActiveId(null);
              }}
              buttonText={"Add Job Architecture"}
              showButton={false}
              exportButton={true}
              downloadFileName={"Job-Architecture"}
              importButton={false}
              importAction={() => {
                setImportModal(!importModal);
              }}
              isDefaultPagination={false}
              inputValue={inputValue}
              setInputValue={setInputValue}
              isInputEdit={isEdit}
              rowIdOnclick={"profileId"}
              pageTitle={"Job Architecture"}
              isPageHeader={true}
              isCustomPagination={true}
              customPaginationMeta={{
                ...storeJobArchitecture?.meta,
                pageRecords: storeJobArchitecture?.jobArchitectures?.length,
                pageIndex: pageIndex,
              }}
              handlePageClick={handlePageClick}
              isFilterBtn={true}
              filterAction={() => {
                setFilterModal(!filterModal);
              }}
              dispatchTableData={getJobArchitectures}
              isCaption={true}
              captionText={
                "Displays only the Job descriptions with a status of 'Completed' from the Job Description Table."
              }
              rowSelectionID={"profileId"}
              downloadList={storeJobArchitecture?.allList?.list}
              isUserId={true}
              downloadColumns={downloadColumns}
            />
          </Col>
        </Row>
      </div>
      <JAFilters show={filterModal} onCloseClick={toggleFilterModal} />
      <FileImport
        show={importModal}
        onCloseClick={setImportModal}
        onClick={() => {}}
        handleAcceptedFiles={handleAcceptedFiles}
        loading={loader}
      />
      {storeJobArchitecture?.loading && <Loader />}
    </React.Fragment>
  );
};
export default JobArchitecture;
