import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Card, Table, Row, Col, Input } from "reactstrap";
import classnames from "classnames";
import TableColumnFilter from "../../Components/Custom/TableColumnFilter";
import { getMarketRanges } from "../../slices/thunks";
import Loader from "../../Components/Custom/Loader";
import { formatSalary } from "../../utils/dataFormat";
import PaginationContainer from "../../Components/Custom/PaginationContainer";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import useUpdateRouteWithQueryParams from "../../Components/Hooks/queryParams";
import PayZonePriceModal from "./modal";
import RangesFilters from "./Filters";
import PageHeader from "../../Components/Common/Header";

const defaultColumnVisible = {
  familyGroup: true,
  familyName: true,
  positionCode: true,
  positionTitle: true,
  jobDescriptionId: true,
  level: true,
  jobTitle: true,
  jobType: true,
  jobStatus: true,
  minComposite: true,
  maxComposite: true,
  baseComposite: true,
  MidpointDifferential: true,
};

const RangesTable = () => {
  const [searchValues, setSearchValues] = useState("");
  const [columVisibility, setColumnVisibility] = useState({
    ...defaultColumnVisible,
  });
  const dispatch = useDispatch();
  const storeRanges = useSelector((state) => state?.MarketRanges?.marketRanges);
  const queryParams = parseQueryParams();
  const [pageIndex, setPageIndex] = useState();
  const [modal, setModal] = useState(false);
  const [activeValues, setActiveValues] = useState();
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);
  const updateRouteWithParams = useUpdateRouteWithQueryParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllRows, setSelectedAllRows] = useState(true);

  const columns = [
    {
      accessor: "familyGroup",
      Header: "Job Family Group",
      visible: columVisibility?.familyGroup,
    },
    {
      accessor: "familyName",
      Header: "Job Family",
      visible: columVisibility?.familyName,
    },
    {
      accessor: "positionCode",
      Header: "Position ID",
      visible: columVisibility?.positionCode,
    },
    {
      accessor: "positionTitle",
      Header: "Position Title",
      visible: columVisibility?.positionTitle,
    },
    {
      accessor: "jobDescriptionId",
      Header: "Job ID",
      visible: columVisibility?.jobDescriptionId,
    },
    {
      accessor: "jobTitle",
      Header: "Job Title",
      visible: columVisibility?.jobTitle,
    },
    {
      accessor: "level",
      Header: "Job Level",
      visible: columVisibility?.level,
    },
    {
      accessor: "jobType",
      Header: "Job Type",
      visible: columVisibility?.jobType,
    },
    {
      accessor: "jobStatus",
      Header: "Job Status",
      visible: columVisibility?.jobStatus,
    },
    {
      accessor: "minComposite",
      Header: "Minimum",
      visible: columVisibility?.minComposite,
    },

    {
      accessor: "maxComposite",
      Header: "Maximum",
      visible: columVisibility?.maxComposite,
    },
    {
      accessor: "baseComposite",
      Header: "Midpoint",
      visible: columVisibility?.baseComposite,
    },
    // {
    //   accessor: "MidpointDifferential",
    //   Header: "Midpoint Differential",
    //   visible: true,
    // },
  ];

  // useEffect(() => {
  // dispatch(getMarketRanges());
  // }, []);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      let query = getQueryString(queryParams);
      dispatch(getMarketRanges(query));
      setPageIndex(queryParams?.page);
    } else {
      dispatch(getMarketRanges(""));
    }
  }, []);

  const handlePageClick = (value) => {
    if (value) {
      setPageIndex(value);
      let query = { page: value, limit: 10 };
      let body = `?page=${value}&limit=10`;
      updateRouteWithParams(query);
      dispatch(getMarketRanges(body));
    }
  };

  const toggleVisibleColumns = (
    columnKey,
    isReset = false,
    isShowAll = false
  ) => {
    if (isReset) {
      setColumnVisibility({ ...defaultColumnVisible });
    } else if (isShowAll) {
      let newColumns = { ...defaultColumnVisible };
      for (let i in newColumns) {
        newColumns[i] = true;
      }
      setColumnVisibility({ ...newColumns });
    } else {
      setColumnVisibility((prevColumns) => ({
        ...prevColumns,
        [columnKey]: !prevColumns[columnKey],
      }));
    }
  };

  const toggleRowSelection = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows?.includes(row)) {
        return prevSelectedRows.filter((id) => id !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content ranges-table">
        <PageHeader
          pageTitle={"Ranges"}
          isAddBtn={false}
          isImportBtn={false}
          selectAllRows={selectAllRows}
          data={storeRanges?.ranges}
          visibleColumns={columns}
          exportButton={true}
          rowSelectionID={"jobDescriptionId"}
          selectedRows={selectedRows}
          downloadFileName={"Market-Ranges"}
        />
        <Card className="shadow-none">
          <CardBody className="">
            <Row className="mb-3 ">
              <div className="d-flex justify-content-between search-filters">
                <Col lg={12}>
                  <div className="d-flex justify-content-between gap-2">
                    <div className="w-25">
                      <Input
                        type="text"
                        className="mx-1 rounded-pill w-100"
                        value={searchValues || ""}
                        onChange={(e) => setSearchValues(e.target.value)}
                        placeholder="Search..."
                      />
                    </div>
                    <div>
                      <TableColumnFilter
                        columns={columns}
                        toggleVisibleColumns={toggleVisibleColumns}
                        isFilterBtn={true}
                        filterAction={() => {
                          setFilterModal(!filterModal);
                        }}
                        dispatchTableData={getMarketRanges}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
            <div className="table-responsive my-3">
              <Table responsive className="">
                <thead>
                  <tr className="row-one">
                    <th rowSpan="2">
                      <Input
                        type="checkbox"
                        className="form-check-input "
                        checked={
                          storeRanges?.ranges?.length > 0 &&
                          selectedRows?.length === storeRanges?.ranges?.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            const allRowIds = [];
                            storeRanges?.ranges?.map((ele) => {
                              allRowIds?.push(ele?.jobDescriptionId);
                            });

                            setSelectedRows(allRowIds);
                            setSelectedAllRows(true);
                          } else {
                            setSelectedRows([]);
                            setSelectedAllRows(false);
                          }
                        }}
                      />
                    </th>
                    {columVisibility?.familyGroup && (
                      <th rowSpan="2" className="">
                        Job Family Group
                      </th>
                    )}
                    {columVisibility?.familyName && (
                      <th rowSpan="2" className="">
                        Job Family
                      </th>
                    )}
                    {columVisibility?.positionCode && (
                      <th rowSpan="2" className="">
                        Position ID
                      </th>
                    )}
                    {columVisibility?.positionTitle && (
                      <th rowSpan="2" className="">
                        Position Title
                      </th>
                    )}
                    {columVisibility?.jobDescriptionId && (
                      <th rowSpan="2" className="width-sm">
                        Job ID
                      </th>
                    )}
                    {columVisibility?.jobTitle && (
                      <th rowSpan="2" className="">
                        Job Title
                      </th>
                    )}
                    {columVisibility?.level && (
                      <th
                        rowSpan={"2"}
                        className={classnames({
                          "text-center ": true,
                          "d-none": !columVisibility?.level,
                        })}
                        //
                      >
                        Job Level
                      </th>
                    )}
                    {columVisibility?.jobType && (
                      <th
                        rowSpan={"2"}
                        className={classnames({
                          "text-center ": true,
                          "d-none": !columVisibility?.jobType,
                        })}
                      >
                        Job Type
                      </th>
                    )}
                    {columVisibility?.jobStatus && (
                      <th
                        rowSpan="2"
                        className={classnames({
                          "text-center": true,
                          "d-none": !columVisibility?.jobStatus,
                        })}
                      >
                        Job Status
                      </th>
                    )}
                    <th
                      colSpan="3"
                      className={classnames({
                        "text-center ": true,
                        "d-none":
                          !columVisibility?.maxComposite &&
                          !columVisibility?.baseComposite &&
                          !columVisibility?.minComposite,
                      })}
                    >
                      Pay Range National Average
                    </th>
                    {/* {columVisibility?.MidpointDifferential && (
                    <th
                      rowSpan="2"
                      className={classnames({
                        "text-center impact-area-green": true,
                        "d-none": !columVisibility?.MidpointDifferential,
                      })}
                      
                    >
                      Midpoint Differential
                    </th>
                  )} */}
                  </tr>
                  <tr className="row-two">
                    {columVisibility?.minComposite && <th>Minimum</th>}
                    {columVisibility?.baseComposite && <th>Midpoint</th>}
                    {columVisibility?.maxComposite && <th>Maximum</th>}
                  </tr>
                </thead>
                <tbody className="">
                  {storeRanges?.ranges?.length > 0 &&
                    storeRanges?.ranges?.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className=""
                          onClick={() => {
                            setModal(true);
                            setActiveValues(data);
                          }}
                        >
                          <td>
                            <Input
                              type="checkbox"
                              className="form-check-input "
                              onChange={() => {
                                setModal(false);
                                toggleRowSelection(data?.jobDescriptionId);
                              }}
                              checked={selectedRows?.includes(
                                data?.jobDescriptionId
                              )}
                            />
                          </td>
                          {columVisibility?.familyGroup && (
                            <td className="width-md">{data?.familyGroup}</td>
                          )}
                          {columVisibility?.familyName && (
                            <td className="width-md">{data?.familyName}</td>
                          )}
                          {columVisibility?.positionCode && (
                            <td>{data?.positionCode}</td>
                          )}
                          {columVisibility?.positionTitle && (
                            <td className="width-lg">{data?.positionTitle}</td>
                          )}
                          {columVisibility?.jobDescriptionId && (
                            <td className="width-sm">
                              {data?.jobDescriptionId}
                            </td>
                          )}
                          {columVisibility?.jobTitle && (
                            <td className="width-lg">{data?.jobTitle}</td>
                          )}
                          {columVisibility?.level && (
                            <td className="width-sm text-center">
                              {data?.level}
                            </td>
                          )}
                          {columVisibility?.jobType && (
                            <td className="width-sm text-center">
                              {data?.jobType}
                            </td>
                          )}
                          {columVisibility?.jobStatus && (
                            <td className="width-sm">{data?.jobStatus}</td>
                          )}
                          {columVisibility?.minComposite && (
                            <td>${formatSalary(data?.minComposite) || 0}</td>
                          )}
                          {columVisibility?.baseComposite && (
                            <td>${formatSalary(data?.baseComposite) || 0}</td>
                          )}
                          {columVisibility?.maxComposite && (
                            <td>${formatSalary(data?.maxComposite) || 0}</td>
                          )}
                          {/* {columVisibility?.MidpointDifferential && <td>-</td>} */}
                        </tr>
                      );
                    })}
                  {!storeRanges?.loading &&
                    storeRanges?.ranges?.length == 0 && (
                      <tr className="text-center my-5">
                        <td colSpan={13}>
                          <h3>No Data Found</h3>
                        </td>
                      </tr>
                    )}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col>
                <PaginationContainer
                  pageIndex={pageIndex}
                  goTo={handlePageClick}
                  totalPages={storeRanges?.meta?.totalPages}
                  totalRecords={storeRanges?.meta?.count}
                  pageRecords={storeRanges?.ranges?.length}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <PayZonePriceModal
          isOpen={modal}
          onCloseClick={() => setModal(false)}
          activeValues={activeValues}
        />
        {storeRanges?.loading && <Loader />}
      </div>
      <RangesFilters show={filterModal} onCloseClick={toggleFilterModal} />
    </React.Fragment>
  );
};

export default RangesTable;
