import { createSlice } from "@reduxjs/toolkit";
import { getInternalFields } from "./thunk";

export const initialState = {
  InternalFields: { Fields: [], error: {}, loading: false },
};

const InternalFieldsSlice = createSlice({
  name: "InternalFields",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInternalFields.pending, (state, action) => {
      state.InternalFields.Fields = [];
      state.InternalFields.loading = true;
    });
    builder.addCase(getInternalFields.fulfilled, (state, action) => {
      state.InternalFields.Fields = action.payload;
      state.InternalFields.loading = false;
    });
    builder.addCase(getInternalFields.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.InternalFields.loading = false;
    });
  },
});

export const {} = InternalFieldsSlice.actions;

export default InternalFieldsSlice.reducer;
