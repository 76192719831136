import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBenchmarking as getAllBenchmarkingApi } from "../../helpers/custom_backend_helper";

export const getBenchmarking = createAsyncThunk(
  "benchmarking/get",
  async (userId) => {
    try {
      const response = await getAllBenchmarkingApi(userId);
      const data = await response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
