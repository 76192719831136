import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { buildOptions, getOptionByValue } from "../../utils/dataFormat";
import ColumnLoader from "../../Components/Custom/ColumnLoader";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import * as Yup from "yup";
import { postJobFamily } from "../../helpers/custom_backend_helper";
import { getUserData } from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { pushJobFamily } from "../../slices/jobArchitecture/reducer";

const defaultValues = {
  groupId: "",
  JobFamily: "",
};

export const Add = ({ onCloseClick, isOpen }) => {
  const [jobfamily, setJobfamily] = useState("");
  const [jobFamilyGrp, setJobFamilyGrp] = useState("Select");
  const [loading, setLoading] = useState(false);
  const storeJobFamilyGroup = useSelector(
    (state) => state.JobArchitecture?.jobFamilyGroup
  );
  const userData = getUserData();
  const dispatch = useDispatch();

  const validateSchema = Yup.object().shape({
    groupId: Yup.mixed().required("Job Family Group is required"),
    familyName: Yup.mixed().required("Job Family Name is required"),
  });

  const handleSubmit = (values) => {
    let body = {
      groupId: values?.groupId?.value,
      familyName: values?.familyName,
      userId: userData?.userId,
    };
    setLoading(true);
    postJobFamily(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          dispatch(pushJobFamily(res?.data));
          toasterSuccess("Added Job Family Name!");
          onCloseClick();
        } else {
          toasterError("Unable to add  Job Family Name!");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toasterError("Unable to add Job Family Group Name!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="jobfamily-modal">
      <Modal isOpen={isOpen} toggle={onCloseClick} centered={true}>
        <ModalHeader
          toggle={onCloseClick}
          className="bg-secondary p-3 px-4 modal-header"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-primary d-flex align-items-center">
              ADD - Job Family
            </span>
          </div>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={defaultValues}
          validationSchema={validateSchema}
        >
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <ModalBody>
                  <Row>
                    <Col className="col-12 mb-4">
                      <Label for="exampleSelect">Job Family Group</Label>
                      <ReactSelect
                        id="groupId"
                        className=" mb-0"
                        name="groupId"
                        value={values?.groupId}
                        onChange={(event) => {
                          setFieldValue("groupId", event);
                        }}
                        options={buildOptions(
                          storeJobFamilyGroup?.familyGroup,
                          "familyGroup",
                          "groupId"
                        )}
                        onBlur={() => {
                          setFieldTouched("groupId", true);
                          setFieldError("groupId", true);
                        }}
                      />
                      <ErrorMessage
                        name="groupId"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                    <Col className="col-12 mb-4">
                      <Label>Job Family Name</Label>
                      <Input
                        className="form-control"
                        id="familyName"
                        name="familyName"
                        type="text"
                        placeholder="ex. Software Engineering"
                        value={values?.familyName}
                        onChange={(event) => {
                          setFieldValue("familyName", event.target.value);
                        }}
                        onBlur={() => {
                          setFieldTouched("familyName", true);
                          setFieldError("familyName", true);
                        }}
                      />
                      <ErrorMessage
                        name="familyName"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                  </Row>{" "}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={onCloseClick}
                    className="btn theme-cancel-btn mx-2 px-4 btn btn-white"
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    className="btn px-4 btn btn-secondary"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default Add;
