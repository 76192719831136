import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { buildOptions, getOptionByValue } from "../../utils/dataFormat";
import { JDStatus } from "../../utils/dataOptions";
import { Formik } from "formik";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import {
  getGeneralLevels,
  getJobArchitectures,
  getJobFamily,
  getJobFamilyGroup,
  getMarketRanges,
} from "../../slices/thunks";
import { getUserData } from "../../utils/auth";
import SimpleBar from "simplebar-react";

const defaultValues = {
  jobTitle: "",
  positionTitle: "",
  level: "",
  status: "",
  familyGroupName: "",
  familyName: "",
};

const RangesFilters = ({ show, onCloseClick }) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = parseQueryParams();
  const storeJobDescription = useSelector((state) => state.JobDescription);
  const storeLevels = useSelector((state) => state.GeneralLevels);
  const userData = getUserData();
  const storeFamilyGroup = useSelector(
    (state) => state?.JobArchitecture?.jobFamilyGroup
  );
  const storeFamily = useSelector((state) => state?.JobArchitecture?.jobFamily);
  const storeJobArchitecture = useSelector((state) => state.JobArchitecture);

  useEffect(() => {
    if (storeJobArchitecture?.jobArchitectures?.length == 0) {
      dispatch(getJobArchitectures());
    }
    if (storeLevels?.levels?.length == 0) {
      dispatch(getGeneralLevels());
    }
    if (storeFamily?.family?.length == 0) {
      dispatch(getJobFamily());
    }
    if (storeFamilyGroup?.familyGroup?.length == 0) {
      dispatch(getJobFamilyGroup());
    }
  }, [show]);

  useEffect(() => {
    if (Object?.entries(queryParams).length > 0 && show) {
      setInitialValues({ ...initialValues, ...queryParams });
    } else setInitialValues(defaultValues);
  }, [show]);

  const applyFilters = (values) => {
    let data = {
      jobTitle: values?.jobTitle,
      positionTitle: values?.positionTitle,
      level: values?.level,
      status: values?.status,
      familyGroupName: values?.familyGroupName,
      familyName: values?.familyName,
    };
    const queryString = getQueryString(data);
    const urlWithParams = `/ranges${queryString}`;
    navigate(urlWithParams);
    dispatch(getMarketRanges(`${queryString}`));
    onCloseClick();
  };

  const clearFilters = (e) => {
    e.preventDefault();
    onCloseClick();
    navigate("/ranges");
    // dispatch(getMarketRanges(""));
    setInitialValues(initialValues);
    setFilterValues({});
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader
        className="bg-soft-primary"
        toggle={() => onCloseClick(!show)}
      >
        <h5 className=" text-primary mb-0 "> FILTERS - Ranges</h5>
      </OffcanvasHeader>

      <OffcanvasBody>
        <Formik enableReinitialize initialValues={initialValues}>
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <Row>
                  <SimpleBar className="modalbody-scroll">
                    <Col md={12} className="mb-4">
                      <Label>Job Family Group</Label>
                      <ReactSelect
                        id="familyGroupName"
                        className=" mb-0"
                        name="familyGroupName"
                        value={
                          values?.familyGroupName
                            ? getOptionByValue(values?.familyGroupName)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("familyGroupName", event?.label);
                        }}
                        options={buildOptions(
                          storeFamilyGroup?.familyGroup,
                          "familyGroup",
                          "groupId"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Job Family</Label>
                      <ReactSelect
                        id="familyName"
                        className=" mb-0"
                        name="familyName"
                        value={
                          values?.familyName
                            ? getOptionByValue(values?.familyName)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("familyName", event?.label);
                        }}
                        options={buildOptions(
                          storeFamily?.family,
                          "familyName",
                          "familyId"
                        )}
                      />
                    </Col>{" "}
                    <Col md={12} className="mb-4">
                      <Label>Position Title</Label>
                      <ReactSelect
                        id="positionTitle"
                        className=" mb-0"
                        name="positionTitle"
                        value={
                          values?.positionTitle
                            ? getOptionByValue(values?.positionTitle)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("positionTitle", event?.label);
                        }}
                        options={buildOptions(
                          storeJobArchitecture?.jobArchitectures,
                          "positionTitle",
                          "positionTitle"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Job Title</Label>
                      <ReactSelect
                        id="jobTitle"
                        className=" mb-0"
                        name="jobTitle"
                        value={
                          values?.jobTitle
                            ? getOptionByValue(values?.jobTitle)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("jobTitle", event?.label);
                        }}
                        options={buildOptions(
                          storeJobArchitecture?.jobArchitectures,
                          "jobTitle",
                          "jobTitle"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Job Level</Label>
                      <ReactSelect
                        id="level"
                        className=" mb-0"
                        name="level"
                        value={
                          values?.level ? getOptionByValue(values?.level) : null
                        }
                        onChange={(event) => {
                          setFieldValue("level", event?.label);
                        }}
                        options={buildOptions(
                          storeLevels?.levels,
                          "levelCode",
                          "levelCode"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Status</Label>
                      <ReactSelect
                        id="statuss"
                        className=" mb-0"
                        name="status"
                        value={
                          values?.status
                            ? getOptionByValue(values?.status)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("status", event?.label);
                        }}
                        options={JDStatus}
                      />
                    </Col>{" "}
                  </SimpleBar>
                </Row>

                <div className=" d-flex justify-content-end position-absolute bottom-0 py-4 pe-4 bg-white w-100">
                  <Button
                    className="btn theme-cancel-btn me-2 px-4 w-100"
                    color="white"
                    onClick={(e) => {
                      clearFilters(e);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn px-4 w-100 me-3"
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      applyFilters(values);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>{" "}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default RangesFilters;