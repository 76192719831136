import React, { useEffect, useState, useCallback } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getMarketData } from "../../slices/thunks";
import Loader from "../../Components/Custom/Loader";
import { useNavigate } from "react-router-dom";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import { putMarketPricing } from "../../helpers/custom_backend_helper";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import { updateMarketData } from "../../slices/marketPricing/reducer";
import starImg from "../../assets/images/star.png";
import PageHeader from "../../Components/Common/Header";
import TableContainer from "../../Components/Common/TableContainer";
import { formatSalary } from "../../utils/dataFormat";
import PaginationContainer from "../../Components/Custom/PaginationContainer";
import useUpdateRouteWithQueryParams from "../../Components/Hooks/queryParams";
import MarketPricingFilters from "./Filters";
import DisplayFilters from "../../Components/Common/DisplayFilters";
import Export from "../../Components/Custom/Export";

export const MarketPricing = () => {
  const [loading, setLoading] = useState(false);
  let ageingMonths = 5;
  const ageingPercentage = 3;
  let ageing =
    1 + parseFloat(((ageingMonths * ageingPercentage) / 100 / 12).toFixed(4));

  const defaultValues = {
    weightage: 100,
    adjustment: 100,
    ageing: ageing,
    marketBase: "",
    caluculatedmarket: "",
    baseComposite: "",
    caluculatedmarket: "",
  };

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const queryParams = parseQueryParams();
  const [percentile, setPercentile] = useState("50");
  const storeMarketData = useSelector(
    (state) => state.MarketPricing?.marketData
  );
  const [tableInfo, setTableInfo] = useState([]);
  const [initialValues, setInitialValues] = useState([defaultValues]);
  const [baseCompositeValue, setBaseCompositeValue] = useState(0);
  const [isEdit, setIsEdit] = useState(null);
  const [JDId, setJDId] = useState(null);
  const [multiInputCellObj, setMultiInputCellObj] = useState({
    weightage: "",
    adjustment: "",
  });
  const [pageIndex, setPageIndex] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllRows, setSelectedAllRows] = useState(true);
  const updateRouteWithParams = useUpdateRouteWithQueryParams();
  const { page, limit, ...filterQueryParams } = queryParams;
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);
  const filterAction = () => {
    setFilterModal(!filterModal);
  };

  const columns = [
    {
      Header: "Market Base",
      accessor: "marketBase",
      filterable: true,
      visible: true,
      maxWidth: "100px",
      Cell: (cellProps) => {
        return <>$ {formatSalary(cellProps?.value) || "-"}</>;
      },
    },
    {
      Header: "Adjustment (%)",
      accessor: "adjustment",
      filterable: true,
      visible: true,
      maxWidth: "100px",
      isInputField: true,
      Cell: (cellProps) => {
        return <>{cellProps?.value || "-"}</>;
      },
    },

    {
      Header: "Weightage (%)",
      accessor: "weightage",
      filterable: true,
      visible: true,
      maxWidth: "100px",
      isInputField: true,
      Cell: (cellProps) => {
        return (
          <>{cellProps?.value ? ` $ ${formatSalary(cellProps?.value)}` : "-"}</>
        );
      },
    },
    {
      Header: "Ageing",
      accessor: "ageing",
      filterable: true,
      visible: true,
      maxWidth: "100px",
      Cell: (cellProps) => {
        return <>{cellProps?.value || 1.0125}</>;
      },
    },
    {
      Header: "Market base after adjustment",
      accessor: "caluculatedmarket",
      filterable: true,
      visible: true,
      maxWidth: "150px",
      Cell: (cellProps) => {
        return (
          <p className=" p-2 rounded mb-0 text-end me-2">
            {cellProps?.value ? ` $ ${formatSalary(cellProps?.value)}` : "-"}
          </p>
        );
      },
    },
  ];

  const headers = [
    {
      accessor: "positionCode",
      Header: "Position Code",
      visible: true,
    },
    {
      accessor: "jobTitle",
      Header: "Job Title",
      visible: true,
    },
    {
      accessor: "level",
      Header: "Job Level",
      visible: true,
    },
    {
      accessor: "baseComposite",
      Header: "baseComposite",
      visible: true,
    },
  ];

  // useEffect(() => {
    // if (queryParams?.jdId) {
    //   setModal(true);
    // }
    // if (storeMarketData?.marketData?.length == 0) {
    //   dispatch(getMarketData());
    // }
  // }, []);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      let query = getQueryString(queryParams);
      let body = `${query}`;
      dispatch(getMarketData(query));
      setPageIndex(queryParams?.page);
    } else {
      dispatch(getMarketData(""));
    }
  }, []);

  const handlePageClick = (value) => {
    if (value) {
      setPageIndex(value);
      let query = { page: value, limit: 10 };
      let body = `?page=${value}&limit=10`;
      updateRouteWithParams(query);
      dispatch(getMarketData(body));
    }
  };

  const getDefaultValues = (values) => {
    if (values?.length > 0) {
      setInitialValues(values);
    }
  };

  const toggleRowSelection = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows?.includes(row)) {
        return prevSelectedRows.filter((id) => id !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  function calculateAdjustedBase(
    marketBase,
    weightage,
    adjustmentPercentage,
    ageingMonths,
    index,
    updatedValues
  ) {
    // Constants
    const ageingPercentage = 3; // Percentage for ageing
    const roundingMultiple = 1000; // Rounding multiple

    // Calculate Aging
    let ageing =
      1 + parseFloat(((ageingMonths * ageingPercentage) / 100 / 12).toFixed(4));

    // Calculate Adjusted Base
    let adjustedBase = marketBase * (weightage / 100);

    // Round Adjusted Base
    let adjustment = adjustedBase * (adjustmentPercentage / 100);
    const agedPrice = adjustment * ageing;
    let roundedAdjustedBase =
      Math.round(agedPrice / roundingMultiple) * roundingMultiple;
    const finalVal = (((adjustment * ageing) / 1000) * 1000).toFixed(0);

    const values = _.cloneDeep(updatedValues);
    values[index]["caluculatedmarket"] = finalVal || 0;
    setInitialValues(values);
    calculateBaseComposite(values);
    return finalVal;
  }

  const calculateBaseComposite = (values) => {
    let data = _.cloneDeep(values);
    let baseValues = [],
      WeightageValues = [];
    let baseSum = 0,
      weightageSum = 0;
    data?.map((ele) => {
      baseValues.push(ele?.caluculatedmarket);
      WeightageValues.push(ele?.weightage);
    });
    baseSum = baseValues.reduce((a, i) => +a + +i, 0);
    weightageSum = WeightageValues.reduce((a, i) => a + i, 0);
    let composite = baseSum / (weightageSum / 100);
    const roundedAmount = Math.round(composite / 1000) * 1000;
    // setBaseCompositeValue(roundedAmount);
  };

  const handleInputChange = (name, index, value) => {
    const updatedValues = _.cloneDeep(initialValues);
    // if (name == "adjustment" || name == "weightage") {
    //   if (value >= 100) {
    //     updatedValues[index][name] = value;
    //   } else updatedValues[index][name] = 100;
    // } else
    updatedValues[index][name] = value;
    setInitialValues(updatedValues);
    calculateAdjustedBase(
      updatedValues[index]?.marketBase,
      updatedValues[index]?.weightage,
      updatedValues[index]?.adjustment,
      ageingMonths,
      index,
      updatedValues
    );
    let obj = {
      jobDescriptionId: JDId,
      marketData: {
        jobCode: updatedValues[index]?.jobCode,
        weightage: updatedValues[index]?.weightage,
        adjustment: updatedValues[index]?.adjustment,
        marketBase: updatedValues[index]?.marketBase,
      },
    };
    setInputValue(obj);
    debouncedUpdate(obj);
  };

  const [inputValue, setInputValue] = useState({});
  const [debouncedValue, setDebouncedValue] = useState({});

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedUpdate = useCallback(debounce(setDebouncedValue, 2000), []);

  useEffect(() => {
    if (debouncedValue?.jobDescriptionId) {
      handleUpdate();
    }
  }, [debouncedValue]);

  const handleUpdate = () => {
    setLoading(true);
    putMarketPricing(debouncedValue)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          setBaseCompositeValue(res?.data?.baseComposite);
          toasterSuccess("Market Pricing Updated Successfully!");
          dispatch(updateMarketData(res?.data));
          setLoading(false);
        } else {
          toasterError("Unable to Update Market Pricing");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toasterError("Unable to Update Market Pricing");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content benchmarking-styles market-pricing ">
        <div>
          <PageHeader
            pageTitle={"Market Pricing"}
            isAddBtn={false}
            isImportBtn={false}
            selectAllRows={selectAllRows}
            data={storeMarketData?.marketData}
            visibleColumns={headers}
            exportButton={true}
            rowSelectionID={"jobDescriptionId"}
            selectedRows={selectedRows}
            isFilterBtn={true}
            filterAction={filterAction}
            downloadFileName={"Market Pricing"}
          />
        </div>

        <Row className="mx-1 mb-2">
          <Col className="d-flex flex-wrap mt-2 justify-content-end px-2 align-items-center">
            <DisplayFilters
              dispatchTableData={getMarketData}
              isBorderColor={true}
              isUserID={false}
            />
          </Col>
        </Row>

        <div className="mb-5">
          <Row className="data-container">
            <Col md={12} lg={12} xxl={12}>
              {/* <div className="d-flex justify-content-end">
              <p className="bg-soft-secondary fw-semibold p-2 rounded">
                Ageing Percentage : {ageingPercentage}%
              </p>
            </div> */}
              <Card className="header-container p-0 rounded-0 shadow-none mb-1 ">
                <CardBody className="px-2 py-1 rounded-5 bg-white">
                  <UncontrolledAccordion defaultOpen={[]} stayOpen className="">
                    <AccordionItem className="border border-0  bg-white">
                      <AccordionHeader className="d-flex   border border-0">
                        <div className="px-0 me-3">
                          <Input
                            type="checkbox"
                            className="form-check-input "
                            checked={
                              storeMarketData?.marketData?.length > 0 &&
                              selectedRows?.length ===
                                storeMarketData?.marketData?.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                const allRowIds = [];
                                storeMarketData?.marketData?.map((ele) => {
                                  allRowIds?.push(ele?.jobDescriptionId);
                                });
                                setSelectedRows(allRowIds);
                                setSelectedAllRows(true);
                              } else {
                                setSelectedRows([]);
                                setSelectedAllRows(false);
                              }
                            }}
                          />
                        </div>
                        <div className="job-code px-0 me-3 ">
                          <p className="fw-semibold mb-0">Job Code</p>
                        </div>
                        <div className="job-title px-0 me-3 ">
                          <p className="fw-semibold mb-0">Job Title</p>
                        </div>
                        <div className="job-level px-0 me-3">
                          <p className="fw-semibold mb-0">Job Level</p>
                        </div>
                        <div className="job-level px-0 me-3">
                          <p className="fw-semibold mb-0">Base Composite</p>
                        </div>
                        <div className="job-level px-0 me-3">
                          <p className="fw-semibold mb-0">
                            Midpoint Differential
                          </p>
                        </div>

                        <div className="accordion-arrow"></div>
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </CardBody>
              </Card>
              <UncontrolledAccordion className="data-container">
                {storeMarketData?.marketData?.length > 0 &&
                  storeMarketData?.marketData?.map((ele, ind) => {
                    return (
                      <Card
                        className="list-container p-0 rounded-0 shadow-none mb-1"
                        key={ind}
                      >
                        <CardBody className="p-1 px-2 py-1">
                          <AccordionItem className="border border-0">
                            <AccordionHeader
                              targetId={ele?.positionCode}
                              className="d-flex   border border-0"
                              onClick={() => {
                                setPercentile(ele?.percentile);
                                getDefaultValues(ele?.marketData);
                                setBaseCompositeValue(ele?.baseComposite);
                                setJDId(ele?.jobDescriptionId);
                                setIsEdit(ele?.positionCode);
                                setTableInfo([
                                  `Position Code : ${ele?.positionCode}`,
                                  `Job Title : ${ele?.jobTitle}`,
                                  `Job Level : ${ele?.level}`,
                                  `Percentile : ${ele?.percentile}th Percentile`,
                                  `Base Composite : ${ele?.baseComposite}`,
                                ]);
                              }}
                            >
                              <div className="px-0 me-3">
                                <Input
                                  type="checkbox"
                                  className="form-check-input "
                                  onChange={() =>
                                    toggleRowSelection(ele?.jobDescriptionId)
                                  }
                                  checked={selectedRows?.includes(
                                    ele?.jobDescriptionId
                                  )}
                                />
                              </div>
                              <div className="px-0 me-3 job-code">
                                <p className="fw-normal mb-0">
                                  {ele?.positionCode}
                                </p>
                              </div>
                              <div className="px-0 me-3 job-title">
                                <p className="fw-normal mb-0">
                                  {ele?.jobTitle}
                                </p>
                              </div>
                              <div className="px-0 me-3 job-level">
                                <p className="fw-normal mb-0">{ele?.level}</p>
                              </div>
                              <div className="px-0 me-3 job-level">
                                <p className="fw-normal mb-0">
                                  $ {formatSalary(ele?.baseComposite)}
                                </p>
                              </div>
                              <div className="px-0 me-3 job-level">
                                <p className="fw-normal mb-0">
                                  {ele?.midPointDiffernce
                                    ? `${ele?.midPointDiffernce}%`
                                    : "-"}
                                </p>
                              </div>
                            </AccordionHeader>
                            <AccordionBody
                              accordionId={ele?.positionCode}
                              className={`bg-white collapse transition-all duration-300`}
                            >
                              <div className="d-flex justify-content-end mx-4 mt-2">
                                <div
                                  class="btn-group mr-2  btn-group-md rounded-pill border border-1 border-secondary "
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="presentation"
                                    class={` rounded-pill border border-0 bg-soft-secondary text-secondary px-3`}
                                    onClick={() => {
                                      setPercentile("10");
                                    }}
                                  >
                                    {percentile}<sup>th</sup> Percentile
                                  </button>
                                  {/* <button
                                    type="button"
                                    class={`btn  rounded-pill border border-0  ${
                                      percentile == "25"
                                        ? " btn-secondary fw-semibold "
                                        : " btn-outline-secondary "
                                    }`}
                                    onClick={() => {
                                      setPercentile("25");
                                    }}
                                  >
                                    25 <sup>th</sup>
                                  </button>
                                  <button
                                    type="button"
                                    class={`btn  rounded-pill border border-0  ${
                                      percentile == "50"
                                        ? " btn-secondary fw-semibold "
                                        : " btn-outline-secondary "
                                    }`}
                                    onClick={() => {
                                      setPercentile("50");
                                    }}
                                  >
                                    50 <sup>th</sup>
                                  </button>
                                  <button
                                    type="button"
                                    class={`btn rounded-pill border border-0   ${
                                      percentile == "75"
                                        ? " btn-secondary fw-semibold "
                                        : " btn-outline-secondary "
                                    }`}
                                    onClick={() => {
                                      setPercentile("75");
                                    }}
                                  >
                                    75 <sup>th</sup>
                                  </button>
                                  <button
                                    type="button"
                                    class={`btn rounded-pill border border-0   ${
                                      percentile == "90"
                                        ? " btn-secondary fw-semibold "
                                        : " btn-outline-secondary "
                                    }`}
                                    onClick={() => {
                                      setPercentile("90");
                                    }}
                                  >
                                    90 <sup>th</sup>
                                  </button> */}
                                </div>{" "}
                                <div className="ms-2">
                                  <Export
                                    columns={columns}
                                    list={initialValues}
                                    fileName={`MarketPricing - ${ele?.positionCode}`}
                                    isInfo={true}
                                    infoList={tableInfo}
                                  />
                                </div>
                              </div>
                              {initialValues?.length > 0 ? (
                                <>
                                  <Row className="justify-content-center">
                                    <Col lg={9} md={12}>
                                      <TableContainer
                                        loading={false}
                                        columns={columns}
                                        data={initialValues}
                                        isGlobalFilter={false}
                                        columnFilter={true}
                                        isAddUserList={false}
                                        customPageSize={10}
                                        showPagination={true}
                                        divClass="table-responsive table-card mb-0 p-3 py-0"
                                        tableClass="align-middle table-wrap"
                                        theadClass="table-light"
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        isInputEdit={isEdit}
                                        rowIdOnclick={"jobCode"}
                                        isMultiCellInput={true}
                                        multiInputCellObj={multiInputCellObj}
                                        setMultiInputCellObj={
                                          setMultiInputCellObj
                                        }
                                        multiInputChange={handleInputChange}
                                      />
                                      <div className="d-flex align-items-center justify-content-end px-4 bg-soft-secondary mx-4 rounded-pill">
                                        <p className="mb-0">
                                          <Label className="fw-semibold text-dark mb-0 me-5">
                                            Base Composite
                                          </Label>
                                        </p>
                                        <p
                                          className="ms-2 text-end fw-semibold text-dark p-2 rounded  mb-0"
                                          style={{ width: "150px" }}
                                        >
                                          ${" "}
                                          {formatSalary(baseCompositeValue) ||
                                            0}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <div className="d-flex justify-content-center text-center mt-3 ">
                                  <Button
                                    className="btn btn-sm ai-btn rounded-pill p-2 px-4 d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      setModal(!modal);
                                    }}
                                  >
                                    <img src={starImg} className="me-2" />
                                    <h6 className="mb-0 text-white">
                                      {" "}
                                      Get Bencharking
                                    </h6>
                                  </Button>
                                </div>
                              )}
                            </AccordionBody>
                          </AccordionItem>
                        </CardBody>
                      </Card>
                    );
                  })}
                {!storeMarketData?.loading &&
                  storeMarketData?.marketData == 0 && (
                    <div className="text-center my-5">
                      <h4>No Data Found</h4>
                    </div>
                  )}
              </UncontrolledAccordion>
            </Col>
          </Row>
          <Row>
            <Col className="py-3">
              {" "}
              <PaginationContainer
                pageIndex={pageIndex}
                goTo={handlePageClick}
                totalPages={storeMarketData?.meta?.totalPages}
                totalRecords={storeMarketData?.meta?.count}
                pageRecords={storeMarketData?.marketData?.length}
              />
            </Col>
          </Row>
        </div>
      </div>
      <MarketPricingFilters
        show={filterModal}
        onCloseClick={toggleFilterModal}
      />
      {(storeMarketData?.loading || loading) && <Loader />}
    </React.Fragment>
  );
};
export default MarketPricing;
