import React from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
const tileBoxs1 = [
  {
    id: 1,
    label: "Job Descriptions",
    labelClass: "muted",
    percentage: "+16.24 %",
    percentageClass: "success",
    percentageIcon: "ri-arrow-right-up-line",
    counter: "550",
    caption: "View net earnings",
    // icon: "bx bx-dollar-circle",
    icon: "ri-honour-line",
    iconClass: "success-subtle",
    color: "primary",

    decimals: 0,
  },
  {
    id: 2,
    label: "Job Architecture",
    labelClass: "muted",
    percentage: "-3.57 %",
    percentageClass: "warning",
    percentageIcon: "ri-arrow-right-down-line",
    counter: "300",
    caption: "View all orders",
    icon: "bx bx-sitemap",
    iconClass: "info-subtle",
    color: "primary",

    // bgColor: "bg-info",
    // counterClass: "text-white",
    // captionClass: "text-white-50",
    decimals: 0,
    prefix: "",
    separator: ",",
    suffix: "",
  },
  {
    id: 3,
    label: "Benchmarks",
    labelClass: "muted",
    percentage: "+29.08 %",
    percentageClass: "success",
    percentageIcon: "ri-arrow-right-up-line",
    counter: "400",
    caption: "See details",
    icon: "bx bx-bar-chart-alt-2",
    iconClass: "warning-subtle",
    color: "primary",

    // decimals: 2,
    prefix: "",
    // suffix: "M",
  },
  {
    id: 4,
    label: "Employees",
    labelClass: "muted",
    percentage: "+0.00 %",
    percentageClass: "muted",
    counter: "1200",
    caption: "Withdraw money",
    // icon: "bx bx-wallet",
    icon: "ri-shield-user-line",
    iconClass: "danger-subtle",
    color: "primary",
    decimals: 0,
    // prefix: "$",
    separator: ",",
    // suffix: "k",
  },
  {
    id: 5,
    label: "Levels",
    labelClass: "muted",
    percentage: "-3.57 %",
    percentageClass: "warning",
    percentageIcon: "ri-arrow-right-down-line",
    counter: "22",
    caption: "View all orders",
    icon: "bx bx-table",
    iconClass: "primary-subtle",
    color: "primary",
    decimals: 0,
    prefix: "",
    separator: ",",
    suffix: "",
  },
  {
    id: 6,
    label: "External Survey",
    labelClass: "muted",
    percentage: "-3.57 %",
    percentageClass: "warning",
    percentageIcon: "ri-arrow-right-down-line",
    counter: "4",
    caption: "View all orders",
    icon: "ri-database-2-line",
    iconClass: "success-subtle",
    color: "primary",
    decimals: 0,
    prefix: "",
    separator: ",",
    suffix: "",
  },
];
export const CountCards = () => {
  const storeCount = useSelector((state) => state?.Dashboard?.count);
  const tileBoxs1 = [
    {
      id: 1,
      label: "Job Descriptions",
      labelClass: "muted",
      percentage: "+16.24 %",
      percentageClass: "success",
      percentageIcon: "ri-arrow-right-up-line",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.jobDescriptionCount
          : 0,
      caption: "View net earnings",
      // icon: "bx bx-dollar-circle",
      icon: "ri-honour-line",
      iconClass: "success-subtle",
      color: "primary",

      decimals: 0,
    },
    
    {
      id: 3,
      label: "Benchmarks",
      labelClass: "muted",
      percentage: "+29.08 %",
      percentageClass: "success",
      percentageIcon: "ri-arrow-right-up-line",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.benchmarkCount
          : 0,
      caption: "See details",
      icon: "bx bx-bar-chart-alt-2",
      iconClass: "warning-subtle",
      color: "primary",

      // decimals: 2,
      prefix: "",
      // suffix: "M",
    },
    {
      id: 4,
      label: "Employees",
      labelClass: "muted",
      percentage: "+0.00 %",
      percentageClass: "muted",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.employeesCount
          : 0,
      caption: "Withdraw money",
      // icon: "bx bx-wallet",
      icon: "ri-shield-user-line",
      iconClass: "danger-subtle",
      color: "primary",
      decimals: 0,
      // prefix: "$",
      separator: ",",
      // suffix: "k",
    },
    {
      id: 5,
      label: "Levels",
      labelClass: "muted",
      percentage: "-3.57 %",
      percentageClass: "warning",
      percentageIcon: "ri-arrow-right-down-line",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.internalLevelsCount
          : 0,
      caption: "View all orders",
      icon: "bx bx-table",
      iconClass: "primary-subtle",
      color: "primary",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: "",
    },
    
    {
      id: 6,
      label: "External Survey",
      labelClass: "muted",
      percentage: "-3.57 %",
      percentageClass: "warning",
      percentageIcon: "ri-arrow-right-down-line",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.surveyCount
          : 0,
      caption: "View all orders",
      icon: "ri-database-2-line",
      iconClass: "success-subtle",
      color: "primary",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: "",
    },
    {
      id: 2,
      label: "Job Family Group",
      labelClass: "muted",
      percentage: "-3.57 %",
      percentageClass: "warning",
      percentageIcon: "ri-arrow-right-down-line",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.JobFamilyGroupCount
          : 0,
      caption: "View all orders",
      icon: "ri-database-2-line",
      iconClass: "success-subtle",
      color: "primary",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: "",
    },
    {
      id: 7,
      label: "Job Family",
      labelClass: "muted",
      percentage: "-3.57 %",
      percentageClass: "warning",
      percentageIcon: "ri-arrow-right-down-line",
      counter:
        Object.entries(storeCount?.list).length > 0
          ? storeCount?.list?.jobFamilyCount
          : 0,
      caption: "View all orders",
      icon: "ri-database-2-line",
      iconClass: "success-subtle",
      color: "primary",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: "",
    },
  ];
  return (
    <div>
      {/* <Row>
        {(tileBoxs1 || []).map((item, key) => (
          <Col xl={6} md={6} key={key}>
            <Card className={"card-animate " + item.bgColor}>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p
                      className={
                        "text-uppercase fw-medium mb-0 text-" + item.labelClass
                      }
                    >
                      {item.label}
                    </p>
                  </div>
                 
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    <h4
                      className={
                        "fs-22 fw-semibold ff-secondary mb-0 " +
                        item.counterClass
                      }
                    >
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={4}
                        />
                      </span>
                    </h4>
                    
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span
                      className={
                        "avatar-title rounded fs-3 bg-" + item.iconClass
                      }
                    >
                      <i className={item.icon + " text-" + item.color}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row> */}
      {/* <div className="d-flex flex-wrap justify-content-between"> */}
      <Row>
        {(tileBoxs1 || []).map((item, key) => (
          <Col md={4} lg={3} sm={6}>
            <Card className={"card-animate me-2 "} key={key}>
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p
                      className={
                        "text-uppercase fw-medium mb-0 text-" + item.labelClass
                      }
                    >
                      {item.label}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div>
                    <h4
                      className={
                        "fs-22 fw-semibold ff-secondary mb-0 " +
                        item.counterClass
                      }
                    >
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={4}
                        />
                      </span>
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0 d-flex align-items-center">
                    <span
                      className={
                        "avatar-title rounded fs-3 bg-" + item.iconClass
                      }
                    >
                      <i className={item.icon + " text-" + item.color}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/* </div> */}
    </div>
  );
};
