import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Input, CardBody, Card } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import {
  ProductsGlobalFilter,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
} from "../../Components/Common/GlobalSearchFilter";
import { useLocation, useNavigate } from "react-router-dom";
import ColumnVisibility from "../Custom/ColumnVisibility";
import Pagination from "../Custom/Pagination";
import { TableHeader } from "./TableHeader";
import PageHeader from "./Header";
import PaginationContainer from "../Custom/PaginationContainer";
import { parseQueryParams } from "../../utils/queryParams";
import { showRowSelection } from "../../utils/displayOptions";
import DisplayFilters from "./DisplayFilters";

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder,
  columnFilter,
  columns,
  toggleColumnVisibility,
  isFilterBtn,
  filterAction,
  dispatchTableData,
  isUserId
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const queryParams = parseQueryParams();
  const { page, limit, ...filterQueryParams } = queryParams;

  return (
    <React.Fragment>
      <CardBody className="border-0 py-0">
        <form>
          <Row>
            <Col sm={4} className="d-flex">
              <div
                className={
                  isProductsFilter ||
                  isContactsFilter ||
                  isCompaniesFilter ||
                  isNFTRankingFilter
                    ? "search-box me-2 d-inline-block"
                    : "search-box me-2 d-inline-block w-100"
                }
              >
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control border-1 search rounded-pill w-100"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
            {isProductsFilter && <ProductsGlobalFilter />}
            {isCustomerFilter && <CustomersGlobalFilter />}
            {isOrderFilter && <OrderGlobalFilter />}
            {isContactsFilter && <ContactsGlobalFilter />}
            {isCompaniesFilter && <CompaniesGlobalFilter />}
            {isLeadsFilter && <LeadsGlobalFilter />}
            {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
            {isInvoiceListFilter && <InvoiceListGlobalSearch />}
            {isTicketsListFilter && <TicketsListGlobalFilter />}
            {isNFTRankingFilter && <NFTRankingGlobalFilter />}
            {isTaskListFilter && <TaskListGlobalFilter />}

            <Col
              sm={4}
              md={4}
              xl={4}
              className="ms-auto text-end px-0 col-md-auto d-flex align-items-center justify-content-end"
            >
              <div>
                {isFilterBtn && (
                  <div
                    className="icon-container text-nowrap d-flex align-items-center"
                    role="button"
                    onClick={filterAction}
                  >
                    <span className="filter_columns">
                      <span className="fw-bold me-1 text-primary">
                        {Object?.entries(filterQueryParams)?.length}{" "}
                      </span>
                      {"  "}Filters
                    </span>
                  </div>
                )}
              </div>
              <div>
                {columnFilter && (
                  <div className="me-2 ">
                    <ColumnVisibility
                      columns={columns}
                      toggleColumnVisibility={toggleColumnVisibility}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col className="d-flex flex-wrap mt-2 justify-content-end px-2 align-items-center">
              <DisplayFilters
                dispatchTableData={dispatchTableData}
                isUserID={isUserId}
              />
            </Col>
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter,
  isProductsFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isTaskListFilter,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  divClass,
  SearchPlaceholder,
  showButton,
  buttonAction,
  buttonText,
  disableAddBtn,
  columnFilter,
  exportButton,
  downloadFileName,
  importButton,
  importAction,
  exportHeader,
  isDefaultPagination,
  isTableHeader,
  tableHeader,
  inputValue,
  setInputValue,
  isInputEdit,
  isRowOnclick,
  rowOnclickAction,
  rowIdOnclick,
  pageTitle,
  multiInputCellObj,
  setMultiInputCellObj,
  multiInputChange,
  isPageHeader,
  isCustomPagination,
  customPaginationMeta,
  handlePageClick,
  isFilterBtn,
  filterAction,
  dispatchTableData,
  isCaption,
  captionText,
  downloadList,
  rowSelectionID,
  downloadColumns,
  isUserId,loading
}) => {
  console.log(downloadColumns,"download columns");
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      enableRowSelection: true,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: [
          {
            // id: initialSortColumn,  // Dynamically set the initial sort column
            desc: true, // Set to true for descending order
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [selectAllRows, setSelectedAllRows] = useState(true);
  const toggleColumnVisibility = (updatedVisibleColumns) => {
    setVisibleColumns(updatedVisibleColumns);
  };
  const location = useLocation();

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const toggleRowSelection = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows?.includes(row)) {
        return prevSelectedRows.filter((id) => id !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };
console.log([...visibleColumns],"[...visibleColumns]");


  return (
    <Fragment>
      {isPageHeader && (
        <PageHeader
          pageTitle={pageTitle}
          isAddBtn={showButton}
          isImportBtn={importButton}
          buttonText={buttonText}
          buttonAction={buttonAction}
          importAction={importAction}
          exportButton={exportButton}
          exportHeader={exportHeader}
          visibleColumns={downloadColumns ? [...visibleColumns,...downloadColumns] : visibleColumns}
          data={downloadList && downloadList?.length > 0 ? downloadList : data}
          downloadFileName={downloadFileName}
          isCaption={isCaption}
          captionText={captionText}
          selectedRows={selectedRows}
          rowSelectionID={rowSelectionID}
          selectAllRows={selectAllRows}
        />
      )}
      <Card className="table-ui">
        <CardBody className="p-4">
          <Row className={isGlobalSearch || isGlobalFilter ? "mb-3" : ""}>
            {isGlobalSearch && (
              <Col md={1}>
                <select
                  className="form-select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isProductsFilter={isProductsFilter}
                isCustomerFilter={isCustomerFilter}
                isOrderFilter={isOrderFilter}
                isContactsFilter={isContactsFilter}
                isCompaniesFilter={isCompaniesFilter}
                isLeadsFilter={isLeadsFilter}
                isCryptoOrdersFilter={isCryptoOrdersFilter}
                isInvoiceListFilter={isInvoiceListFilter}
                isTicketsListFilter={isTicketsListFilter}
                isNFTRankingFilter={isNFTRankingFilter}
                isTaskListFilter={isTaskListFilter}
                SearchPlaceholder={SearchPlaceholder}
                showButton={showButton}
                buttonAction={buttonAction}
                buttonText={buttonText}
                disableAddBtn={disableAddBtn}
                columnFilter={columnFilter}
                visibleColumns={visibleColumns}
                toggleColumnVisibility={toggleColumnVisibility}
                exportButton={exportButton}
                downloadFileName={downloadFileName}
                data={data}
                importButton={importButton}
                importAction={importAction}
                columns={columns}
                exportHeader={exportHeader}
                isDefaultPagination={isDefaultPagination}
                isTableHeader={isTableHeader}
                tableHeader={tableHeader}
                isRowOnclick={isRowOnclick}
                rowOnclickAction={rowOnclickAction}
                rowIdOnclick={rowIdOnclick}
                isFilterBtn={isFilterBtn}
                filterAction={filterAction}
                dispatchTableData={dispatchTableData}
                rowSelectionID={rowSelectionID}
                isUserId={isUserId}
              />
            )}
          </Row>
          <div className={divClass}>
            <Table hover {...getTableProps()} className={`${tableClass}`}>
              <thead className={`${theadClass}`}>
                {isTableHeader && <TableHeader tableHeader={tableHeader} />}
                {headerGroups.map((headerGroup) => (
                  <tr
                    className={trClass}
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {!showRowSelection?.includes(location?.pathname) && (
                      <th>
                        <Input
                          type="checkbox"
                          className="form-check-input "
                          checked={
                            (downloadList?.length > 0 &&
                              selectedRows?.length === downloadList?.length) ||
                            (page?.length > 0 &&
                              selectedRows?.length === page?.length)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const allRowIds = downloadList?.map(
                                (row) => row[rowSelectionID]
                              );
                              setSelectedRows(allRowIds);
                              setSelectedAllRows(true);
                            } else {
                              setSelectedRows([]);
                              setSelectedAllRows(false);
                            }
                          }}
                        />
                      </th>
                    )}
                    {_.sortBy(headerGroup.headers, (obj) =>
                      _.findIndex(visibleColumns, { Header: obj.Header })
                    ).map((column, index) => {
                      const isVisible = visibleColumns?.find(
                        (col) => col.accessor === column.id
                      )?.visible;
                      return (
                        isVisible && (
                          <th
                            key={column.id}
                            className={`${
                              column?.key === "actionButtons" ||
                              column?.key === "Id"
                                ? "sticky-columns sticky-id"
                                : ""
                            }`}
                            {...column.getSortByToggleProps()}
                            style={{
                              maxWidth: column.maxWidth || "",
                              minWidth: column.minWidth || "",
                            }}
                          >
                            {column.render("Header")}
                            {generateSortingIndicator(column)}
                          </th>
                        )
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr
                      >
                        {!showRowSelection?.includes(location?.pathname) && (
                          <td>
                            <Input
                              type="checkbox"
                              className="form-check-input "
                              onChange={() =>
                                toggleRowSelection(
                                  row?.original[rowSelectionID]
                                )
                              }
                              checked={selectedRows?.includes(
                                row?.original[rowSelectionID]
                              )}
                            />
                          </td>
                        )}
                        {_.sortBy(row.cells, (item) =>
                          _.findIndex(visibleColumns, {
                            Header: item.column.Header,
                          })
                        ).map((cell, index) => {
                          const isVisible = visibleColumns?.find(
                            (col) => col.accessor === cell.column.id
                          )?.visible;
                          return (
                            isVisible && (
                              <td
                                key={cell.id}
                                {...cell.getCellProps()}
                                // className={cell.column.class}
                                style={{
                                  maxWidth:
                                    headerGroups[0].headers[index]?.maxWidth,
                                  display: isVisible ? "" : "none",
                                }}
                                onClick={() => {
                                  isRowOnclick &&
                                    rowOnclickAction(row?.original[rowSelectionID]);
                                }}
                                role={isRowOnclick ? "button" : ""}
                                className={
                                  selectedRows?.includes(row?.original[rowSelectionID])
                                    ? "selected-row"
                                    : ""
                                }
                              >
                                {location?.pathname === "/market-pricing" &&
                                cell?.column?.isInputField ? (
                                  <Input
                                    className="form-control"
                                    id="level"
                                    name="level"
                                    type="text"
                                    value={cell?.value}
                                    onChange={(event) => {
                                      multiInputChange(
                                        cell?.column?.id,
                                        cell?.row?.index,
                                        event?.target?.value
                                      );
                                      setMultiInputCellObj({
                                        ...multiInputCellObj,
                                        [cell?.id]: event?.target?.value,
                                      });
                                    }}
                                  />
                                ) : cell?.row?.original[rowIdOnclick] ===
                                    isInputEdit &&
                                  cell?.column?.isInputField ? (
                                  <Input
                                    className="form-control"
                                    id="level"
                                    name="level"
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => {
                                      setInputValue(e.target.value);
                                    }}
                                  />
                                ) : (
                                  <>{cell.render("Cell")}</>
                                )}
                              </td>
                            )
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
                
              </tbody>
              {data?.length==0 && !loading && <div className="mx-3 my-3"><h4>No data</h4></div>}
            </Table>
          </div>
         
          {isDefaultPagination && (
            <Row className="align-items-center g-3 text-center text-sm-start mt-2">
              <div className="col-sm">
                <div className="text-muted">
                  Showing<span className="fw-semibold ms-1">{page.length}</span>{" "}
                  of <span className="fw-semibold">{data.length}</span> Results
                </div>
              </div>
              <Pagination
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                pageOptions={pageOptions}
                pageIndex={pageIndex}
                gotoPage={(page) => gotoPage(page)}
                canNextPage={canNextPage}
                nextPage={nextPage}
              />
            </Row>
          )}{" "}
          {isCustomPagination && (
            <PaginationContainer
              pageIndex={customPaginationMeta?.pageIndex}
              goTo={handlePageClick}
              totalPages={customPaginationMeta?.totalPages}
              totalRecords={customPaginationMeta?.count}
              pageRecords={customPaginationMeta?.pageRecords}
            />
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
