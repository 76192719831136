import React from "react";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../utils/auth";
import FilterChips from "./FilterChips";
import { useDispatch } from "react-redux";

const DisplayFilters = ({ dispatchTableData, isBorderColor, isUserID }) => {
  const userData = getUserData();
  const queryParams = parseQueryParams();
  const { page, limit, ...filterQueryParams } = queryParams;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearFilter = (paramKey) => {
    const newParams = { ...queryParams };
    delete newParams[paramKey];
    const queryString = getQueryString(newParams);
    const urlWithParams = `${location?.pathname}${queryString}`;
    navigate(urlWithParams);
    let query = !isUserID
      ? `${queryString}`
      : `${userData?.userId}/${queryString}`;

    dispatch(dispatchTableData(query));
  };

  const clearAllFilters = () => {
    const urlWithParams = `${location?.pathname}`;
    navigate(urlWithParams);
    let query = !isUserID ? "" : userData?.userId;
    dispatch(dispatchTableData(query));
  };

  return (
    <>
      {Object?.entries(filterQueryParams)?.map((ele, ind) => {
        let value = ele[1]?.label ? ele[1]?.label : ele[1];
        return (
          <FilterChips
            value={value}
            index={ind}
            pair={ele}
            isBorderColor={isBorderColor}
            clearFilterInput={() => {
              clearFilter(ele[0]);
            }}
          />
        );
      })}
      {Object?.entries(filterQueryParams)?.length > 0 && (
        <div
          className="badge badge-soft-info  mb-0 p-2 border border- rounded-pill "
          role="button"
          onClick={clearAllFilters}
        >
          <p className="fs-12 fw-normal text-info mb-0"> Clear Filters</p>
        </div>
      )}
    </>
  );
};

export default DisplayFilters;
