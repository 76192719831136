import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllEmployees as getAllEmployeesApi,
  getAllEmployeeID as getAllEmployeeIDApi,
} from "../../helpers/custom_backend_helper";

export const getEmployees = createAsyncThunk(
  "emp/getAllEmployees",
  async (query) => {
    try {
      const response = await getAllEmployeesApi(query);
      const data = await response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getEmployeeById = createAsyncThunk(
  "emp/getEmployeeId",
  async (id) => {
    try {
      const response = await getAllEmployeeIDApi(id);
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getEmployeesAll = createAsyncThunk(
  "emp/getAllEmployeesAll",
  async (query) => {
    try {
      const response = await getAllEmployeesApi(query);
      const data = await response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);