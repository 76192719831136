import React from "react";
import RangesTable from "./Table";

export const Ranges = () => {
  return (
    <React.Fragment>
      <div>
        <div className="col-sm-auto ms-auto">
          <RangesTable />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Ranges;
