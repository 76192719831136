import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import AddJobFamilyGrp from "./AddJobFamilyGrp";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Custom/Loader";
import { getJobFamilyGroup } from "../../slices/thunks";

export const JobFamilyGroup = ({
  familyGroupData,
}) => {
  const [addModal, setAddModal] = useState(false);
  const [actionType, setActionType] = useState("Add");
  const storeJobFamilyGroup = useSelector(
    (state) => state.JobArchitecture?.jobFamilyGroup
  );
  const dispatch = useDispatch()

  const columns = [
    {
      Header: "ID",
      accessor: "groupId",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          <h6 className="text-primary fw-semibold mb-0">{cellProps?.value}</h6>
        );
      },
    },
    {
      Header: "Job Family Group",
      accessor: "familyGroup",
      filterable: true,
      visible: true,
    },
  ];

  useEffect(() => {
    dispatch(getJobFamilyGroup());
  }, []);

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col md={12} lg={12} xxl={12}>
            <TableContainer
              loading={false}
              columns={columns}
              data={storeJobFamilyGroup?.familyGroup}
              isGlobalFilter={true}
              columnFilter={true}
              isAddUserList={false}
              customPageSize={10}
              showPagination={false}
              divClass="table-responsive table-card mb-0 p-3"
              tableClass="align-middle table-wrap"
              theadClass="table-light"
              SearchPlaceholder="Search for..."
              addAction={() => {}}
              buttonAction={() => {
                setAddModal(!addModal);
                setActionType("Add");
                //   setActiveId(null);
              }}
              buttonText={"Add Job Family Group"}
              showButton={true}
              exportButton={false}
              downloadFileName={"Job-Family Group"}
              importButton={false}
              isDefaultPagination={false}
              pageTitle={"Job Family Group"}
              isPageHeader={true}
            />
          </Col>
        </Row>
      </div>

      <AddJobFamilyGrp
        isOpen={addModal}
        onCloseClick={() => setAddModal(false)}
        // id={activeId}
        actionType={actionType}
      />
      {storeJobFamilyGroup?.loading && <Loader />}
    </React.Fragment>
  );
};

export default JobFamilyGroup;
