import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import companyLogo from "../../assets/images/logo-light.png";
import { UTCToLocalDateTime } from "../../utils/dataFormat";

const ExportPDF = ({
  columns,
  list,
  fileName = "",
  className = "",
  isInfo,
  infoList,
}) => {
  const mapColumns = () =>
    columns
      .filter((item) => item.visible && item.accessor !== "Actions" && item.accessor !== "rangeSpread")
      .map(({ Header, accessor }) => ({ header: Header, dataKey: accessor }));

  const convertStringToHTML = (input) => {
    let html = input
      .replace(/●/g, '- ')  // Simple bullet point replacement
      .replace(/○/g, '  - ')  // Sub-bullet point replacement
      .replace(/\\r\\n/g, ' ') // Replace newline characters
      .replace(/\n/g, ' '); // Handle new lines

    return html;
  };


  const handlePDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const header = (doc, pageCount, options) => {
      doc.setFillColor("#08569a"); // Header background color
      doc.rect(0, 0, doc.internal.pageSize.width, 20, "F");

      // Add logo on the left
      const logoImg = new Image();
      logoImg.src = companyLogo; // Replace with the path to your logo
      doc.addImage(logoImg, "PNG", 10, 3, 50, 13);

      // Page Name
      const pageName = fileName;
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(10);
      doc.text(pageName, doc.internal.pageSize.width - 10, 8, { align: 'right' });

      // Date
      const date = `Date: ${new Date().toLocaleDateString()}`;
      doc.setFontSize(8);
      doc.text(date, doc.internal.pageSize.width - 10, 15, { align: "right" });
    };

    isInfo &&
      infoList?.forEach((ele, index) => {
        doc.setFontSize(12);
        doc.setTextColor("#000");
        const positionY = 30 + index * 10;
        doc.text(ele, 10, positionY);
      });

    const headerList = mapColumns();
    const bodyData = list.map((item) => {
      // Convert the job description string to HTML-like format before adding to the body
      if (item?.jobDescription) {
        return {
          ...item,
          jobDescription: convertStringToHTML(item?.jobDescription),
        };
      } else return item;
    });

    doc.autoTable({
      headStyles: {
        fillColor: "#ECECEC",
        textColor: "#1d293b",
        lineWidth: 0.2,
        border: 1,
        halign: "center",
        valign: "middle",
        minCellWidth: 20,
      },
      theme: "grid",
      startY: isInfo ? (infoList?.length + 3) * 10 : 30,
      margin: { top: 28, left: 8, right: 8, bottom: 8 }, // Add margin to the body only
      body: bodyData,
      columns: headerList,
      didParseCell: ({ column, cell }) => {
        // Check if the cell is in the header has the '#Id' key
        if (column.raw.header === "#Id") {
          cell.styles.halign = "center";
          cell.styles.minCellWidth = 20;
        } else {
          cell.styles.minCellWidth = 20;
        }
        if (column.raw.header === "Updated Date" && cell.raw !== "Updated Date") {
          cell.text = UTCToLocalDateTime(cell.raw);
        }
      },
      didDrawPage: (options) => {
        header(doc, options.pageCount, options);
      },
    });
    const modifiedFileName = fileName.replace(/[_\s]/g, '');
    doc.save(`${modifiedFileName}.pdf`);
  };

  return (
    <div onClick={handlePDF} className={`${className} d-flex px-0`}>
      <i className="bx bxs-file-pdf  align-bottom me-2 text-muted fs-4"></i>
      <span className=""> Export to PDF</span>
    </div>
  );
};

export default ExportPDF;
