import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { BeatLoader } from "react-spinners";
import FileDropzone from "./FileDropzone";

export const FileImport = ({
    show,
    onClick,
    onCloseClick,
    handleAcceptedFiles,
    loading,
}) => {
    return (
        <div>
            <Modal
                fade={true}
                isOpen={show}
                toggle={onCloseClick}
                centered={true}
                className="border border-0"
            >
                <ModalHeader
                    className="bg-secondary p-3 "
                    toggle={() => onCloseClick(!show)}
                >
                    <span className="mb-0 h5 text-white"> Import File</span>
                </ModalHeader>
                <ModalBody className="p-3">
                    <FileDropzone
                        handleAcceptedFiles={handleAcceptedFiles}
                        className="Dropzone_File mb-3"
                        loading={loading}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};
