import { createSlice } from "@reduxjs/toolkit";
import { getGeoPayzoneById, getGeoPayzones } from "./thunk";

export const initialState = {
  GeoPayzones: { List: [], error: {}, loading: false },
  GeoPayzoneById: { data: {}, error: {}, loading: false },
  GeoPayzoneByPayzoneId: { List: [], error: {}, loading: false },
};

const PayzoneSlice = createSlice({
  name: "Payzone",
  initialState,
  reducers: {
    pushGeoPayzone(state, action) {
      state.GeoPayzones.List.push(action.payload);
    },
    deleteGeoPayzoneById(state, action) {
      state.GeoPayzones.List = state.GeoPayzones.List.filter(
        (item) => item.geoId !== action.payload
      );
    },
    updateGeoPayzone(state, action) {
      state.GeoPayzones.List = state?.GeoPayzones.List?.map((item) =>
        item?.geoId == action?.payload?.geoId
          ? { ...item, ...action?.payload }
          : item
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeoPayzones.pending, (state, action) => {
      state.GeoPayzones.List = [];
      state.GeoPayzones.loading = true;
    });
    builder.addCase(getGeoPayzones.fulfilled, (state, action) => {
      state.GeoPayzones.List = action.payload;
      state.GeoPayzones.loading = false;
    });
    builder.addCase(getGeoPayzones.rejected, (state, action) => {
      state.GeoPayzones.error = action?.error?.message || null;
      state.GeoPayzones.loading = false;
    });
    builder.addCase(getGeoPayzoneById.pending, (state, action) => {
      state.GeoPayzoneById.data = [];
      state.GeoPayzoneById.loading = true;
    });
    builder.addCase(getGeoPayzoneById.fulfilled, (state, action) => {
      state.GeoPayzoneById.data = action.payload;
      state.GeoPayzoneById.loading = false;
    });
    builder.addCase(getGeoPayzoneById.rejected, (state, action) => {
      state.GeoPayzoneById.error = action?.error?.message || null;
      state.GeoPayzoneById.loading = false;
    });
  },
});

export const { deleteGeoPayzoneById, pushGeoPayzone, updateGeoPayzone } =
  PayzoneSlice.actions;

export default PayzoneSlice.reducer;
