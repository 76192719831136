import React from "react";

export const FilterChips = ({
  value,
  index,
  pair,
  clearFilterInput,
  isBorderColor,
}) => {
  if (value) {
    return (
      <div
        className={`d-flex flex-wrap m-1 align-items-center border border-1 rounded-pill ${
          isBorderColor ? " border-info " : ""
        } `}
      >
        <div className="badge badge-s  mb-0 p-2 ">
          <p className="fs-12 fw-normal text-info mb-0"> {value}</p>
        </div>
        <div
          className="badge text-danger mb-0"
          role="button"
          onClick={() => {
            clearFilterInput(value, pair);
          }}
        >
          <i className="ri-close-fill h6 text-danger "></i>
        </div>
      </div>
    );
  }
};
export default FilterChips;
