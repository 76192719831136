import { getUserData } from "../utils/auth";
import { APIClient } from "./api_helper";
import * as url from "./url_helper";
const api = new APIClient();
const userData = getUserData();

// Login
export const postLogin = (body) => api.create(url.POST_LOGIN, body);

// Departments
export const getAllDepartments = () =>
  api.get(url.GET_DEPARTMENTS + "/" + userData?.userId);
export const getDepartmentByID = (id) =>
  api.get(url.GET_DEPARTMENT_BY_ID + "/" + id);
export const postDepartment = (body) => api.create(url.POST_DEPARTMENT, body);

// Job-Description
export const getAllJobDescriptions = (userId) =>
  api.get(url.GET_JOB_DESCRIPTIONS + "/" + userId);
export const getJobDescriptionByID = (id) =>
  api.get(url.GET_JOB_DESCRIPTION_BY_ID + "/" + id);
export const postJobDescription = (body) =>
  api.create(url.POST_JOB_DESCRIPTION, body);
export const putJobDescription = (body) =>
  api.put(url.PUT_JOB_DESCRIPTION, body);
export const deleteJobDescription = (body) =>
  api.put(url.DELETE_JOB_DESCRIPTION, body);
export const PostJDFile = (body) => api.put(url.POST_JD_FILE_IMPORT, body);

// Job Architecture
export const getAllJobArchitectures = (userId) =>
  api.get(url.GET_JOB_ARCHITECTURES + "/" + userId);
export const PostJobArchitectureFile = (body) =>
  api.put(url.POST_JOB_ARC_FILE_IMPORT, body);
export const postJobFamilyGroup = (body) =>
  api.create(url.POST_JOB_FAMILY_GROUP, body);
export const postJobFamily = (body) => api.create(url.POST_JOB_FAMILY, body);
export const getAllJobFamilyGroup = () => api.get(url.GET_JOB_FAMILY_GROUP);
export const getAllJobFamily = () => api.get(url.GET_JOB_FAMILY);
export const postJobProfile = (body) => api.create(url.POST_JOB_PROFILE, body);
export const getJobProfileByID = (id) =>
  api.get(url.GET_JOB_PROFILE_BY_ID + "/" + id);
export const putJobProfile = (body) => api.put(url.PUT_JOB_PROFILE, body);

// Level Mapping
export const getAllLevelMappingByUser = (id) =>
  api.get(url.GET_LEVEL_MAPPING_BY_USER + "/" + id);
export const postLevelMapping = (body) =>
  api.create(url.POST_LEVEL_MAPPING, body);
export const getDefaultMapping = (body) =>
  api.create(url.GET_DEFAULT_MAPPING, body);

//External Levels
export const getAllLevelMapping = () => api.get(url.GET_LEVEL_MAPPING);
export const getAllLevelsByUser = (id) =>
  api.get(url.GET_LEVELS_BY_USER + "/" + id);

// Internal Levels
export const getAllGeneralLevels = () => api.get(url.GET_GENERAL_LEVELS);
export const getAllGernalLevelsByUser = () =>
  api.get(url.GET_GENERAL_LEVELS_BY_USER);
export const postCustomInternalLevel = (body) =>
  api.create(url.POST_CUSTOM_INTERNAL_LEVEL, body);

//BenchMarking
export const fetchAIBenchMarking = (body) =>
  api.create(url.GET_AI_BENCH_MARKING, body);
export const applyBenchmarking = (body) =>
  api.create(url.APPLY_BENCHMARKING, body);
export const getAllBenchmarking = (userId) =>
  api.get(url.GET_BENCHMARKING + "/" + userId);

//Market Pricing
export const getAllMarketPricing = (body) =>
  api.create(url.GET_MARKET_PRICING, body);
export const getAllMarketData = (query) => api.get(url.GET_MARKET_DATA + query);
export const putMarketPricing = (body) => api.put(url.PUT_MARKET_PRICING, body);

// Ranges
export const getAllMarketRanges = (query) =>
  api.get(url.GET_MARKET_RANGES + query);

//Incumbent Impact Analysis
export const getAllEmployees = (query) => api.get(url.GET_EMPLOYEES + query);
export const PostEmployeeFile = (body) =>
  api.put(url.POST_EMP_FILE_IMPORT, body);
export const getAllEmployeeID = (id) =>
  api.get(url.GET_EMPLOYEE_BY_ID + "/" + id);

// Dashboard
export const getAllDashboardCount = () => api.get(url.GET_DASHBOARD_COUNT);

// Survey Data
export const getAllExternalSurveyData = () =>
  api.get(url.GET_EXTERNAL_SURVEY_DATA);
export const getAllExternalSurveyDataByID = (id) =>
  api.get(url.GET_EXTERNAL_SURVEY_DATA_BY_ID + "/" + id);
export const PostExternalSurveyData = (body) =>
  api.put(url.UPLOAD_EXTERNAL_SURVEY_DATA, body);
export const PutExternalSurveyMapper = (body) =>
  api.put(url.PUT_EXTERNAL_SURVEY_DATA_MAPPER, body);

// Internal Fields
export const getAllInternalFields = () => api.get(url.GET_INTERNAL_FIELDS);

// Payzone
export const getAllPayzones = () => api.get(url.GET_PAYZONES);
export const getPayzoneByID = (id) => api.get(url.GET_PAYZONE_BY_ID + "/" + id);
export const postPayzone = (body) => api.create(url.POST_PAYZONE, body);
export const putPayzone = (body) => api.put(url.PUT_PAYZONE, body);
export const deletePayzone = (id) => api.delete(url.DELETE_PAYZONE + "/" + id);


// Geo - Payzone
export const getAllGeoPayzones = () => api.get(url.GET_GEO_PAYZONES);
export const getGeoPayzoneByID = (id) => api.get(url.GET_GEO_PAYZONE_BY_ID + "/" + id);
export const postGeoPayzone = (body) => api.create(url.POST_GEO_PAYZONE, body);
export const putGeoPayzone = (body) => api.put(url.PUT_GEO_PAYZONE, body);
export const deleteGeoPayzone = (id) => api.delete(url.DELETE_GEO_PAYZONE + "/" + id);
