import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllJobArchitectures as getAllJobArchitecturesApi,
  getAllJobFamilyGroup as getAllJobFamilyGroupApi,
  getAllJobFamily as getAllJobFamilyApi,
  getJobProfileByID as getJobProfileByIDApi,
} from "../../helpers/custom_backend_helper";

export const getJobArchitectures = createAsyncThunk(
  "jobArchitecture/getAllJobArchitectures",
  async (userId) => {
    try {
      const response = await getAllJobArchitecturesApi(userId);
      const data = await response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getJobFamilyGroup = createAsyncThunk(
  "jobArchitecture/getAllJobFamilyGroup",
  async () => {
    try {
      const response = await getAllJobFamilyGroupApi();
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getJobFamily = createAsyncThunk(
  "jobArchitecture/getAllJobFamily",
  async () => {
    try {
      const response = await getAllJobFamilyApi();
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getJobArchitectureById = createAsyncThunk(
  "jobArchitecture/getJobArchitectureById",
  async (id) => {
    try {
      const response = await getJobProfileByIDApi(id);
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getAllJobArchitectures = createAsyncThunk(
  "jobArchitecture/getAllJobArchitecture",
  async (userId) => {
    try {
      const response = await getAllJobArchitecturesApi(userId);
      const data = await response?.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
