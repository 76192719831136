import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllMarketData as getAllMarketDataApi,
  getAllMarketPricing as getAllMarketPricingApi,
} from "../../helpers/custom_backend_helper";

export const getMarketPricing = createAsyncThunk(
  "market/getAllMarketPricing",
  async (body) => {
    try {
      const response = await getAllMarketPricingApi(body);
      const data = await response.data;
      return data;
    } catch (error) {
      // return error;
      return Promise.reject(error);
    }
  }
);
export const getMarketData = createAsyncThunk(
  "market/getAllMarketData",
  async (query) => {
    try {
      const response = await getAllMarketDataApi(query);
      const data = await response;
      return data;
    } catch (error) {
      // return error;
      return Promise.reject(error);
    }
  }
);
