import { buildOptions } from "./dataFormat";
import { getAllCountries } from "world-locations";
export const workModeOptions = [
  { label: "Work from Office", value: "Work from Office" },
  { label: "Fully Remote", value: "Fully Remote" },
  { label: "Hybrid", value: "Hybrid" },
];
export const EmploymentTypeOptions = [
  { label: "Full-time", value: "Full-time" },
  { label: "Part-time", value: "Part-time" },
  { label: "Contract", value: "Contract" },
  { label: "Temporary", value: "Temporary" },
];
let allCountriesList = getAllCountries();
export const countriesList = buildOptions(allCountriesList, "name", "iso3");
export const reportsToJobTitleOptions = [
  { label: "Manager", value: "Manager" },
];

export const internalColumnValues = [
  { name: "Survey Provider Name" },
  { name: "Survey Datacut" },
  { name: "Survey Data Effective Date" },
  { name: "Survey Job Family Group" },
  { name: "Survey Job Family" },
  { name: "Survey Job Focus/Specialization" },
  { name: "Survey Job Code" },
  { name: "Survey Job Title" },
  { name: "Survey Job Level" },
  { name: "Survey Job Description" },

  { name: "Base Weighted Average" },
  { name: "Base 10th Percentile $" },
  { name: "Base 25th Percentile $" },
  { name: "Base 40th Percentile $" },
  { name: "Base 45th Percentile $" },
  { name: "Base 50th Percentile $" },
  { name: "Base 60th Percentile $" },
  { name: "Base 65th Percentile $" },
  { name: "Base 75th Percentile $" },
  { name: "Base 90th Percentile $" },

  { name: "Bonus 10th Percentile $" },
  { name: "Bonus 25th Percentile $" },
  { name: "Bonus 40th Percentile $" },
  { name: "Bonus 45th Percentile $" },
  { name: "Bonus 50th Percentile $" },
  { name: "Bonus 60th Percentile $" },
  { name: "Bonus 65th Percentile $" },
  { name: "Bonus 75th Percentile $" },
  { name: "Bonus 90th Percentile $" },

  { name: "STI 10th Percentile $" },
  { name: "STI 25th Percentile $" },
  { name: "STI 40th Percentile $" },
  { name: "STI 45th Percentile $" },
  { name: "STI 50th Percentile $" },
  { name: "STI 60th Percentile $" },
  { name: "STI 65th Percentile $" },
  { name: "STI 75th Percentile $" },
  { name: "STI 90th Percentile $" },

  { name: "STI 10th Percentile %" },
  { name: "STI 25th Percentile %" },
  { name: "STI 40th Percentile %" },
  { name: "STI 45th Percentile %" },
  { name: "STI 50th Percentile %" },
  { name: "STI 60th Percentile %" },
  { name: "STI 65th Percentile %" },
  { name: "STI 75th Percentile %" },
  { name: "STI 90th Percentile %" },
];
export const MetricOneOptions = [
  { label: "Departments", value: "Departments" },
  { label: "State", value: "State" },
  { label: "City", value: "City" },
  { label: "Manager", value: "Manager" },
];
export const MetricTwoOptions = [
  { label: "Compa-Ratio", value: "Compa-Ratio" },
  { label: "Payzone", value: "Payzone" },
  { label: "Internal Equity", value: "Internal Equity" },
];
export const JDStatus = [
  { label: "Completed", value: "Completed" },
  { label: "Incomplete", value: "Incomplete" },
];
export const GenderOptions = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
export const OutlierOptions = [
  { label: "Above Max", value: "Above Max" },
  { label: "Below Min", value: "Below Min" },
  { label: "In Range", value: "In Range" },
];