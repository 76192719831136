import { Link } from "react-router-dom";
import React from "react";

const Pagination = ({
  pageOptions,
  pageIndex,
  gotoPage,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
}) => {
  const maxPagesToShow = 3;

  const renderPageItem = (key, text, onClick, isDisabled, isActive) => {
    return (
      <li
        key={key}
        className={`page-item ${isDisabled ? "disabled" : ""} ${
          isActive ? "active" : ""
        }`}
      >
        <button
          className={`page-link ${typeof key === "string" && "p-0 bg-none"}`}
          onClick={isDisabled ? null : onClick}
        >
          {key === "prev" ? (
            <i class="bx bx-chevron-left fs-3 mt-1"></i>
          ) : key === "next" ? (
            <i class="bx bx-chevron-right fs-3 mt-1"></i>
          ) : (
            text
          )}
        </button>
      </li>
    );
  };
  const getPageItems = () => {
    const totalPageCount = pageOptions.length;

    if (totalPageCount <= maxPagesToShow) {
      const pageItems = [
        renderPageItem(
          "prev",
          "Previous",
          previousPage,
          !canPreviousPage,
          pageIndex > 1 ? true : false
        ),
        ...pageOptions.map((item, key) =>
          renderPageItem(
            key,
            item + 1,
            () => gotoPage(item),
            false,
            pageIndex === item
          )
        ),
        renderPageItem("next", "Next", nextPage, !canNextPage, false),
      ];

      return pageItems;
    } else {
      const minPage = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
      const maxPage = Math.min(
        totalPageCount - 1,
        minPage + maxPagesToShow - 1
      );

      const pageItems = [
        renderPageItem(
          "prev",
          "Previous",
          previousPage,
          minPage < 0 || !canPreviousPage,
          false
        ),
      ];

      if (minPage > 0) {
        pageItems.push(
          renderPageItem(
            "ellipsis-start",
            "...",
            () => gotoPage(minPage - 1),
            false,
            false
          )
        );
      }

      for (let i = minPage; i <= maxPage; i++) {
        pageItems.push(
          renderPageItem(i, i + 1, () => gotoPage(i), false, pageIndex === i)
        );
      }

      if (maxPage < totalPageCount - 1) {
        pageItems.push(
          renderPageItem(
            "ellipsis-end",
            "...",
            () => gotoPage(maxPage + 1),
            false,
            false
          )
        );
      }

      pageItems.push(
        renderPageItem(
          "next",
          "Next",
          nextPage,
          maxPage > totalPageCount - 1 || !canNextPage,
          false
        )
      );

      return pageItems;
    }
  };

  return (
    <div className="col-sm-auto">
      <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0 pagination-rounded">
        {getPageItems()}
      </ul>
    </div>
  );
};

export default Pagination;
