import { createSlice } from "@reduxjs/toolkit";
import { getBenchmarking } from "./thunk";

export const initialState = {
  benchmarking: [],
  error: {},
  loading: false,
  meta: {
    limit: 10,
    page: 1,
    count: null,
    totalPages: null,
  },
};

const BenchmarkingSlice = createSlice({
  name: "Benchmarking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBenchmarking.pending, (state, action) => {
      state.benchmarking = [];
      state.loading = true;
    });
    builder.addCase(getBenchmarking.fulfilled, (state, action) => {
      state.benchmarking = action.payload?.data;
      state.loading = false;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getBenchmarking.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.loading = false;
    });
  },
});

// export const {  } =
//     BenchmarkingSlice.actions;

export default BenchmarkingSlice.reducer;
