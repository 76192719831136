import { toast } from "react-toastify";
const autoClose = 3000;

export const toasterSuccess = (message) => {
    toast.success(message, { autoClose: autoClose });
};
export const toasterWarning = (message) => {
    toast.warning(message, { autoClose: autoClose });
};
export const toasterError = (message) => {
    toast.error(message, { autoClose: autoClose });
};
