import { createSlice } from "@reduxjs/toolkit";
import { getDashboardCount, getDepartmentById, getDepartments } from "./thunk";

export const initialState = {
  count: { list: {}, loading: false, error: "" },
};

const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardCount.pending, (state, action) => {
      state.count.list = {};
      state.count.loading = true;
    });
    builder.addCase(getDashboardCount.fulfilled, (state, action) => {
      state.count.list = action.payload;
      state.count.loading = false;
    });
    builder.addCase(getDashboardCount.rejected, (state, action) => {
      state.count.error = action?.error?.message || null;
      state.count.loading = false;
    });
  },
});

export default DashboardSlice.reducer;
