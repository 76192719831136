import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import DeleteModal from "../../Components/Common/DeleteModal";
import ImportSurveyData from "./importModal";

export const FileList = ({ data }) => {
  const [isCartDropdown, setIsCartDropdown] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [activeDataCut, setActiveDataCut] = useState("");

  const toggleCartDropdown = () => {
    setIsCartDropdown(!isCartDropdown);
  };

  return (
    <div className="">
      <div className="d-flex align-items-center ">
        <div className="">
          <Dropdown
            isOpen={isCartDropdown}
            toggle={toggleCartDropdown}
            className="topbar-head-dropdown"
          >
            <DropdownToggle
              type="button"
              tag="button"
              className="border border-0 bg-white px-0"
              //   id={`${levelUser?.label} - ${levelUser?.Id}`}
            >
              <h6 className="mb-0 text-info text-decoration-underline">
                {data?.dataCut?.length} Data Cut
                {data?.dataCut?.length > 1 && "s"}
              </h6>
            </DropdownToggle>

            <DropdownMenu
              className="dropdown-menu-md dropdown-menu-end p-0 dropdown-menu-cart mt-1"
              aria-labelledby="page-header-cart-dropdown"
            >
              <SimpleBar style={{ maxHeight: "40vh" }}>
                <div>
                  {data?.dataCut?.map((ele, ind) => {
                    return (
                      <div className="border border-start-0 border-end-0 border-top-0 border-bottom-1 border-dashed d-flex justify-content-between align-items-center p-2 px-4">
                        <div className="w-75">
                          <h6 key={ind} className="mb-0 text-primary text-wrap">
                            {ele?.name}
                          </h6>
                          <small>{ele?.totalRecords} records</small>
                        </div>
                        <div className="d-flex align-items-center">
                          <i
                            role="button"
                            className={`ri-add-line fs-16 text-info me-1 `}
                            onClick={() => {
                              setImportModal(!importModal);
                              setActiveData(data);
                              setActiveDataCut(ele?.name);
                            }}
                          ></i>
                          <i
                            role="button"
                            className={`ri-delete-bin-5-line fs-6 text-danger `}
                            onClick={() => {
                              setDeleteModal(!deleteModal);
                            }}
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SimpleBar>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <DeleteModal
        show={deleteModal}
        onCloseClick={setDeleteModal}
        // onDeleteClick={handleDelete}
        // activeDeleteId={activeId}
        // loading={loading}
      />
      <ImportSurveyData
        show={importModal}
        onCloseClick={setImportModal}
        onClick={() => {}}
        modalType={"data-sub-set"}
        activeData={activeData}
        activeDataCut={activeDataCut}
      />
    </div>
  );
};
