import * as React from "react";
import ReactToPrint from "react-to-print";
import { Button } from "reactstrap";
import { BeatLoader } from "react-spinners";

export const Print = () => {
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
    }, []);

    const handleBeforePrint = React.useCallback(() => {
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        const content = document.getElementById("offcanvas-body");
        return content;
    }, []);

    const reactToPrintTrigger = React.useCallback(() => {
        return (
            <Button className="btn-icon-text btn btn-sm px-3 btn-soft-light">
                <div className="d-flex align-items-center ">
                    {loading ? (
                        <BeatLoader color="#08569a" size={11} className="" />
                    ) : (
                        <>
                            <i class="bx bxs-printer text-dark me-1 fs-6"></i>
                            <h6 className="btn-text  mb-0 ">Print</h6>
                        </>
                    )}{" "}
                </div>
            </Button>
        );
    }, [loading]);

    return (
        <div>
            <ReactToPrint
                bodyClass="print-agreement"
                content={reactToPrintContent}
                documentTitle="AwesomeFileName"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={reactToPrintTrigger}
            />
        </div>
    );
};
