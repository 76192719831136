import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { buildOptions, getOptionByValue } from "../../utils/dataFormat";
import { JDStatus } from "../../utils/dataOptions";
import { Formik } from "formik";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import { getGeneralLevels, getJobDescriptions } from "../../slices/thunks";
import { getUserData } from "../../utils/auth";
import SimpleBar from "simplebar-react";

const defaultValues = {
  internalJobtitle: "",
  jobLevel: "",
  status: "",
};

const JDFilters = ({ show, onCloseClick }) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = parseQueryParams();
  const storeJobDescription = useSelector((state) => state.JobDescription);
  const storeLevels = useSelector((state) => state.GeneralLevels);
  const userData = getUserData();

  useEffect(() => {
    if (storeLevels?.levels?.length == 0) {
      dispatch(getGeneralLevels());
    }
  }, [show]);

  useEffect(() => {
    if (Object.entries(queryParams).length > 0 && show) {
      setInitialValues({ ...initialValues, ...queryParams });
    } else setInitialValues(defaultValues);
  }, [show]);


  const applyFilters = (values) => {
    let data = {
      internalJobtitle: values?.internalJobtitle,
      level: values?.level,
      status: values?.status,
    };
    const queryString = getQueryString(data);
    const urlWithParams = `/job-description${queryString}`;
    navigate(urlWithParams);
    dispatch(getJobDescriptions(`${userData?.userId}${queryString}`));
    onCloseClick();
  };

  const clearFilters = (e) => {
    e.preventDefault();
    onCloseClick();
    navigate("/job-description");
    dispatch(getJobDescriptions(userData?.userId));
    setInitialValues(initialValues);
    setFilterValues({});
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader
        className="bg-soft-primary"
        toggle={() => onCloseClick(!show)}
      >
        <h5 className=" text-primary mb-0 "> FILTERS - Job Description</h5>
      </OffcanvasHeader>
      <OffcanvasBody>
        <Formik enableReinitialize initialValues={initialValues}>
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <Row>
                <SimpleBar className="modalBody-scroll">
                  <Col md={12} className="mb-4">
                    <Label>Job Title</Label>
                    <ReactSelect
                      id="internalJobtitle"
                      className=" mb-0"
                      name="internalJobtitle"
                      value={
                        values?.internalJobtitle
                          ? getOptionByValue(values?.internalJobtitle)
                          : null
                      }
                      onChange={(event) => {
                        setFieldValue("internalJobtitle", event?.label);
                      }}
                      options={buildOptions(
                        storeJobDescription?.jobDescriptions,
                        "internalJobtitle",
                        "internalJobtitle"
                      )}
                    />
                  </Col>
                  <Col md={12} className="mb-4">
                    <Label>Job Level</Label>
                    <ReactSelect
                      id="level"
                      className=" mb-0"
                      name="level"
                      value={
                        values?.level ? getOptionByValue(values?.level) : null
                      }
                      onChange={(event) => {
                        setFieldValue("level", event?.label);
                      }}
                      options={buildOptions(
                        storeLevels?.levels,
                        "levelCode",
                        "levelCode"
                      )}
                    />
                  </Col>
                  <Col md={12} className="mb-4">
                    <Label>Status</Label>
                    <ReactSelect
                      id="statuss"
                      className=" mb-0"
                      name="status"
                      value={
                        values?.status ? getOptionByValue(values?.status) : null
                      }
                      onChange={(event) => {
                        setFieldValue("status", event?.label);
                      }}
                      options={JDStatus}
                    />
                  </Col>
                </SimpleBar>
                <div className=" d-flex justify-content-end position-absolute bottom-0 py-4 pe-4 bg-white ">
                  <Button
                    className="btn theme-cancel-btn me-2 px-4 w-100"
                    color="white"
                    onClick={(e) => {
                      //   onCloseClick(!show);
                      clearFilters(e);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn px-4 w-100"
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      applyFilters(values);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Row>
            );
          }}
        </Formik>{" "}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default JDFilters;
