import React, { useCallback, useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import { FileImport } from "../../Components/Custom/FileImport";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  addEdge,
  Background,
  MiniMap,
} from "reactflow";
import {
  getInternalColumnNodes,
  getNodes,
  getOutputNodes,
  nodeColor,
} from "../../Components/Custom/ReactFlow/Data";
import * as XLSX from "xlsx";
import ButtonEdge from "../../Components/Custom/ReactFlow/ButtonEdge";
import { PostJobArchitectureFile } from "../../helpers/custom_backend_helper";
import { getUserData } from "../../utils/auth";
import { internalColumnValues } from "../../utils/dataOptions";

export const ExternalLevels = () => {
  const userData = getUserData();
  const [importModal, setImportModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState();

  function handleAcceptedFiles(acceptedFiles) {
    const file = acceptedFiles[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();

      // reader.onload = function (event) {
      //     const data = new Uint8Array(event.target.result);
      //     const workbook = XLSX.read(data, { type: "array" });
      //     const sheetName = workbook.SheetNames[0];
      //     const worksheet = workbook.Sheets[sheetName];
      //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      //     let result = jsonData[0];
      //     const newColumns = Array.from(
      //         { length: 15 },
      //         (_, index) => result[index]
      //     );
      //     let newnodes = getOutputNodes(newColumns);
      //     setInitialNodes([...initialNodes, ...newnodes]);
      //     setNodes([...nodes, ...newnodes]);
      // };

      reader.onload = function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        let jsonData = [];
        // Iterate through each sheet name
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          jsonData.push(json);
        });

        if (jsonData?.length > 0) {
          const mergedData = mergeWithCommonKey(jsonData, "Job Code");
          // console.log(mergedData,"merged data");
          if (mergedData?.length > 0) {
            let allColumns = getCoulumnNames(mergedData);
            // const newColumns = Array.from(
            //     { length: 40 },
            //     (_, index) => allColumns[index]
            // );
            // let newnodes = getOutputNodes(newColumns);
            let newnodes = getOutputNodes(getCoulumnNames(mergedData));
            setInitialNodes([...initialNodes, ...newnodes]);
            setNodes([...nodes, ...newnodes]);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
    setImportModal(!importModal);
  }

  const mergeWithCommonKey = (arrays, commonKey) => {
    // Find the common keys present in all arrays
    const commonKeys = arrays.reduce((common, array) => {
      const keys = new Set(array.map((obj) => obj[commonKey]));
      return common
        ? new Set([...common].filter((key) => keys.has(key)))
        : keys;
    }, null);

    // Filter objects in each array to keep only those with common keys
    const filteredArrays = arrays.map((array) =>
      array.filter((obj) => commonKeys.has(obj[commonKey]))
    );

    // Merge filtered arrays by common key
    const mergedArray = mergeArraysByCommonKey(filteredArrays, commonKey);
    // console.log(mergedArray,"array");
    return mergedArray;
  };

  const mergeArraysByCommonKey = (arrays, commonKey) => {
    const mergedMap = new Map();

    // Iterate over each array
    arrays.forEach((array) => {
      // Iterate over the objects in the array
      array.forEach((obj) => {
        const keyValue = obj[commonKey];
        if (!mergedMap.has(keyValue)) {
          mergedMap.set(keyValue, {});
        }
        mergedMap.set(keyValue, { ...mergedMap.get(keyValue), ...obj });
      });
    });

    // Convert map values to an array
    const mergedArray = Array.from(mergedMap.values());
    return mergedArray;
  };
  const getCoulumnNames = (data) => {
    return Object?.keys(data[0]);
  };
  const edgeTypes = {
    buttonedge: (props) => <ButtonEdge {...props} isEdit={isEdit} />,
  };

  useEffect(() => {
    if (internalColumnValues?.length > 0) {
      const newInitialNodes = getNodes(internalColumnValues, "input");
      setInitialNodes(newInitialNodes);
      setNodes(newInitialNodes);
    }
  }, []);

  useEffect(() => {
    if (initialEdges?.length > 0) {
      const newInitialNodes = getNodes(internalColumnValues, "input");
      setInitialNodes(newInitialNodes);
      setNodes(newInitialNodes);
    }
  }, []);

  const onConnect = useCallback(
    (params) => {
      const { source, target, type } = params;

      let newType = type || "buttonedge";
      setEdges((els) => addEdge({ ...params, type: newType }, els));
    },
    [nodes, edges, setEdges]
  );

  const onNodeDragStop = (event, node) => {
    const updatedNodes = nodes.map((n) => {
      if (n.id === node.id) {
        return {
          ...n,
          position: { x: node.__rf.position.x, y: node.__rf.position.y },
        };
      }
      return n;
    });
    setNodes(updatedNodes);
  };

  const handleSubmit = () => {
    let levelMapping = [];
    const formData = new FormData();
    formData.append("userId", userData?.userId);
    Object.keys(file).forEach((key) => {
      formData.append(`survey_xl`, file);
    });
    edges?.length > 0 &&
      edges?.forEach((ele) => {
        let internalCode = ele?.source.split("I-").pop();
        let externalCode = ele?.target.split("E-").pop();
        let obj = {
          externalColumn: externalCode,
          internalColumn: internalCode,
        };
        levelMapping?.push(obj);
      });
    formData.append("mapping", JSON.stringify(levelMapping));
    // setLoader(true);
    PostJobArchitectureFile(formData)
      .then((res) => {
        if (
          res?.metadata?.status === 200 ||
          res?.metadata?.message == "Success"
        ) {
        }
      })
      .catch((error) => {
        console.error(error);
        // setLoader(false);
      });
  };
  return (
    <div>
      <div className="page-content">
        <BreadCrumb title="External Levels" pageLink="/external-levels" />
        <Row>
          <Col md={12} lg={12} xxl={12}>
            <Card className="level-chart pb-4">
              <CardBody className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-sm me-1 btn-soft-secondary"
                      onClick={() => {
                        setImportModal(!importModal);
                      }}
                    >
                      <i className="bx bx-import "></i> Import
                    </button>

                    <button
                      className="btn btn-sm btn-soft-success d-flex align-items-center"
                      onClick={() => setIsEdit(true)}
                    >
                      <i className="bx bxs-edit me-1"></i>
                      <p className="mb-0">Edit</p>
                    </button>
                  </div>
                  {isEdit && (
                    <div className="">
                      <button
                        className="btn theme-cancel-btn px-4 mx-2"
                        onClick={() => {
                          setEdges(initialEdges);
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-sm btn-primary px-4"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  edgeTypes={edgeTypes}
                  snapToGrid
                  onConnect={onConnect}
                  fitView
                  attributionPosition="bottom-right"
                  snapGrid={[15, 15]}
                  minZoom={0}
                  maxZoom={2}
                  nodesDraggable={isEdit ? true : false}
                  nodesConnectable={isEdit ? true : false}
                >
                  <Controls />
                  <MiniMap zoomable pannable nodeColor={nodeColor} />
                  <Background gap={25} />
                </ReactFlow>
              </CardBody>
            </Card>
            <FileImport
              show={importModal}
              onCloseClick={setImportModal}
              onClick={() => {}}
              handleAcceptedFiles={handleAcceptedFiles}
              loading={loader}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ExternalLevels;
