import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllDepartments as getAllDepartmentsApi,
    getDepartmentByID as getDepartmentByIDApi,
} from "../../helpers/custom_backend_helper";
import { postProcess as postProcessApi } from "../../helpers/custom_backend_helper";

export const getDepartments = createAsyncThunk(
    "departments/getDepartments",
    async () => {
        try {
            const response = await getAllDepartmentsApi();
            const data = await response.data;
            return data;
        } catch (error) {
            // return error;
            return Promise.reject(error);
        }
    }
);
export const getDepartmentById = createAsyncThunk(
    "departments/getDepartmentById",
    async (id) => {
        try {
            const response = await getDepartmentByIDApi(id);
            const data = await response.data;
            return data;
        } catch (error) {
            // return error;
            return Promise.reject(error);
        }
    }
);
