import { createSlice } from "@reduxjs/toolkit";
import { getDepartmentById, getDepartments } from "./thunk";

export const initialState = {
    departments: [],
    error: {},
    loading: false,
    departmentById: { department: {}, error: {}, loading: false },
};

const DepartmentSlice = createSlice({
    name: "Department",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDepartments.pending, (state, action) => {
            state.departments = [];
            state.loading = true;
        });
        builder.addCase(getDepartments.fulfilled, (state, action) => {
            state.departments = action.payload;
            state.loading = false;
        });
        builder.addCase(getDepartments.rejected, (state, action) => {
            state.error = action?.error?.message || null;
            state.loading = false;
        });
        builder.addCase(getDepartmentById.pending, (state, action) => {
            state.departmentById.department = {};
            state.departmentById.loading = true;
        });
        builder.addCase(getDepartmentById.fulfilled, (state, action) => {
            state.departmentById.department = action.payload;
            state.departmentById.loading = false;
        });
        builder.addCase(getDepartmentById.rejected, (state, action) => {
            state.departmentById.error = action?.error?.message || null;
            state.departmentById.loading = false;
        });
    },
});

// export const {  } =
//     DepartmentSlice.actions;

export default DepartmentSlice.reducer;
