import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Custom/Loader";
import { getGeneralLevels, getLevelMapping } from "../../slices/thunks";
import AddCustomLevels from "./Add";
import PageHeader from "../../Components/Common/Header";

export const LevelChart = () => {
  const storeLevelMapping = useSelector((state) => state?.Levels);
  const storeLevelChart = useSelector((state) => state.GeneralLevels);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeId, setActiveId] = useState();
  const [actionType, setActionType] = useState("Add");

  useEffect(() => {
    dispatch(getLevelMapping());
    dispatch(getGeneralLevels());
  }, []);

  useEffect(() => {
    if (storeLevelChart?.levels?.length > 0) {
      let data = [...storeLevelChart?.levels];
      data?.reverse();
      let i = 0;
      let result = buildLevelObj(data, i, []);
      setData(result);
    }
  }, [storeLevelChart]);

  const buildLevelObj = (data, index, result) => {
    let item = data[index];
    if (item) {
      if (item["isAdded"]) {
        index++;
        return buildLevelObj(data, index, result);
      } else {
        let newObj = { ...item, isAdded: true };
        let obj = {
          [item.management]: newObj,
        };
        let sameObj = getSameLevelItems(data, item, obj);
        data = sameObj.data;
        obj = sameObj.result;
        result.push(obj);
        index++;
        return buildLevelObj(data, index, result);
      }
    } else {
      return { data: data, result: result };
    }
  };

  const getSameLevelItems = (data, item, result) => {
    if (item.sameLevel) {
      const sameLevelIndex = data.findIndex(
        (ele) => ele.levelCode === item.sameLevel
      );
      if (sameLevelIndex > -1) {
        let sameLevelItem = data[sameLevelIndex];
        let modifiedItem = { ...sameLevelItem, isAdded: true };
        result[sameLevelItem.management] = modifiedItem;
        data[sameLevelIndex] = modifiedItem;
        if (sameLevelItem.sameLevel) {
          let res = getSameLevelItems(data, sameLevelItem, result);
          data = res.data;
          result = res.result;
        }
      }
    }
    return { data, result };
  };

  const columns = [
    {
      Header: "Executive ",
      accessor: "Executive",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          cellProps?.row?.original?.Executive?.levelCode && (
            <h6 className="text-primary fw-semibold mb-0 badge-soft-info p-2">
              {cellProps?.row?.original?.Executive?.levelCode} -{" "}
              {cellProps?.row?.original?.Executive?.level}
            </h6>
          )
        );
      },
      class: "p-0",
    },
    {
      Header: "Management",
      accessor: "generalJobType",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          cellProps?.row?.original?.Management?.levelCode && (
            <h6 className="text-primary fw-semibold mb-0 badge-soft-danger p-2">
              {cellProps?.row?.original?.Management?.levelCode} -{" "}
              {cellProps?.row?.original?.Management?.level}
            </h6>
          )
        );
      },
      class: "p-0",
    },
    {
      Header: "Professional",
      accessor: "mappedLevel",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          cellProps?.row?.original?.Professional?.levelCode && (
            <h6 className="text-primary fw-semibold mb-0 badge-soft-success p-2">
              {cellProps?.row?.original?.Professional?.levelCode} -{" "}
              {cellProps?.row?.original?.Professional?.level}
            </h6>
          )
        );
      },
      class: "p-0",
    },
    {
      Header: "Support",
      accessor: "jobType",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          cellProps?.row?.original?.Support?.levelCode && (
            <h6 className="text-primary fw-semibold mb-0 badge-soft-warning p-2">
              {cellProps?.row?.original?.Support?.levelCode} -{" "}
              {cellProps?.row?.original?.Support?.level}
            </h6>
          )
        );
      },
      class: "p-0",
    },
  ];

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          {/* <BreadCrumb title="Level Chart" pageLink="/level-chart" /> */}

          <Row>
            <Col md={12} lg={12} xxl={12}>
              {/* <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className={"btn btn-primary add-btn  px-2  my-2  "}
                      id="create-btn"
                      onClick={() => {
                        setAddModal(!addModal);
                        setActionType("Add");
                        setActiveId(null);
                      }}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                      Custom Levels
                    </button>
                  </div> */}

              <TableContainer
                loading={false}
                columns={columns}
                data={data?.result || []}
                isGlobalFilter={false}
                columnFilter={false}
                isAddUserList={false}
                customPageSize={50}
                showPagination={false}
                divClass="table-responsive table-card mb-0 p-3"
                tableClass="align-middle table-wrap"
                theadClass="table-light"
                SearchPlaceholder="Search for..."
                showButton={false}
                exportButton={false}
                importButton={false}
                isDefaultPagination={false}
                isTableHeader={false}
                pageTitle={"Level Chart"}
                isPageHeader={true}
              />
              <AddCustomLevels
                isOpen={addModal}
                onCloseClick={() => setAddModal(false)}
                id={activeId}
                actionType={actionType}
              />
            </Col>
          </Row>
        </div>
        {storeLevelMapping?.loading && <Loader />}
      </React.Fragment>
    </div>
  );
};
export default LevelChart;
