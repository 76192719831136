import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllInternalFields as getAllInternalFieldsApi } from "../../helpers/custom_backend_helper";
export const getInternalFields = createAsyncThunk(
  "external/getIntenalFields",
  async () => {
    try {
      const response = await getAllInternalFieldsApi();
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
