import React, { useMemo } from "react";
import * as XLSX from "xlsx";
import { UTCToLocalDateTime } from "../../utils/dataFormat";

const ExcelExport = React.memo(
  ({ columns, list, fileName, className = "" }) => {
    const header = useMemo(
      () =>
        columns
          ?.filter((item) => item.visible && item.Header !== "Actions")
          ?.map((item) => ({
            header: item.Header,
            dataKey: item.accessor,
          })),
      [columns]
    );

    const filteredData = list?.map((item) =>
      header?.reduce((filteredItem, headerItem) => {
        const newVal =
          headerItem.header === "Updated Date"
            ? UTCToLocalDateTime(item[headerItem.dataKey])
            : item[headerItem.dataKey];

        return {
          ...filteredItem,
          [headerItem.dataKey]: newVal,
        };
      }, {})
    );

    const calculateColumnWidths = (header, filteredData) =>
      header?.map((headerItem) => ({
        wch:
          Math.max(
            headerItem.header?.length,
            ...filteredData?.map(
              (dataItem) => String(dataItem[headerItem.dataKey]).length
            )
          ) + 2,
      }));

    const exportToExcel = () => {
      const headerRow = header?.map((item) => item.header);
      const ws = XLSX.utils.aoa_to_sheet([headerRow]);

      XLSX?.utils?.sheet_add_json(ws, filteredData, {
        skipHeader: true,
        origin: "A2",
      });

      ws["!cols"] = calculateColumnWidths(header, filteredData);
      ws.s = { fill: { fgColor: "#1c293b" }, font: { color: "red" } };

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      const modifiedFileName = fileName.replace(/[_\s]/g, "");
      XLSX.writeFile(wb, `${modifiedFileName}.xlsx`);
    };

    return (
      <div
        onClick={() => exportToExcel()}
        className={`${className} d-flex px-0`}
      >
        <i className="bx bxs-file align-bottom me-2 text-muted fs-4"></i>
        <span className="">Export to Excel</span>
      </div>
    );
  }
);

export default ExcelExport;
