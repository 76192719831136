import { createSlice } from "@reduxjs/toolkit";
import { getMarketRanges } from "./thunk";

export const initialState = {
  marketRanges: {
    ranges: [],
    error: {},
    loading: false,
    meta: {
      limit: 10,
      page: 1,
      count: null,
      totalPages: null,
    },
  },
};

const MarketRangesSlice = createSlice({
  name: "MarketRanges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMarketRanges.pending, (state, action) => {
      state.marketRanges.ranges = [];
      state.marketRanges.loading = true;
    });
    builder.addCase(getMarketRanges.fulfilled, (state, action) => {
      state.marketRanges.ranges = action.payload.data;
      state.marketRanges.loading = false;
      state.marketRanges.meta = action.payload.meta;
    });
    builder.addCase(getMarketRanges.rejected, (state, action) => {
      state.marketRanges.error = action?.error?.message || null;
      state.marketRanges.loading = false;
    });
  },
});

export const {} = MarketRangesSlice.actions;

export default MarketRangesSlice.reducer;
