import React from "react";
import { formatSalary } from "../../utils/dataFormat";

const RangeSpread = ({ min, max, mid, salary, percentage }) => {
  const coveredRange = { start: min, end: salary };

  const totalRange = max - min;
  const coveredStartPercent = ((coveredRange.start - min) / totalRange) * 100;
  const coveredEndPercent = ((coveredRange.end - min) / totalRange) * 100;

  return (
    <div className="range-container px-2 ">
      <div className="coverage-labels">
        {min < salary && max > salary && (
          <div
            style={{ left: `${coveredEndPercent}%` }}
            className="text-primary fw-semibold"
          >
            ${formatSalary(coveredRange.end)}
          </div>
        )}
      </div>
      <div className="range-bar">
        {min < salary && max > salary && (
          <div
            className="range-coverage"
            style={{
              left: `${coveredStartPercent}%`,
              width: `${coveredEndPercent - coveredStartPercent}%`,
            }}
          ></div>
        )}
        {min < salary && max > salary && (
          <div
            className="coverage-percentage text-sendary"
            style={{
              left: `${(coveredEndPercent + -2 + coveredStartPercent) / 2}%`,
            }}
          >
            {percentage}
          </div>
        )}
        {min > salary && <div className="below-min"></div>}
        <div className="mid-divider"></div>
        {max < salary && (
          <div
            className="above-max"
            style={{
              left: `${coveredStartPercent}%`,
              width: `100%`,
            }}
          ></div>
        )}
      </div>
      <div className="range-labels">
        <div style={{ left: "5%" }} className="min-label text-primary">
          ${formatSalary(min)}
        </div>
        <div style={{ left: "50%" }} className="mid-label text-primary">
          ${formatSalary(mid)}
        </div>
        <div style={{ left: "95%" }} className="max-label text-primary">
          ${formatSalary(max)}
        </div>
      </div>
    </div>
  );
};

export default RangeSpread;
