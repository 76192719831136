import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import LinewithDataLabels from "../../Components/Charts/LinewithDataLabels";
import { CountCards } from "../../Components/Charts/CountCards";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardCount, getJobFamily, getJobFamilyGroup } from "../../slices/thunks";
import { buildOptions, getValues } from "../../utils/dataFormat";
import { getUserData } from "../../utils/auth";
import GroupedBarVisual from "./GroupedBarChart";
import PieChartVisual from "./PieChart";

const DashboardEcommerce = () => {
  document.title = "Compscale";
  const dispatch = useDispatch();
  const userData = getUserData();
  const [jobFamily, setJobFamily] = useState([]);
  const storeFamilyGroup = useSelector(
    (state) => state?.JobArchitecture?.jobFamilyGroup
  );
  const storeFamily = useSelector((state) => state?.JobArchitecture?.jobFamily);
  useEffect(() => {
    dispatch(getJobFamilyGroup(userData?.userId));
    dispatch(getDashboardCount())
  }, []);

  useEffect(() => {
    setJobFamily(getValues(storeFamily?.family, "familyName"));
  }, [storeFamily]);
  
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid>
          <Row className="dashboard">
            <Col md={12} lg={12} xl={12} className="p-0">
              <CountCards />
            </Col>
            <Col md={6} sm={12} className="ps-0">
              <Card>
                <CardBody>
                  <LinewithDataLabels dataColors='["#143D59"]' />
                </CardBody>
              </Card>
            </Col>
            <Col md={6} sm={12} className="p-0">
              <GroupedBarVisual />
            </Col>
            <Col md={6} sm={12} className="ps-0 pt-0">
              <PieChartVisual />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
