import { createSlice } from "@reduxjs/toolkit";
import {
  getJobDescriptions,
  getJobDescriptionById,
  getJobDescriptionsAll,
} from "./thunk";

export const initialState = {
  jobDescriptions: [],
  error: {},
  loading: false,
  allList:[],
  jobDescriptionById: { jobDescription: {}, error: {}, loading: false },
  meta: {
    limit: 10,
    page: 1,
    count: null,
    totalPages: null,
  },
};

const JobDescriptionSlice = createSlice({
  name: "JobDescription",
  initialState,
  reducers: {
    pushJobDescription(state, action) {
      state.jobDescriptions.push(action.payload);
    },
    updateJobDescription(state, action) {
      state.jobDescriptions = state?.jobDescriptions?.map((item) =>
        item?.jobDescriptionId == action?.payload?.jobDescriptionId
          ? { ...item, ...action?.payload }
          : item
      );
    },
    deleteAJobDescription(state, action) {
      state.jobDescriptions = state.jobDescriptions.filter(
        (item) => item.jobDescriptionId !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobDescriptions.pending, (state, action) => {
      state.jobDescriptions = [];
      state.loading = true;
    });
    builder.addCase(getJobDescriptions.fulfilled, (state, action) => {
      state.jobDescriptions = action.payload?.data;
      state.meta = action?.payload?.meta;
      state.loading = false;
    });
    builder.addCase(getJobDescriptions.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.loading = false;
    });
    builder.addCase(getJobDescriptionById.pending, (state, action) => {
      state.jobDescriptionById.jobDescription = {};
      state.jobDescriptionById.loading = true;
    });
    builder.addCase(getJobDescriptionById.fulfilled, (state, action) => {
      state.jobDescriptionById.jobDescription = action.payload;
      state.jobDescriptionById.loading = false;
    });
    builder.addCase(getJobDescriptionById.rejected, (state, action) => {
      state.jobDescriptionById.error = action?.error?.message || null;
      state.jobDescriptionById.loading = false;
    });
    builder.addCase(getJobDescriptionsAll.pending, (state, action) => {
      state.allList = [];
    });
    builder.addCase(getJobDescriptionsAll.fulfilled, (state, action) => {
      state.allList = action.payload?.data;
    });
    builder.addCase(getJobDescriptionsAll.rejected, (state, action) => {
      state.error = action?.error?.message || null;
    });
  },
});

export const {
  pushJobDescription,
  updateJobDescription,
  deleteAJobDescription,
} = JobDescriptionSlice.actions;

export default JobDescriptionSlice.reducer;
