import { createSlice } from "@reduxjs/toolkit";
import {
  getAllJobArchitectures,
  getJobArchitectureById,
  getJobArchitectures,
  getJobFamily,
  getJobFamilyGroup,
} from "./thunk";

export const initialState = {
  jobArchitectures: [],
  error: {},
  loading: false,
  jobArchitectureById: { jobArchitecture: {}, error: {}, loading: false },
  jobFamilyGroup: { familyGroup: [], error: {}, loading: false },
  jobFamily: { family: [], error: {}, loading: false },
  meta: {
    limit: 10,
    page: 1,
    count: null,
    totalPages: null,
  },
  allList: { list: [], error: {}, loading: false },
};

const JobArchitectureSlice = createSlice({
  name: "jobArchitectures",
  initialState,
  reducers: {
    pushJobProfile(state, action) {
      state.jobArchitectures.push(action.payload);
    },
    pushJobFamilyGroup(state, action) {
      state.jobFamilyGroup.familyGroup.push(action.payload);
    },
    pushJobFamily(state, action) {
      state.jobFamily.family.push(action.payload);
    },
    updateJobProfile(state, action) {
      state.jobArchitectures = state?.jobArchitectures?.map((item) =>
        item?.profileId == action?.payload?.profileId
          ? { ...item, ...action?.payload }
          : item
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobArchitectures.pending, (state, action) => {
      state.jobArchitectures = [];
      state.loading = true;
    });
    builder.addCase(getJobArchitectures.fulfilled, (state, action) => {
      state.jobArchitectures = action.payload?.data;
      state.loading = false;
      state.meta = action.payload?.meta;
    });
    builder.addCase(getJobArchitectures.rejected, (state, action) => {
      state.error = action?.error?.message || null;
      state.loading = false;
    });
    builder.addCase(getJobFamilyGroup.pending, (state, action) => {
      state.jobFamilyGroup.familyGroup = [];
      state.jobFamilyGroup.loading = true;
    });
    builder.addCase(getJobFamilyGroup.fulfilled, (state, action) => {
      state.jobFamilyGroup.familyGroup = action.payload;
      state.jobFamilyGroup.loading = false;
    });
    builder.addCase(getJobFamilyGroup.rejected, (state, action) => {
      state.jobFamilyGroup.error = action?.error?.message || null;
      state.jobFamilyGroup.loading = false;
    });
    builder.addCase(getJobFamily.pending, (state, action) => {
      state.jobFamily.family = [];
      state.jobFamily.loading = true;
    });
    builder.addCase(getJobFamily.fulfilled, (state, action) => {
      state.jobFamily.family = action.payload;
      state.jobFamily.loading = false;
    });
    builder.addCase(getJobFamily.rejected, (state, action) => {
      state.jobFamily.error = action?.error?.message || null;
      state.jobFamily.loading = false;
    });
    builder.addCase(getJobArchitectureById.pending, (state, action) => {
      state.jobArchitectureById.jobArchitecture = [];
      state.jobArchitectureById.loading = true;
    });
    builder.addCase(getJobArchitectureById.fulfilled, (state, action) => {
      state.jobArchitectureById.jobArchitecture = action.payload;
      state.jobArchitectureById.loading = false;
    });
    builder.addCase(getJobArchitectureById.rejected, (state, action) => {
      state.jobArchitectureById.error = action?.error?.message || null;
      state.jobArchitectureById.loading = false;
    });
    builder.addCase(getAllJobArchitectures.pending, (state, action) => {
      state.allList.list = [];
      state.allList.loading = true;
    });
    builder.addCase(getAllJobArchitectures.fulfilled, (state, action) => {
      state.allList.list = action.payload;
      state.allList.loading = false;
    });
    builder.addCase(getAllJobArchitectures.rejected, (state, action) => {
      state.allList.error = action?.error?.message || null;
      state.allList.loading = false;
    });
  },
});

export const {
  pushJobFamilyGroup,
  pushJobFamily,
  pushJobProfile,
  updateJobProfile,
} = JobArchitectureSlice.actions;

export default JobArchitectureSlice.reducer;
