import { useEffect, useState } from "react";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Input,
  Button,
} from "reactstrap";
import { ReactSortable } from "react-sortablejs";
const ColumnVisibility = ({ columns, toggleColumnVisibility }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns?.map((column) => ({
      ...column,
    }))
  );

  useEffect(() => {
    toggleColumnVisibility(visibleColumns);
    const isFilterActive = visibleColumns?.some((column) => !column?.visible);
    const count = visibleColumns?.filter((column) => !column?.visible).length;
    setActiveFilterCount(count);
    setIsFilterActive(isFilterActive);
  }, [visibleColumns, toggleColumnVisibility]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleToggle = (accessor) => {
    const updatedColumns = visibleColumns?.map((column) =>
      column.accessor === accessor
        ? { ...column, visible: !column.visible }
        : column
    );
    setVisibleColumns(updatedColumns);
  };

  const showAllColumns = () => {
    const allVisibleColumns = columns?.map((column) => ({
      ...column,
      visible: true,
    }));
    setVisibleColumns(allVisibleColumns);
    toggleColumnVisibility(allVisibleColumns);
    setShowDropdown(showDropdown);
    setIsFilterActive(false);
  };

  const hideAllColumns = () => {
    const allHiddenColumns = columns?.map((column) => ({
      ...column,
      visible: false,
    }));
    setVisibleColumns(allHiddenColumns);
    toggleColumnVisibility(allHiddenColumns);
    setShowDropdown(showDropdown);
    setIsFilterActive(true);
  };

  const handleLabelClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleButtonClick = (event, type) => {
    handleLabelClick(event);
    if (type == "show-all") {
      showAllColumns();
    } else {
      hideAllColumns();
    }
    toggleDropdown();
  };

  return (
    <UncontrolledDropdown
      className={"ms-2 column-visibility "}
      isOpen={showDropdown}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        tag="div"
        className={`filter-icon ${showDropdown ? "active" : ""}`}
        onClick={() => setShowDropdown((prevState) => !prevState)}
        role="button"
      >
        <div className="icon-container text-nowrap d-flex align-items-center">
          {/* <i className="bx bx-menu bx-rotate-90 fs-3"></i> */}
          <span className="hidden_columns">
            <span className="fw-bold me-1">{activeFilterCount}</span> hidden
            columns
          </span>
          {/* {isFilterActive && <div className="active bg-danger d-none"></div>} */}
        </div>
      </DropdownToggle>
      {showDropdown && (
        <DropdownMenu className="ms-0">
          <ReactSortable
            filter=".addImageButtonContainer"
            dragClass="sortableDrag"
            list={visibleColumns || []}
            setList={setVisibleColumns}
            animation="200"
            easing="ease-out"
          >
            {visibleColumns.map((column) => (
              <DropdownItem key={column.accessor}>
                <div
                  className="form-check d-flex align-items-center"
                  onClick={(event) => {
                    handleLabelClick(event);
                    handleToggle(column.accessor);
                  }}
                >
                  <Input
                    type="checkbox"
                    className="form-check-input "
                    checked={column.visible}
                    onChange={() => handleToggle(column.accessor)}
                    id={`toggle-${column.accessor}`}
                  />
                  <label
                    className="form-check-label ms-2"
                    htmlFor={`toggle-${column.accessor}`}
                  >
                    {column.Header}
                  </label>
                </div>
              </DropdownItem>
            ))}
          </ReactSortable>
          <hr className="border border-dashed border-end-0 border-top-0 border-start-0  border-dark m-1" />
          <div className="text-center pt-2 pb-1">
            <button
              onClick={(event) => {
                handleButtonClick(event, "show-all");
              }}
              className="w-75 btn-link me-2 btn  btn-sm fs-6 badge badge-soft-secondary"
            >
              Show All
            </button>
          </div>
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};
export default ColumnVisibility;
