import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { handleDownloadPDF } from "./htmlToPdf";
import { downloadAsWordDoc } from "../Exports/ExportDoc";

const DownloadView = ({ fileName, id, isOpen, JsonData, title, isWordDoc }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="col-sm-auto ms-auto me-2">
      <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          className=" btn btn-sm px-2 btn-soft-light "
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="d-flex align-items-center">
            <i class="bx  bxs-download me-1 fs-6 text-dark"></i>
            <h6 className="btn-text  mb-0 ">Download</h6>
          </div>
        </DropdownToggle>
        {dropdownOpen && (
          <DropdownMenu className="mt-3">
            <li className="px-0">
              <DropdownItem>
                <div
                  role="button"
                  onClick={() => {
                    handleDownloadPDF(id, isOpen, fileName, title);
                  }}
                  className={` d-flex px-0 mx-2 align-items-center`}
                >
                  <i className="bx bxs-file align-bottom me-2 text-muted fs-4"></i>
                  <span className="">Download PDF</span>
                </div>
              </DropdownItem>
            </li>
            {isWordDoc && (
              <li className="px-0">
                <DropdownItem>
                  {" "}
                  <div
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      downloadAsWordDoc(
                        "Job-Description.docx",
                        JsonData,
                        title
                      );
                    }}
                    className={` d-flex px-0  mx-2 align-items-center`}
                  >
                    <i className="bx bxs-file align-bottom me-2 text-muted fs-4 "></i>
                    <span className="">Download DOC</span>
                  </div>
                </DropdownItem>
              </li>
            )}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </div>
  );
};

export default DownloadView;
