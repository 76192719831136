import { createSlice } from "@reduxjs/toolkit";
import {
  getLevelMapping,
  getLevelsByUser,
  getPayzoneById,
  getPayzones,
} from "./thunk";

export const initialState = {
  Payzones: { List: [], error: {}, loading: false },
  PayzoneById: { data: {}, error: {}, loading: false },
};

const PayzoneSlice = createSlice({
  name: "Payzone",
  initialState,
  reducers: {
    pushPayzone(state, action) {
      state.Payzones.List.push(action.payload);
    },
    deletePayzoneById(state, action) {
      state.Payzones.List = state.Payzones.List.filter(
        (item) => item.payZoneId !== action.payload
      );
    },
    updatePayzone(state, action) {
      state.Payzones.List = state?.Payzones.List?.map((item) =>
        item?.payZoneId == action?.payload?.payZoneId
          ? { ...item, ...action?.payload }
          : item
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPayzones.pending, (state, action) => {
      state.Payzones.List = [];
      state.Payzones.loading = true;
    });
    builder.addCase(getPayzones.fulfilled, (state, action) => {
      state.Payzones.List = action.payload;
      state.Payzones.loading = false;
    });
    builder.addCase(getPayzones.rejected, (state, action) => {
      state.Payzones.error = action?.error?.message || null;
      state.Payzones.loading = false;
    });
    builder.addCase(getPayzoneById.pending, (state, action) => {
      state.PayzoneById.data = [];
      state.PayzoneById.loading = true;
    });
    builder.addCase(getPayzoneById.fulfilled, (state, action) => {
      state.PayzoneById.data = action.payload;
      state.PayzoneById.loading = false;
    });
    builder.addCase(getPayzoneById.rejected, (state, action) => {
      state.PayzoneById.error = action?.error?.message || null;
      state.PayzoneById.loading = false;
    });
  },
});

export const { pushPayzone, deletePayzoneById, updatePayzone } =
  PayzoneSlice.actions;

export default PayzoneSlice.reducer;
