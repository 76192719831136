import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import DownloadView from "../../Components/Custom/Download";
import { IdView } from "../../Components/Custom/IdView";
import Export from "../../Components/Custom/Export";

export const PayZonePriceModal = ({ isOpen, onCloseClick, activeValues }) => {
  const [data, setData] = useState({});
  const [tableInfo, setTableInfo] = useState([]);

  useEffect(() => {
    if (isOpen && Object.entries(activeValues?.payzonePrice)?.length > 0) {
      setData(activeValues?.payzonePrice);
      setTableInfo([
        `Job Family Name Group : ${activeValues?.familyGroup}`,
        `Job Family Name : ${activeValues?.familyName}`,
        `Position Code : ${activeValues?.positionCode}`,
        `Job Title : ${activeValues?.jobTitle}`,
        `Job Level : ${activeValues?.level}`,
      ]);
    }
  }, [isOpen]);

  const columns = [
    {
      Header: "Zone",
      accessor: "zone",
      visible: true,
    },
    {
      Header: "Maximum",
      accessor: "maximum",
      visible: true,
    },
    {
      Header: "MidPoint",
      accessor: "midPoint",
      visible: true,
    },
    {
      Header: "Minimum",
      accessor: "minimum",
      visible: true,
    },
  ];

  const list = Object.keys(data).map((zone) => ({
    zone: zone,
    maximum: data[zone].maximum,
    minimum: data[zone].minimum,
    midPoint: data[zone].midPoint,
  }));

  return (
    <div>
      <Modal
        fade={true}
        isOpen={isOpen}
        toggle={onCloseClick}
        centered={true}
        size="lg"
        modalClassName="flip"
      >
        <ModalHeader
          className="bg-secondary p-3 px-4"
          toggle={() => onCloseClick(!isOpen)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-white d-flex align-items-center">
              Payzone Price
              <IdView id={activeValues?.positionCode} />
            </span>
            <Export
              columns={columns}
              list={list}
              fileName={`Payzone - ${activeValues?.positionCode}`}
              isInfo={true}
              infoList={tableInfo}
            />
          </div>
        </ModalHeader>
        <SimpleBar className="modalbody-scroll">
          <ModalBody className="p-4" id={"modal-body"}>
            <Table className="table ">
              <thead>
                <tr>
                  <th>Zone</th>
                  <th>Maximum</th>
                  <th>Midpoint</th>
                  <th>Minimum</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data)?.map((zone) => (
                  <tr key={zone} className="">
                    <td className="fw-semibold">{zone}</td>
                    <td>{data[zone].maximum}</td>
                    <td>{data[zone].midPoint}</td>
                    <td>{data[zone].minimum}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
        </SimpleBar>
      </Modal>
    </div>
  );
};
export default PayZonePriceModal;
