import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getGeoPayzones } from "../../../slices/thunks";
import TableContainer from "../../../Components/Common/TableContainer";
import AddGeoPayzone from "./Add";
import ActionButton from "../../../Components/Common/ActionButton";
import { deleteGeoPayzone } from "../../../helpers/custom_backend_helper";
import { deleteGeoPayzoneById } from "../../../slices/GeoPayzone/reducer";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { toasterError, toasterSuccess } from "../../../utils/toaster";
import Loader from "../../../Components/Custom/Loader";

export const GeoPayzone = () => {
  const [addModal, setAddModal] = useState(false);
  const [actionType, setActionType] = useState("Add");
  const [activeId, setActiveId] = useState("");
  const storeGeoPayzone = useSelector(
    (State) => State?.GeoPayzone?.GeoPayzones
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGeoPayzones());
  }, []);

  const columns = [
    {
      Header: "S.No",
      accessor: "geoId",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          <h6 className="text-primary fw-semibold mb-0">{cellProps?.value}</h6>
        );
      },
    },
    {
      Header: "Payzone ID",
      accessor: "payZoneId",
      filterable: true,
      visible: true,
      minWidth: "100px",
    },
    {
      Header: "Location ID",
      accessor: "locationId",
      filterable: true,
      visible: true,
      minWidth: "100px",
    },
    {
      Header: "Location",
      accessor: "location",
      filterable: true,
      visible: true,
      minWidth: "100px",
    },
    {
      Header: "Actions",
      accessor: "Actions",
      filterable: false,
      visible: true,
      Cell: (cellProps) => {
        return (
          <div>
            <ul className="list-inline hstack gap-1 mb-0">
              <li className="list-inline-item edit">
                <ActionButton
                  action="edit"
                  onClick={() => {
                    setAddModal(!addModal);
                    setActiveId(cellProps.row.original?.geoId);
                    setActionType("Edit");
                  }}
                />
              </li>
              <li className="list-inline-item">
                <ActionButton
                  action="delete"
                  onClick={() => {
                    setActiveId(cellProps.row.original?.geoId);
                    setShowDeleteModal(!showDeleteModal);
                  }}
                />
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  const handleDelete = () => {
    // setLoading(true);
    deleteGeoPayzone(activeId)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          toasterSuccess("Geo Payzone Deleted Successfully!");
          dispatch(deleteGeoPayzoneById(activeId));
          //   setLoading(false);
          setShowDeleteModal(!showDeleteModal);
        } else {
          toasterError("Unable to delete Geo Payzone");
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
        toasterError("Unable to delete Geo Payzone");
      });
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col md={12} lg={12} xxl={12}>
            <TableContainer
              loading={false}
              columns={columns}
              data={storeGeoPayzone?.List || []}
              isGlobalFilter={true}
              columnFilter={true}
              isAddUserList={false}
              customPageSize={10}
              showPagination={true}
              isDefaultPagination={true}
              divClass="table-responsive table-card mb-0 p-3"
              tableClass="align-middle table-wrap"
              theadClass="table-light"
              SearchPlaceholder="Search for..."
              addAction={() => {}}
              buttonAction={() => {
                setActiveId(null);
                setAddModal(!addModal);
                setActionType("Add");
              }}
              buttonText={"Add Geo Payzone"}
              showButton={true}
              exportButton={false}
              downloadFileName={"Geo-Payzone"}
              importButton={false}
              pageTitle={"Geo Payzone"}
              isPageHeader={true}
            />
          </Col>
        </Row>
      </div>
      <AddGeoPayzone
        isOpen={addModal}
        onCloseClick={() => setAddModal(false)}
        id={activeId}
        actionType={actionType}
      />
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={setShowDeleteModal}
        onDeleteClick={handleDelete}
        activeDeleteId={activeId}
        // loading={loading}
      />
      {storeGeoPayzone?.loading && <Loader />}
    </React.Fragment>
  );
};

export default GeoPayzone;
