const getScreenSize = () => {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;
      if (width >= 1270) {
        return "desktop";
      } else if (width >= 768 && width < 1270) {
        return "tablet";
      } else {
        return "mobile";
      }
    }
    return "";
  };
  
  export { getScreenSize };
  