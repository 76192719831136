import React, { useMemo } from "react";
import * as XLSX from "xlsx";
import { UTCToLocalDateTime } from "../../utils/dataFormat";

const TemplateExport = React.memo(
    ({ className = "", header }) => {

        const filteredData = [];

        const calculateColumnWidths = (header, filteredData) =>
            header?.map((headerItem) => ({
                wch:
                    Math.max(
                        headerItem.header?.length,
                        ...filteredData?.map(
                            (dataItem) => String(dataItem[headerItem.dataKey]).length
                        )
                    ) + 2,
            }));

        const exportToExcel = () => {
            const headerRow = header?.map((item) => item.header);
            const ws = XLSX.utils.aoa_to_sheet([headerRow]);

            XLSX?.utils?.sheet_add_json(ws, filteredData, {
                skipHeader: true,
                origin: "A2",
            });

            ws["!cols"] = calculateColumnWidths(header, filteredData);
            ws.s = { fill: { fgColor: "#1c293b" }, font: { color: "red" } };

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
            XLSX.writeFile(wb, `Template.xlsx`);
        };

        return (
            <div
                onClick={() => exportToExcel()}
                className={`${className} d-flex px-0`}
            >
                <i className="bx bxs-file align-bottom me-2 text-muted fs-4"></i>
                <span className="">Export Template</span>
            </div>
        );
    }
);

export default TemplateExport;
