import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  addEdge,
  Background,
  MiniMap,
} from "reactflow";
import "reactflow/dist/style.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Button, Card, CardBody } from "reactstrap";
import ButtonEdge from "../../Components/Custom/ReactFlow/ButtonEdge";
import { useDispatch, useSelector } from "react-redux";
import {
  getInitialEdges,
  getInitialNodes,
} from "../../Components/Custom/ReactFlow/Data";
import { getLevelsByUser } from "../../slices/levels/thunk";
import { getUserData } from "../../utils/auth";
import {
  getDefaulLeveltMapping,
  getGeneralLevelsByUser,
  getLevelMappingByUser,
} from "../../slices/thunks";
import { postLevelMapping } from "../../helpers/custom_backend_helper";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import Loader from "../../Components/Custom/Loader";
import PageHeader from "../../Components/Common/Header";

const nodeColor = (node) => {
  switch (node?.type) {
    case "input":
      return "#e6eef5";
    case "output":
      return "#feeed2";
    default:
      return "#eeeeee";
  }
};

// const edgeTypes = {
//     buttonedge: ButtonEdge,
// };

const LevelMappingFlowChart = () => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const storeExternalLevels = useSelector((state) => state.Levels);
  const storeInternalLevels = useSelector((state) => state?.GeneralLevels);
  const storeLevelMapping = useSelector((state) => state?.LevelMapping);
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isDefault, setIsDefault] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getLevelsByUser(userData?.userId));
    dispatch(getGeneralLevelsByUser());
    dispatch(getLevelMappingByUser(userData?.userId));
    // dispatch(
    //     getDefaulLeveltMapping({
    //         userId: userData?.userId,
    //     })
    // );
  }, []);

  const edgeTypes = {
    buttonedge: (props) => <ButtonEdge {...props} isEdit={isEdit} />,
  };

  useEffect(() => {
    if (
      storeInternalLevels?.levelsByUser?.levels?.length > 0 &&
      storeExternalLevels?.levelsByUser?.levels?.length > 0
    ) {
      const newInitialNodes = getInitialNodes(
        storeInternalLevels.levelsByUser.levels,
        storeExternalLevels.levelsByUser.levels
      );
      setInitialNodes(newInitialNodes);
      setNodes(newInitialNodes);
    }
  }, [storeInternalLevels, storeExternalLevels]);

  useEffect(() => {
    if (storeLevelMapping?.mappingByUser?.mapping?.length > 0) {
      const newInitialEdges = isDefault
        ? getInitialEdges(storeLevelMapping?.defaultMapping?.mapping)
        : getInitialEdges(storeLevelMapping?.mappingByUser?.mapping);
      setInitialEdges(newInitialEdges);
      setEdges(newInitialEdges);
    }
  }, [storeLevelMapping]);

  const onConnect = useCallback(
    (params) => {
      const { source, target, type } = params;
      /* VALIDATION - one edge */
      // const isOutputNode = nodes.find(
      //     (node) => node.id === target && node.type === "output"
      // );

      // if (isOutputNode) {
      //     const existingEdge = edges.find((edge) => edge.target === target);

      //     if (existingEdge) {
      //         return;
      //     }
      // }

      let newType = type || "buttonedge";
      setEdges((els) => addEdge({ ...params, type: newType }, els));
    },
    [nodes, edges, setEdges]
  );

  const handleSubmit = () => {
    let body = {};
    let levelMapping = [];
    edges?.length > 0 &&
      edges?.forEach((ele) => {
        let internalCode = ele?.source.split("I-").pop();
        let externalCode = ele?.target.split("E-").pop();
        let obj = {
          externalLevel: externalCode,
          internalLevel: internalCode,
        };
        levelMapping?.push(obj);
      });
    body["levelMapping"] = levelMapping;
    body["userId"] = userData?.userId;
    setLoading(true);
    postLevelMapping(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          toasterSuccess("Level Mapping updated Successfully");
          dispatch(getLevelMappingByUser(userData?.userId));
          setLoading(false);
          setIsEdit(false);
        } else {
          toasterError("Unable to Update Level Mapping");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <BreadCrumb
          title="Level Mapping"
          pageLink="/level-mapping-flow-chart"
        /> */}
        <PageHeader
          pageTitle={"Level Mapping"}
          isAddBtn={false}
          isImportBtn={false}
        />
        <Card className="level-chart  pb-4 shadow-none">
          <CardBody className="">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm me-1 btn-soft-info"
                  onClick={() => {
                    setIsDefault(true);
                    dispatch(
                      getDefaulLeveltMapping({
                        userId: userData?.userId,
                      })
                    );
                  }}
                >
                  Set Default Level Mapping
                </button>

                <button
                  className="btn btn-sm btn-soft-success d-flex align-items-center"
                  onClick={() => setIsEdit(true)}
                >
                  <i className="bx bxs-edit me-1"></i>
                  <p className="mb-0">Edit</p>
                </button>
              </div>
              {(edges?.filter((ele) => ele?.id?.includes("reactflow__edge"))
                ?.length > 0 ||
                initialEdges?.length !== edges?.length ||
                isEdit) && (
                <div className="">
                  <Button
                    className="btn theme-cancel-btn btn-sm px-3 me-1"
                    onClick={() => {
                      setEdges(initialEdges);
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-sm btn-primary px-3"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <div className="mt-3 position-absolute">
              <div className="d-flex align-items-center mb-2 ">
                {" "}
                <diV className="p-2 bg-primary me-2"></diV>
                <p className="mb-0">Internal Levels</p>
              </div>
              <div className="d-flex align-items-center ">
                {" "}
                <diV className="p-2 bg-secondary me-2 "></diV>
                <p className="mb-0">External Levels</p>
              </div>
            </div>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              edgeTypes={edgeTypes}
              snapToGrid
              onConnect={onConnect}
              fitView
              minZoom={0}
              attributionPosition="bottom-right"
              snapGrid={[15, 15]}
              nodesDraggable={isEdit ? true : false}
              nodesConnectable={isEdit ? true : false}
            >
              <Controls />
              <MiniMap nodeColor={nodeColor} zoomable pannable />
              <Background gap={25} />
            </ReactFlow>
          </CardBody>
        </Card>
        {(storeLevelMapping?.defaultMapping?.loading ||
          storeLevelMapping?.mappingByUser?.loading ||
          storeInternalLevels?.levelsByUser?.loading ||
          storeExternalLevels?.levelsByUser?.loading ||
          loading) && <Loader />}
      </div>
    </React.Fragment>
  );
};

export default LevelMappingFlowChart;
