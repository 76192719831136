import React, { useEffect, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBenchmarking, getMarketPricing } from "../../slices/thunks";
import { getUserData } from "../../utils/auth";
import ApplyBenchMarking from "./Apply";
import Loader from "../../Components/Custom/Loader";
import { useNavigate } from "react-router-dom";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import starImg from "../../assets/images/star.png";
import PageHeader from "../../Components/Common/Header";
import { formatSalary } from "../../utils/dataFormat";
import PaginationContainer from "../../Components/Custom/PaginationContainer";
import useUpdateRouteWithQueryParams from "../../Components/Hooks/queryParams";
import { applyBenchmarking } from "../../helpers/custom_backend_helper";
import BenchmarkingFilters from "./Filters";
import DisplayFilters from "../../Components/Common/DisplayFilters";
import Export from "../../Components/Custom/Export";

export const Benchmarking = () => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const queryParams = parseQueryParams();
  const updateRouteWithParams = useUpdateRouteWithQueryParams();
  const storeBenchmarking = useSelector((state) => state?.Benchmarking);
  const storeMarketPricing = useSelector(
    (state) => state?.MarketPricing?.marketPricing
  );
  const [pageIndex, setPageIndex] = useState();
  const [activeValues, setActiveValues] = useState();
  const [percentile, setPercentile] = useState("50");
  const [initialPercentile, setInitialPercentile] = useState("50");
  const [selectedBenchmarks, setSelectedBenchmarks] = useState([]);
  const [allBenchmarkCodes, setAllBenchmarkCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllRows, setSelectedAllRows] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const [tableInfo, setTableInfo] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [storeBenchmarksList, setStoreBenchmarksList] = useState(
    storeBenchmarking?.benchmarking
  );

  useEffect(() => {
    if (percentile !== initialPercentile) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [percentile, initialPercentile]);

  const filterAction = () => {
    setFilterModal(!filterModal);
  };
  const toggleFilterModal = () => setFilterModal(!filterModal);

  const columns = [
    {
      accessor: "jobCode",
      Header: "Job Code",
      visible: true,
    },
    {
      accessor: "jobTitle",
      Header: "Job Title",
      visible: true,
    },
    {
      accessor: "level",
      Header: "Job Level",
      visible: true,
    },
    {
      accessor: "jobDescription",
      Header: "Job Description",
      visible: true,
    },
  ];

  const downloadColumns = [
    {
      Header: "Survey Job Family Group",
      accessor: "surveyJobFamilyGroup",
      visible: true,
    },
    { Header: "Survey Job Family", accessor: "surveyJobFamily", visible: true },
    { Header: "Survey Job Title", accessor: "surveyJobTitle", visible: true },
    { Header: "Survey Job Code", accessor: "surveyJobCode", visible: true },
    { Header: "Survey Job Level", accessor: "surveyJobLevel", visible: true },
    { Header: "Survey Data Cut", accessor: "surveyDataCut", visible: true },
    {
      Header: "Survey Provider Name",
      accessor: "surveyProviderName",
      visible: true,
    },
    {
      Header: "SurveyJobDescription",
      accessor: "surveyJobDescription",
      visible: true,
    },
  ];

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      let query = getQueryString(queryParams);
      let body = `${userData?.userId}${query}`;
      dispatch(getBenchmarking(body));
      setPageIndex(queryParams?.page);
    } else {
      dispatch(getBenchmarking(userData?.userId));
    }
  }, []);

  useEffect(() => {
    setStoreBenchmarksList(storeBenchmarking?.benchmarking);
  }, [storeBenchmarking]);

  const handlePageClick = (value) => {
    if (value) {
      setPageIndex(value);
      let query = { page: value, limit: 10 };
      let body = `${userData?.userId}?page=${value}&limit=10`;
      updateRouteWithParams(query);
      dispatch(getBenchmarking(body));
    }
  };

  const handleSelectBenchmarks = (jobCode) => {
    setSelectedBenchmarks((prev) => {
      if (prev?.includes(jobCode)) {
        return selectedBenchmarks?.filter((ele) => ele != jobCode);
      } else {
        return [...prev, jobCode];
      }
    });
  };

  const handleDeleteBenchmarks = (jdId) => {
    let benchmarkCode = allBenchmarkCodes.filter(
      (item) => !selectedBenchmarks.includes(item)
    );
    let body = {
      jobDescriptionId: jdId,
      jobCode: benchmarkCode,
      allLevels: false,
      type: "delete"
    };
    handleApplyBenchmarks(body,benchmarkCode)
  };

  const handleApplyBenchmarks = (body, benchmarkCode) => {
    setLoading(true);
    applyBenchmarking(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          dispatch(getBenchmarking(userData?.userId));
          dispatch(
            getMarketPricing({
              jobCode: benchmarkCode,
            })
          );
          setLoading(false);
          setSelectedBenchmarks([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {});
  };

  const toggleRowSelection = (row) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows?.includes(row)) {
        return prevSelectedRows.filter((id) => id !== row);
      } else {
        return [...prevSelectedRows, row];
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content benchmarking-styles ">
        <div>
          <PageHeader
            pageTitle={"Benchmarking"}
            isAddBtn={false}
            isImportBtn={false}
            exportButton={true}
            selectAllRows={selectAllRows}
            data={storeBenchmarksList}
            visibleColumns={columns}
            rowSelectionID={"jdID"}
            selectedRows={selectedRows}
            isFilterBtn={true}
            filterAction={filterAction}
            downloadFileName={"benchmarking"}
          />
        </div>
        <Row className="mx-1 mb-2">
          <Col className="d-flex flex-wrap mt-2 justify-content-end px-2 align-items-center">
            <DisplayFilters
              dispatchTableData={getBenchmarking}
              isBorderColor={true}
              isUserID={true}
            />
          </Col>
        </Row>
        <div className="mb-5">
          <Row className="">
            <Col md={12} lg={12} xxl={12}>
              <Card className="header-container p-0  bg-white shadow-none mb-1 ">
                <CardBody className="px-2 py-1">
                  <UncontrolledAccordion defaultOpen={[]} stayOpen className="">
                    <AccordionItem className="border border-0 bg-white ">
                      <AccordionHeader className="d-flex  border border-0">
                        <div className="px-0 me-3">
                          <Input
                            type="checkbox"
                            className="form-check-input "
                            checked={
                              storeBenchmarksList?.length > 0 &&
                              selectedRows?.length ===
                                storeBenchmarksList?.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                const allRowIds = [];
                                storeBenchmarksList?.map((ele) => {
                                  allRowIds?.push(ele?.jdID);
                                });
                                setSelectedRows(allRowIds);
                                setSelectedAllRows(true);
                              } else {
                                setSelectedRows([]);
                                setSelectedAllRows(false);
                              }
                            }}
                          />
                        </div>
                        <div className="job-code px-0 me-3 ">
                          <p className="fw-semibold mb-0">Job Code</p>
                        </div>
                        <div className="job-title px-0 me-3 ">
                          <p className="fw-semibold mb-0">Job Title</p>
                        </div>
                        <div className="job-level px-0 me-3">
                          <p className="fw-semibold mb-0">Job Level</p>
                        </div>
                        <div className="job-description px-0 me-5">
                          <p className="fw-semibold mb-0">Job Description</p>
                        </div>
                        <div
                          className="accordion-arrow"
                          style={{ width: "3.5%" }}
                        ></div>
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </CardBody>
              </Card>
              <UncontrolledAccordion className="data-container">
                {storeBenchmarksList?.length > 0 &&
                  storeBenchmarksList?.map((ele, ind) => {
                    let benchmarkCode = [];
                    ele["benchmark_job_code"]?.map((i) =>
                      benchmarkCode?.push(i?.surveyJobCode)
                    );
                    let applyBenchmarkBody = {
                      allLevels: false,
                      jobDescriptionId: ele?.jdID,
                      jobCode: benchmarkCode,
                    };
                    return (
                      <Card
                        className="list-container p-0 rounded-0 shadow-none mb-1"
                        key={ind}
                      >
                        <CardBody className="p-1 px-2 py-1">
                          <AccordionItem className="border border-0">
                            <AccordionHeader
                              targetId={ele?.profileId}
                              className="d-flex border border-0"
                              onClick={() => {
                                setPercentile(ele?.percentile)
                                setInitialPercentile(ele?.percentile)
                                setAllBenchmarkCodes(benchmarkCode);
                                dispatch(
                                  getMarketPricing({
                                    jobCode: benchmarkCode,
                                  })
                                );
                              }}
                            >
                              <div className="px-0 me-3">
                                <Input
                                  type="checkbox"
                                  className="form-check-input "
                                  onChange={() => toggleRowSelection(ele?.jdID)}
                                  checked={selectedRows?.includes(ele?.jdID)}
                                />
                              </div>
                              <div className="px-0 me-3 job-code">
                                <p className="fw-normal mb-0">{ele?.jobCode}</p>
                              </div>
                              <div className="px-0 me-3 job-title">
                                <p className="fw-normal mb-0">
                                  {ele?.jobTitle}
                                </p>
                              </div>
                              <div className="px-0 me-3 job-level">
                                <p className="fw-normal mb-0">{ele?.level}</p>
                              </div>
                              <div className="px-0 me-5 job-description">
                                <p className="fw-normal mb-0">
                                  {ele?.jobDescription}
                                </p>
                              </div>
                            </AccordionHeader>
                            <AccordionBody
                              accordionId={ele?.profileId}
                              className={`bg-white collapse transition-all duration-300`}
                              onClick={() => {
                                setTableInfo([
                                  `Position Code : ${ele?.jobCode}`,
                                  `Job Title : ${ele?.jobTitle}`,
                                  `Job Level : ${ele?.level}`,
                                ]);
                              }}
                            >
                              {ele["benchmark_job_code"]?.length > 0 ? (
                                <>
                                  <div className="d-flex justify-content-end mb-2 px-2 ">
                                    <div
                                      class="btn-group mr-2  btn-group-md rounded-pill border border-1 border-secondary "
                                      role="group"
                                      aria-label="First group"
                                    >
                                      <button
                                        type="button"
                                        class={`btn rounded-pill border border-0 me-1  ${
                                          percentile == "10"
                                            ? " btn-secondary fw-semibold"
                                            : " btn-outline-secondary "
                                        }`}
                                        onClick={() => {
                                          setPercentile("10");
                                        }}
                                      >
                                        10 <sup>th</sup>
                                      </button>
                                      <button
                                        type="button"
                                        class={`btn  rounded-pill border border-0 me-1 ${
                                          percentile == "25"
                                            ? " btn-secondary fw-semibold "
                                            : " btn-outline-secondary "
                                        }`}
                                        onClick={() => {
                                          setPercentile("25");
                                        }}
                                      >
                                        25 <sup>th</sup>
                                      </button>
                                      <button
                                        type="button"
                                        class={`btn  rounded-pill border border-0 me-1 ${
                                          percentile == "50"
                                            ? " btn-secondary fw-semibold "
                                            : " btn-outline-secondary "
                                        }`}
                                        onClick={() => {
                                          setPercentile("50");
                                        }}
                                      >
                                        50 <sup>th</sup>
                                      </button>
                                      <button
                                        type="button"
                                        class={`btn rounded-pill border border-0  me-1 ${
                                          percentile == "75"
                                            ? " btn-secondary fw-semibold "
                                            : " btn-outline-secondary "
                                        }`}
                                        onClick={() => {
                                          setPercentile("75");
                                        }}
                                      >
                                        75 <sup>th</sup>
                                      </button>
                                      <button
                                        type="button"
                                        class={`btn rounded-pill border border-0   ${
                                          percentile == "90"
                                            ? " btn-secondary fw-semibold "
                                            : " btn-outline-secondary "
                                        }`}
                                        onClick={() => {
                                          setPercentile("90");
                                        }}
                                      >
                                        90 <sup>th</sup>
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <Export
                                        columns={downloadColumns}
                                        list={storeMarketPricing?.marketPricing}
                                        fileName={`Benchmarking - ${ele?.jobCode}`}
                                        isInfo={true}
                                        infoList={tableInfo}
                                      />
                                    </div>
                                    {showSaveButton && (
                                      <button
                                        className="btn btn-sm btn-primary px-4 rounded-pill"
                                        onClick={() => {
                                          setInitialPercentile(percentile)
                                          handleApplyBenchmarks(
                                            {
                                              ...applyBenchmarkBody,
                                              percentile: `${percentile}thPercentile`,
                                            },
                                            benchmarkCode
                                          );
                                        }}
                                      >
                                        Save
                                      </button>
                                    )}
                                    {selectedBenchmarks?.length > 0 && (
                                      <div>
                                        <button
                                          className="delete-btn btn btn-md p-2 px-3 ms-2 btn-soft-danger rounded-pill d-flex align-items-center"
                                          onClick={() =>
                                            handleDeleteBenchmarks(ele?.jdID)
                                          }
                                        >
                                          <i class="bx bx-trash text-danger me-1"></i>
                                          <h6 className="text-danger mb-0 fw-normal">
                                            Delete
                                          </h6>
                                        </button>
                                      </div>
                                    )}
                                  </div>

                                  {ele["benchmark_job_code"]?.map((item) => {
                                    return (
                                      <Card className="shadow-none p-0  mb-2">
                                        <CardBody className=" position-relative p-0">
                                          {storeMarketPricing?.marketPricing
                                            ?.length > 0 &&
                                            storeMarketPricing?.marketPricing?.map(
                                              (ele, index) => {
                                                if (ele?._id == item?._id) {
                                                  return (
                                                    <div className="p-0 ">
                                                      <div className="d-flex p-2">
                                                        <Input
                                                          type="checkbox"
                                                          className="form-check-input mx-2 "
                                                          onClick={() => {
                                                            handleSelectBenchmarks(
                                                              ele?.surveyJobCode
                                                            );
                                                          }}
                                                        />
                                                        <h5 className="mb-0 text-secondary fw-bold">
                                                          {index + 1}.{" "}
                                                          {ele?.surveyJobTitle}
                                                        </h5>
                                                      </div>
                                                      <div className="p-">
                                                        <Row className="m-0">
                                                          <Col md={6}>
                                                            <div className="my-3">
                                                              <Badge className="px-3 py-2 bg-light me-2 fw-normal mb-2">
                                                                <p className="mb-0 text-dark fs-6 ">
                                                                  Survey Job
                                                                  Code :{" "}
                                                                  <span className="fw-semibold">
                                                                    {
                                                                      ele?.surveyJobCode
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </Badge>
                                                              <Badge className="px-3 py-2 bg-light me-2 fw-normal mb-2">
                                                                <p className="mb-0 text-dark fs-6">
                                                                  Survey
                                                                  Provider Name
                                                                  :{" "}
                                                                  <span className="fw-semibold">
                                                                    {
                                                                      ele?.surveyProviderName
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </Badge>
                                                              <Badge className="px-3 py-2 bg-light me-2 fw-normal mb-2">
                                                                <p className="mb-0 text-dark fs-6">
                                                                  Survey Data
                                                                  Cut :{" "}
                                                                  <span className="fw-semibold">
                                                                    {
                                                                      ele?.surveyDataCut
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </Badge>
                                                              <Badge className="px-3 py-2 bg-light me-2 fw-normal mb-2">
                                                                <p className="mb-0 text-dark fs-6">
                                                                  Survey Job
                                                                  Level :{" "}
                                                                  <span className="fw-semibold">
                                                                    {
                                                                      ele?.surveyJobLevel
                                                                    }
                                                                  </span>
                                                                </p>
                                                              </Badge>
                                                            </div>
                                                            <div className="mb-3 ">
                                                              <p className="text-muted">
                                                                {
                                                                  ele?.surveyJobDescription
                                                                }
                                                              </p>
                                                            </div>
                                                          </Col>

                                                          <Col md={6}>
                                                            <Row className="my-2">
                                                              <Col md={6}>
                                                                <div className="d-flex align-items-center border border-1 p-2 rounded my-2">
                                                                  <div className="me-3">
                                                                    <i class="bx bx-money bg-soft-primary text-primary p-2 rounded fs-3"></i>
                                                                  </div>
                                                                  <div>
                                                                    <p className="text-muted mb-2">
                                                                      Base
                                                                    </p>
                                                                    <h5 className="fw-semibold">
                                                                      ${" "}
                                                                      {formatSalary(
                                                                        ele[
                                                                          `${percentile}thPercentile`
                                                                        ][
                                                                          `totalDirectCompTarget`
                                                                        ]
                                                                      )}
                                                                    </h5>
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                              <Col md={6}>
                                                                <div className="d-flex align-items-center border border-1 p-2 rounded my-2">
                                                                  <div className="me-3">
                                                                    <i class="bx bx-trending-up bg-soft-primary text-primary p-2 rounded fs-3"></i>
                                                                  </div>
                                                                  <div>
                                                                    <p className="text-muted mb-2">
                                                                      Incentives
                                                                    </p>
                                                                    <h5 className="fw-semibold">
                                                                      ${" "}
                                                                      {formatSalary(
                                                                        ele[
                                                                          `${percentile}thPercentile`
                                                                        ][
                                                                          `targetIncentivesAmount`
                                                                        ]
                                                                      )}
                                                                    </h5>
                                                                  </div>
                                                                </div>
                                                              </Col>{" "}
                                                              <Col md={6}>
                                                                <div className="d-flex align-items-center border border-1 p-2 rounded my-2">
                                                                  <div className="me-3">
                                                                    <i class="ri-percent-line bg-soft-primary text-primary p-2 rounded fs-3"></i>
                                                                  </div>
                                                                  <div>
                                                                    <p className="text-muted mb-2">
                                                                      Incentives
                                                                      Percentage
                                                                    </p>
                                                                    <h5 className="fw-semibold">
                                                                      {
                                                                        ele[
                                                                          `${percentile}thPercentile`
                                                                        ][
                                                                          `targetIncentivesPercent`
                                                                        ]
                                                                      }{" "}
                                                                      %
                                                                    </h5>
                                                                  </div>
                                                                </div>
                                                              </Col>{" "}
                                                              <Col md={6}>
                                                                <div className="d-flex align-items-center border border-1 p-2 rounded border-secondary my-2">
                                                                  <div className="me-3">
                                                                    <i class="bx bx-dollar bg-soft-secondary text-secondary p-2 rounded fs-3"></i>
                                                                  </div>
                                                                  <div>
                                                                    <p className="text-secondary mb-2">
                                                                      Total
                                                                    </p>
                                                                    <h5 className="fw-semibold text-secondary">
                                                                      ${" "}
                                                                      {formatSalary(
                                                                        ele[
                                                                          `${percentile}thPercentile`
                                                                        ][
                                                                          `totalDirectCompTarget`
                                                                        ]
                                                                      )}
                                                                    </h5>
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                        </CardBody>
                                      </Card>
                                    );
                                  })}

                                  <div className="d-flex justify-content-center text-center mt-3 ">
                                    <Button
                                      className="btn btn-sm ai-btn rounded-pill p-2 px-4 d-flex align-items-center justify-content-center"
                                      onClick={() => {
                                        setModal(!modal);
                                        setActiveValues(ele);
                                      }}
                                    >
                                      <img src={starImg} className="me-2" />
                                      <h6 className="mb-0 text-white">
                                        {" "}
                                        Get more benchmarks
                                      </h6>
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex justify-content-center text-center mt-3 ">
                                  <Button
                                    className="btn btn-sm ai-btn rounded-pill p-2 px-4 d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      setModal(!modal);
                                      setActiveValues(ele);
                                    }}
                                  >
                                    <img src={starImg} className="me-2" />
                                    <h6 className="mb-0 text-white">
                                      {" "}
                                      Get Bencharking
                                    </h6>
                                  </Button>
                                </div>
                              )}
                            </AccordionBody>
                          </AccordionItem>
                        </CardBody>
                      </Card>
                    );
                  })}{" "}
                {!storeBenchmarking?.loading &&
                  storeBenchmarksList?.length == 0 && (
                    <div className="text-center my-5">
                      <h4>No Data Found</h4>
                    </div>
                  )}
              </UncontrolledAccordion>
            </Col>
          </Row>
          <Row>
            <Col className="py-3">
              {" "}
              <PaginationContainer
                pageIndex={pageIndex}
                goTo={handlePageClick}
                totalPages={storeBenchmarking?.meta?.totalPages}
                totalRecords={storeBenchmarking?.meta?.count}
                pageRecords={storeBenchmarksList?.length}
              />
            </Col>
          </Row>
        </div>
      </div>
      {(storeBenchmarking?.loading || loading) && <Loader />}
      <ApplyBenchMarking
        isOpen={modal}
        onCloseClick={() => setModal(false)}
        activeValues={activeValues}
      />
      <BenchmarkingFilters
        show={filterModal}
        onCloseClick={toggleFilterModal}
      />
    </React.Fragment>
  );
};
export default Benchmarking;
