import { createSlice } from "@reduxjs/toolkit";
import { getEmployeeById, getEmployees, getEmployeesAll } from "./thunk";

export const initialState = {
  employees: {
    employees: [],
    error: {},
    loading: false,
    meta: {
      limit: 10,
      page: 1,
      count: null,
      totalPages: null,
    },
    allList:[]
  },
  active: { employee: [], error: {}, loading: false },
};

const EmployeesSlice = createSlice({
  name: "Employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployees.pending, (state, action) => {
      state.employees.employees = [];
      state.employees.loading = true;
    });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.employees.employees = action.payload.data;
      state.employees.loading = false;
      state.employees.meta = action.payload.meta;
    });
    builder.addCase(getEmployees.rejected, (state, action) => {
      state.employees.error = action?.error?.message || null;
      state.employees.loading = false;
    });
    builder.addCase(getEmployeeById.pending, (state, action) => {
      state.active.employee = [];
      state.active.loading = true;
    });
    builder.addCase(getEmployeeById.fulfilled, (state, action) => {
      state.active.employee = action.payload;
      state.active.loading = false;
    });
    builder.addCase(getEmployeeById.rejected, (state, action) => {
      state.active.error = action?.error?.message || null;
      state.active.loading = false;
    });
    builder.addCase(getEmployeesAll.pending, (state, action) => {
      state.employees.allList = [];
    });
    builder.addCase(getEmployeesAll.fulfilled, (state, action) => {
      state.employees.allList = action.payload.data;
    });
    builder.addCase(getEmployeesAll.rejected, (state, action) => {
      state.employees.error = action?.error?.message || null;
    });
  },
});

export const {} = EmployeesSlice.actions;

export default EmployeesSlice.reducer;
