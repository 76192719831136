import React from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { EmploymentTypeOptions } from "../../utils/dataOptions";
import { getUserData } from "../../utils/auth";
import { postCustomInternalLevel } from "../../helpers/custom_backend_helper";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import { useSelector } from "react-redux";
import ColumnLoader from "../../Components/Custom/ColumnLoader";

const defaultValues = {
  levelCode: "",
  levelJobTitle: "",
  level: "",
  jobType: "",
  nextLevel: "",
  sameLevel: "",
};

export const AddCustomLevels = ({ onCloseClick, isOpen, id, actionType }) => {
  const userData = getUserData();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const storeLevelChart = useSelector((state) => state.GeneralLevels);

  const handleSubmit = (values) => {
    let body = {
      ...values,
      jobType: values?.jobType?.label,
      userId: userData?.userId,
    };
    const successMessage = `Custom Level ${
      id ? "Updated" : "Added"
    } Successfully!`;
    const errorMessage = `Unable to ${id ? "update" : "add"} Custom Level !`;
    setLoading(true);
    postCustomInternalLevel(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          toasterSuccess(successMessage);
          setLoading(false);
        } else {
          toasterError(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        onCloseClick();
      });
  };
  return (
    <div>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        id="offcanvas-sm"
        toggle={onCloseClick}
        className="border-0"
      >
        <OffcanvasHeader
          className="d-flex align-items-center bg-primary p-3 offcanvas-header-dark"
          toggle={onCloseClick}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-white d-flex align-items-center">
              {id ? (
                <>
                  EDIT - Custom Level{" "}
                  {/* <IdView id={storeJobArchitectureById?.profileId} /> */}
                </>
              ) : (
                "ADD - Custom Level"
              )}
            </span>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className={loading ? "loader-container p-0" : " p-0"}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={validateSchema}
          >
            {({
              errors,
              handleChange,
              values,
              setFieldValue,
              isSubmitting,
              dirty,
              setFieldTouched,
              setFieldError,
            }) => {
              return (
                <>
                  <div className="p-3 mb-4">
                    <Row>
                      <Col md={12} className="mb-4">
                        <Label>Level Code</Label>
                        <Field
                          className="form-control "
                          id="levelCode"
                          name="levelCode"
                          type="text"
                          // value={values?.levelCode}
                        />
                      </Col>{" "}
                      <Col md={12} className="mb-4">
                        <Label>Job Title</Label>
                        <Field
                          className="form-control "
                          id="levelJobTitle"
                          name="levelJobTitle"
                          type="text"
                          // value={values?.levelJobTitle}
                        />
                      </Col>
                      <Col md={12} className="mb-4">
                        <Label>Level</Label>
                        <Field
                          className="form-control "
                          id="level"
                          name="level"
                          type="text"
                          // value={values?.level}
                        />
                      </Col>
                      <Col md={12} className="mb-4">
                        <Label>Job Type</Label>
                        <ReactSelect
                          id="jobType"
                          className=" mb-0"
                          name="jobType"
                          options={EmploymentTypeOptions}
                          value={values?.jobType}
                          onChange={(event) => {
                            setFieldValue("jobType", event);
                          }}
                          // onBlur={() => {
                          //     setFieldTouched("jobType", true);
                          //     setFieldError("jobType", true);
                          // }}
                        />
                        {/* <ErrorMessage
                                                    name="jobType"
                                                    component="div"
                                                    className="error text-danger"
                                                /> */}
                      </Col>
                      <Col md={12} className="mb-4">
                        <Label>Next Level </Label>
                        <Field
                          className="form-control "
                          id="nextLevel"
                          name="nextLevel"
                          type="text"
                          value={values?.nextLevel}
                          // disabled={true}
                        />
                        {/* <ErrorMessage
                                                    name="nextLevel"
                                                    component="div"
                                                    className="error text-danger"
                                                /> */}
                      </Col>
                      <Col md={12} className="mb-4">
                        <Label>Same Level</Label>
                        <Field
                          id="sameLevel"
                          name="sameLevel"
                          type="text"
                          className="form-control"
                          value={values?.sameLevel}
                          // onChange={handleChange}
                          // onBlur={() => {
                          //     setFieldTouched("sameLevel", true);
                          //     setFieldError("sameLevel", true);
                          // }}
                        />
                        {/* <ErrorMessage
                                                    name="sameLevel"
                                                    component="div"
                                                    className="error text-danger"
                                                /> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="sticky-footer bg-light float-end ">
                    <Button
                      className="btn  bg-white"
                      color="white"
                      // onClick={(e) => {
                      //     onCloseClick();
                      // }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn mx-3 "
                      color="primary"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(values);
                      }}
                      // disabled={
                      //     isSubmitting || !isEmpty(errors) || !dirty || loading
                      // }
                    >
                      Save
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
          {loading && <ColumnLoader />}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};
export default AddCustomLevels;
