import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  buildMultiOptions,
  buildOptions,
  getOption,
  getOptionByValue,
  getValues,
} from "../../utils/dataFormat";
import ColumnLoader from "../../Components/Custom/ColumnLoader";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import * as Yup from "yup";
import { getUserData } from "../../utils/auth";
import { postJobFamilyGroup } from "../../helpers/custom_backend_helper";
import { isEmpty } from "lodash";
import { pushJobFamilyGroup } from "../../slices/jobArchitecture/reducer";
import { useDispatch } from "react-redux";

const defaultValues = {
  JobFamilyGroup: "",
};

export const Add = ({ onCloseClick, isOpen }) => {
  const [jobfamilygroup, setJobfamilygroup] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = getUserData();
  const dispatch=useDispatch()

  const validateSchema = Yup.object().shape({
    familyGroup: Yup.mixed().required("Job Family Group Name is required"),
  });

  const handleSubmit = (values) => {
    let body = {
      familyGroup: values?.familyGroup,
      userId: userData?.userId,
    };
    setLoading(true);
    postJobFamilyGroup(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          dispatch(pushJobFamilyGroup(res?.data));
          toasterSuccess("Added Job Family Group Name!");
          onCloseClick();
        } else {
          toasterError("Unable to add Job Family Group Name!");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toasterError("Unable to add Job Family Group Name!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={onCloseClick} centered={true}>
        <ModalHeader
          toggle={onCloseClick}
          className="bg-secondary p-3 px-4 modal-header"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-primary d-flex align-items-center">
              ADD - Job Family Group
            </span>
          </div>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={defaultValues}
          validationSchema={validateSchema}
        >
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <ModalBody>
                  <Row>
                    <Col>
                      <Label>Job Family Group Name</Label>
                      <Input
                        className="form-control"
                        id="familyGroup"
                        name="familyGroup"
                        type="text"
                        placeholder="ex. Engineering"
                        onChange={(event) => {
                          setFieldValue("familyGroup", event.target.value);
                        }}
                        onBlur={() => {
                          setFieldTouched("familyGroup", true);
                          setFieldError("familyGroup", true);
                        }}
                      />
                      <ErrorMessage
                        name="familyGroup"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                  </Row>{" "}
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={onCloseClick}
                    className="btn theme-cancel-btn mx-2 px-4 btn btn-white"
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    className="btn px-4 btn btn-secondary"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default Add;
