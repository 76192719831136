import {
    AlignmentType,
    Document,
    Header,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    TextRun,
} from "docx";

export function createDocument(data) {
    const document = new Document({
        sections: [
            {
                children: [
                    createBackgroundParagraph("Job Description", "#08569a", 160),
                    ...Object.entries(data).flatMap(([key, value]) => {
                        const elements = [];
                        elements.push(createHeading(key));
                        if (Array.isArray(value)) {
                            value.forEach((item) => {
                                elements.push(createParagraph(item));
                            });
                        } else {
                            elements.push(createParagraph(value));
                        }
                        return elements;
                    }),
                ],
            },
        ],
    });

    return document;
}

export function createHeading(text) {
    return new Paragraph({
        children: [
            new TextRun({
                text: text,
                size: 32,
                bold: true,
                underline: true,
                color: "1c293b",
            }),
        ],
        spacing: {
            after: 200,
        },
    });
}

export function createParagraph(text) {
    return new Paragraph({
        children: [
            new TextRun({
                text: text,
                size: 28,
                bold: false,
                underline: false,
            }),
        ],
        spacing: {
            after: 100,
        },
    });
}

export const downloadAsWordDoc = (fileName, JsonData) => {
    try {
        const doc = createDocument(JsonData);
        Packer.toBlob(doc)
            .then((blob) => {
                if (!blob) {
                    console.error("Error generating blob.");
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error generating DOCX:", error);
            });
    } catch (error) {
        console.error("Error:", error);
    }
};

function createBackgroundParagraph(text, backgroundColor, padding) {
    const cell = new TableCell({
        children: [
            new Paragraph({
                children: [
                    new TextRun({
                        text: text,
                        bold: true,
                        underline: false,
                        alignment: AlignmentType.LEFT,
                        color: "#ffffff",
                        size: 38,
                    }),
                ],
            }),
        ],
        shading: { fill: backgroundColor },
        margins: {
            top: padding,
            bottom: padding,
        },
    });

    const row = new TableRow({ children: [cell] });

    const table = new Table({
        width: { size: "100%", type: "auto" },
        rows: [row],
    });

    return table;
}
