//Include Both Helper File with needed methods
import { postLogin } from "../../helpers/custom_backend_helper";
import { encryptUserData } from "../../utils/auth";
import {
  logoutUserSuccess,
  apiError,
  loginRequest,
  loginSuccess,
  loginError,
} from "./reducer";

export const loginUser = (user, history, location) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    let response = await postLogin({
      email: user.email,
      password: user.password,
    });
    if (response && response?.data) {
      if (response?.data?.authentication) {
        // dispatch(login2FEnable());
        dispatch(loginSuccess(response?.data));
        encryptUserData(response?.data);
        history("/dashboard");
      }
    }
  } catch (error) {
    dispatch(apiError("Invalid Email or Password"));
    dispatch(loginError("Invalid Email or Password"));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("user");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};
