import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { getOptionByValue } from "../../utils/dataFormat";

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

export const Creatable = ({
    value: parentValue,
    setValue: setParentValue,
    setInputValue: setParentInputValue,
    id,
    setFieldValue,
    otherValues,
    name,
    onChangeInput,
    ...props
}) => {
    const [inputValue, setInputValue] = useState("");
    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(parentValue);
    }, [parentValue]);

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                setParentValue((prev) => [...prev, createOption(inputValue)]);
                setParentInputValue("");
                setInputValue("");
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => {
                    setValue(newValue);
                    setParentValue(newValue);
                    setFieldValue(id, newValue);
                }}
                onInputChange={(newValue) => {
                    setInputValue(newValue);
                    setParentInputValue(newValue);
                    let allValues = [...parentValue];
                    if (newValue) {
                        allValues.push(getOptionByValue(newValue));
                        setFieldValue(id, allValues);
                    }
                }}
                onKeyDown={handleKeyDown}
                placeholder="Enter Skills and Press Enter..."
                value={value}
                name={name}
                {...props}
            />
        </div>
    );
};
