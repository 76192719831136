// Front
export * from "./layouts/thunk";

// Authentication
// export * from "./auth/login/thunk";
export * from "./auth/register/thunk";
export * from "./auth/forgetpwd/thunk";
export * from "./auth/profile/thunk";

// Login
export * from "./login/thunk";

// Departments
export * from "./departments/thunk";

// Job description
export * from "./jobDescription/thunk";

// Job Architecture
export * from "./jobArchitecture/thunk";

// External Levels
export * from "./levels/thunk";

// Gerneral Levels
export * from "./generalLevels/thunk";

// Level Mapping
export * from "./levelMapping/thunk";

// Market Pricing
export * from "./marketPricing/thunk";

//Benchmarking
export * from "./benchmarking/thunk";

//Ranges
export * from "./Ranges/thunk";

//Incumbent Analysis
export * from "./IncumbentAnalysis/thunk";

// Dashboard
export * from "./dashboard/thunk";

// Survey Data
export * from "./surveyData/thunk";

// Internal Fields
export * from "./InternalFields/thunk";

// Payzone
export * from "./Payzone/thunk";

// Geo-Payzone
export * from "./GeoPayzone/thunk";
