import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColors";

const SimplePie = ({ dataColors,seriesData }) => {
    var chartPieBasicColors = getChartColorsArray(dataColors);
    const series = seriesData
    var options = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: ['Female', 'Male',],
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartPieBasicColors
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="pie"
            height={300}
        />
    )
}
export default SimplePie