import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../../Components/Custom/ReactSelect";
import { buildOptions, getOption } from "../../../utils/dataFormat";
import { isEmpty } from "lodash";
import {
  postGeoPayzone,
  postPayzone,
  putGeoPayzone,
  putPayzone,
} from "../../../helpers/custom_backend_helper";
import { pushPayzone, updatePayzone } from "../../../slices/Payzone/reducer";
import { toasterError, toasterSuccess } from "../../../utils/toaster";
import { IdView } from "../../../Components/Custom/IdView";
import { getGeoPayzoneById, getPayzoneById } from "../../../slices/thunks";
import Payzone from ".";
import {
  pushGeoPayzone,
  updateGeoPayzone,
} from "../../../slices/GeoPayzone/reducer";

const defaultValues = {
  payZoneId: "",
  locationId: "",
  location: "",
};
export const AddGeoPayzone = ({ onCloseClick, isOpen, id }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const dispatch = useDispatch();
  const storeGeoPayzoneById = useSelector(
    (state) => state?.GeoPayzone?.GeoPayzoneById
  );
  const storePayzone = useSelector((state) => state?.Payzone?.Payzones);

  const validateSchema = Yup.object().shape({
    payZoneId: Yup.mixed().required("Payzone is required"),
    locationId: Yup.mixed().required("Geo Diff is required"),
    location: Yup.mixed().required("Location is required"),
  });

  useEffect(() => {
    if (id && isOpen) {
      dispatch(getGeoPayzoneById(id));
    }
  }, [isOpen]);

  useEffect(() => {
    if (storeGeoPayzoneById && id) {
      setInitialValues({
        ...storeGeoPayzoneById?.data,
        ["payZoneId"]: getOption(
          storeGeoPayzoneById?.data?.payZone,
          storeGeoPayzoneById?.data?.payZoneId
        ),
      });
    } else setInitialValues(defaultValues);
  }, [id, storeGeoPayzoneById]);

  const handleSubmit = (values) => {
    let reqBody = {
      payZoneId: values?.payZoneId?.value,
      locationId: values?.locationId,
      location: values?.location,
    };
    let body = id ? { ...reqBody, ["geoId"]: id } : reqBody;
    setLoading(true);
    let request = id ? putGeoPayzone : postGeoPayzone;
    let successMsg = id ? "Updated Geo Payzone!" : "Added Geo Payzone!";
    let errorMsg = id
      ? "Unable to update Geo Payzone!"
      : "Unable to add Geo Payzone!";

    request(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          const action = id
            ? updateGeoPayzone(body)
            : pushGeoPayzone(res?.data);
          dispatch(action);
          toasterSuccess(successMsg);
          onCloseClick();
        } else {
          toasterError(errorMsg);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toasterError(errorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="jobfamily-modal">
      <Modal isOpen={isOpen} toggle={onCloseClick} centered={true}>
        <ModalHeader
          toggle={onCloseClick}
          className="bg-secondary p-3 px-4 modal-header"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-primary d-flex align-items-center">
              {id ? (
                <>
                  EDIT - Geo Payzone <IdView id={id} />
                </>
              ) : (
                "ADD - Geo Payzone"
              )}
            </span>
          </div>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validateSchema}
        >
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <ModalBody>
                  <Row>
                    <Col md={12} className="mb-4">
                      <Label>Payzone</Label>
                      <ReactSelect
                        id="payZoneId"
                        className=" mb-0"
                        name="payZoneId"
                        value={values?.payZoneId}
                        onChange={(event) => {
                          setFieldValue("payZoneId", event);
                        }}
                        options={buildOptions(
                          storePayzone?.List,
                          "payZone",
                          "payZoneId"
                        )}
                        onBlur={() => {
                          setFieldTouched("payZoneId", true);
                          setFieldError("payZoneId", true);
                        }}
                      />
                      <ErrorMessage
                        name="payZoneId"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                    <Col className="col-12 mb-4">
                      <Label for="payZone">Location ID</Label>
                      <Input
                        className="form-control"
                        id="locationId"
                        name="locationId"
                        type="text"
                        placeholder="ex. ZN1"
                        value={values?.locationId}
                        onChange={(event) => {
                          setFieldValue("locationId", event.target.value);
                        }}
                        onBlur={() => {
                          setFieldTouched("locationId", true);
                          setFieldError("locationId", true);
                        }}
                      />
                      <ErrorMessage
                        name="locationId"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                    <Col className="col-12 mb-4">
                      <Label for="location">Location Name</Label>
                      <Input
                        className="form-control"
                        id="location"
                        name="location"
                        type="text"
                        placeholder="ex. 110"
                        value={values?.location}
                        onChange={(event) => {
                          setFieldValue("location", event.target.value);
                        }}
                        onBlur={() => {
                          setFieldTouched("location", true);
                          setFieldError("location", true);
                        }}
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                  </Row>{" "}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={onCloseClick}
                    className="btn theme-cancel-btn mx-2 px-4 btn btn-white"
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    className="btn px-4 btn btn-secondary"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddGeoPayzone;
