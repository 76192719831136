import React from "react";
import { Link, useLocation } from "react-router-dom";

const ActionButton = ({
    to = "",
    action = "",
    onClick = () => { },
    disabled = false,
    removeBtn = false,
}) => {
    const route = useLocation();
    let className = "";
    let iconClass = "";
    switch (action) {
        case "read":
            className = disabled ? " disabled " : "view";
            iconClass = "ri-external-link-fill";
            break;
        case "edit":
            className = disabled ? " disabled " : "edit";
            iconClass = "ri-pencil-fill";
            break;
        case "delete":
            className = disabled ? " disabled " : "delete";
            iconClass = "ri-delete-bin-5-line";
            break;
        default:
            break;
    }

    if (disabled) {
        return (
            <span
                className={
                    `d-flex table-action ${removeBtn ? "d-none" : " "} ` + className
                }
                onClick={disabled ? () => { } : onClick}
            >
                {<i className={`${iconClass} fs-16`}></i>}
            </span>
        );
    }

    return (
        <>
            <div
                className={"d-flex table-action " + className}
                onClick={onClick}
                role="button"
            >
                {<i className={`${iconClass} fs-16 text-primary rounded-circle p-1 px-2 shadow-sm `}></i>}
            </div>
        </>
    );
};

export default ActionButton;
