import React from "react";
const StatusBadge = ({ status, classNames }) => {
    let color;
    let style = classNames ? classNames : "";
    if (status === "False" || status === "false" || status === null) {
        return null;
    }
    if (status === "Accepted" || status === "Completed") {
        color = "success";
    } else if (status === "Declined" || status === "Inactive") {
        color = "danger";
    } else if (status === "Draft") {
        color = "warning";
    } else if (status === "Rejected") {
        color = "primary";
    } else if (status === "Reject") {
        color = "info";
    } else {
        color = "dark";
    }
    if (status) {
        return (
            <div className=" text-start">
                <span
                    className={
                        `badge badge-soft-${color} text-${color} text-uppercase rounded-pill px-2 p-1 ` + style
                    }
                >
                    {status}
                </span>
            </div>
        );
    } else {
        return (
            <div className="text-start ms-4">
                <span className="text-dark ">{"-"}</span>
            </div>
        );
    }
};

export default StatusBadge;
