import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getExternalSurveyDataByID,
  getInternalFields,
} from "../../slices/thunks";
import { isArray } from "lodash";
import {
  buildMultiOptions,
  getOption,
  getOptionByValue,
  getValues,
} from "../../utils/dataFormat";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import validationSchema from "./ValidationSchema";
import {
  PostExternalSurveyData,
  PutExternalSurveyMapper,
} from "../../helpers/custom_backend_helper";
import { IdView } from "../../Components/Custom/IdView";
import SimpleBar from "simplebar-react";
import { getScreenSize } from "../../utils/screensize";

export const MapSurveyData = ({
  show,
  onCloseClick,
  externalColumns,
  formValues,
  activeID,
}) => {
  const dispatch = useDispatch();
  const storeInternalFields = useSelector(
    (State) => State?.InternalFields?.InternalFields
  );
  const [initialValues, setInitialValues] = useState({});
  const storeSurveyDataByID = useSelector(
    (state) => state?.SurveyData?.SurveyDataById
  );
  const [columns, setColumns] = useState([]);
  const [deviceMedia, setDeviceMedia] = useState("");

  const updateMedia = () => {
    setDeviceMedia(getScreenSize());
  };

  useEffect(() => {
    updateMedia();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    dispatch(getInternalFields());
  }, []);

  useEffect(() => {
    if (show && activeID) {
      dispatch(getExternalSurveyDataByID(activeID));
    }
  }, [activeID]);

  useEffect(() => {
    getMapperObject();
  }, [storeInternalFields]);

  useEffect(() => {
    if (activeID && storeSurveyDataByID?.List && show) {
      getMapperObjectById();
      getExternalFieldsById();
    }
  }, [storeSurveyDataByID, show, activeID]);

  const getMapperObject = () => {
    let obj = {};
    storeInternalFields?.Fields?.length > 0 &&
      storeInternalFields?.Fields[0]?.internalFields?.map((field, index) => {
        if (typeof field == "string") {
          return (obj[field] = "");
        } else if (typeof field == "object") {
          Object.entries(field)?.map((item, ind) => {
            let nestedObj = {};
            item[1]?.map((i, index) => {
              return (nestedObj[i] = "");
            });
            return (obj[item[0]] = nestedObj);
          });
        }
        setInitialValues(obj);
      });
  };

  const getMapperObjectById = () => {
    let obj = {};
    if (activeID && storeSurveyDataByID?.List?.mapper) {
      Object.entries(storeSurveyDataByID.List.mapper).forEach(
        ([key, value]) => {
          if (typeof value === "string") {
            obj[key] = value;
          } else if (typeof value === "object" && value !== null) {
            let nestedObj = {};
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              nestedObj[nestedKey] = nestedValue;
            });
            obj[key] = nestedObj;
          }
        }
      );
      setInitialValues(obj);
    }
  };

  const getExternalFieldsById = () => {
    let arr = [];
    let nestedArr = [];
    if (activeID && storeSurveyDataByID?.List?.externalFields) {
      Object.entries(storeSurveyDataByID.List.externalFields).forEach(
        ([key, value]) => {
          if (typeof value === "string") {
            arr.push(value);
          } else if (typeof value === "object" && value !== null) {
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              nestedArr.push(...nestedValue);
            });
          }
        }
      );
      setColumns([...arr, ...nestedArr]);
    }

    return [];
  };

  const handleSubmitMapper = (values) => {
    const formData = new FormData();
    formData.append("providerName", formValues?.providerName);
    formData.append("dataCut", formValues?.dataCut);
    formData.append("file", formValues?.file);
    formData.append("mapper", JSON.stringify(values));
    let request = activeID ? PutExternalSurveyMapper : PostExternalSurveyData;
    let reqBody = {
      ["providerName"]: storeSurveyDataByID?.List?.providerName,
      ["mapper"]: values,
    };
    let body = activeID ? reqBody : formData;
    request(body)
      .then((res) => {
        if (
          res?.metadata?.status === 200 ||
          res?.metadata?.message == "Success"
        ) {
          console.log("SUCCESS");
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
      });
  };

  return (
    <div className="">
      <Modal
        fade={true}
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        className="map_survey_data_modal border border-0"
        size="lg"
        fullscreen={deviceMedia !== "desktop" ? true : false}
        modalClassName="flip"
      >
        <ModalHeader
          className="bg-secondary p-3 "
          toggle={() => onCloseClick(!show)}
        >
          <span className="m-0 me-2 text-primary d-flex align-items-center">
            {activeID ? (
              <>
                Map Survey Data - {storeSurveyDataByID?.List?.providerName}
                <IdView id={activeID} />
              </>
            ) : (
              "Map Survey Data"
            )}
          </span>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <div>
                <SimpleBar className="modalbody-scroll">
                  <ModalBody className="p-3">
                    <Row className="justify-content-center mb-2 align-items-center my-2">
                      <Col md={4} sm={4}>
                        <h5 className="text-uppercase">
                          Internal Survey Levels
                        </h5>
                      </Col>
                      <Col md={7} sm={7}>
                        <h5 className="text-uppercase">
                          External Survey Levels
                        </h5>
                      </Col>
                    </Row>
                    <>
                      {Object.entries(values)?.length > 0 &&
                        Object.entries(values)?.map((ele, index) => {
                          if (
                            ele[0] == "10thPercentile" ||
                            ele[0] == "25thPercentile" ||
                            ele[0] == "50thPercentile" ||
                            ele[0] == "75thPercentile" ||
                            ele[0] == "90thPercentile"
                          ) {
                            return (
                              <div key={`${ele[0]}-${index}`}>
                                <Row className="justify-content-center my-2 mt-5">
                                  <Col md={11}>
                                    <h6 className="text-center bg-soft-secondary p-2 text-primary rounded fw-medium">
                                      {ele[0]}
                                    </h6>
                                  </Col>
                                </Row>
                                {Object.entries(ele[1]).map((item, ind) => {
                                  return (
                                    <Row
                                      className="justify-content-center mb-2 align-items-center"
                                      key={`${item[0]}-${ind}`}
                                    >
                                      <Col md={4} sm={4}>
                                        <h6 className="mb-0 bg-soft-primary p-2 rounded text-primary">
                                          {item[0]}
                                        </h6>
                                      </Col>
                                      <Col md={7} sm={7} className="">
                                        <ReactSelect
                                          id={`${ele[0]}.${item[0]}`}
                                          name={`${ele[0]}.${item[0]}`}
                                          value={getOptionByValue(item[1])}
                                          onChange={(event) => {
                                            setFieldValue(
                                              `${ele[0]}.${item[0]}`,
                                              event?.value
                                            );
                                          }}
                                          onBlur={() => {
                                            setFieldTouched(
                                              `${ele[0]}.${item[0]}`,
                                              true
                                            );
                                            setFieldError(
                                              `${ele[0]}.${item[0]}`,
                                              true
                                            );
                                          }}
                                          options={
                                            activeID
                                              ? buildMultiOptions(columns)
                                              : buildMultiOptions(
                                                  externalColumns
                                                )
                                          }
                                          isFilledColor={true}
                                        />
                                        <ErrorMessage
                                          name={`${ele[0]}.${item[0]}`}
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </div>
                            );
                          } else {
                            return (
                              <Row
                                className="justify-content-center mb-2 align-items-center"
                                key={`${ele}-${index}`}
                              >
                                <Col md={4} sm={4}>
                                  <h6 className="mb-0 bg-soft-primary p-2 rounded text-primary">
                                    {ele[0]}
                                  </h6>
                                </Col>
                                <Col md={7} sm={7}>
                                  <ReactSelect
                                    id={ele[0]}
                                    className=" mb-0"
                                    name={ele[0]}
                                    onChange={(event) => {
                                      setFieldValue(ele[0], event?.value);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched(ele[0], true);
                                      setFieldError(ele[0], true);
                                    }}
                                    options={
                                      activeID
                                        ? buildMultiOptions(columns)
                                        : buildMultiOptions(externalColumns)
                                    }
                                    value={getOptionByValue(ele[1])}
                                    isFilledColor={true}
                                  />
                                  <ErrorMessage
                                    name={ele[0]}
                                    component="div"
                                    className="error text-danger"
                                  />
                                </Col>
                              </Row>
                            );
                          }
                        })}
                    </>
                    <Row className="justify-content-center">
                      <Col md={4} sm={4}></Col>
                      <Col md={7} sm={7}>
                        {" "}
                        <div className="d-flex justify-content-end mt-3 ">
                          <Button
                            className="btn btn-sm theme-cancel-btn mx-2 px-4 fs-6"
                            color="white"
                            onClick={() => onCloseClick(!show)}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn px-4"
                            color="secondary"
                            onClick={() => {
                              handleSubmitMapper(values);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>{" "}
                </SimpleBar>
              </div>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};
export default MapSurveyData;
