import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getJobFamily, getJobFamilyGroup } from "../../../slices/thunks";
import { buildOptions, getValues } from "../../../utils/dataFormat";
import GroupedBarChart from "../../../Components/Charts/GroupedBarChart";
import ReactSelect from "../../../Components/Custom/ReactSelect";
import { getUserData } from "../../../utils/auth";

const GroupedBarVisual = () => {
  document.title = "Compscale";
  const dispatch = useDispatch();
  const userData = getUserData();
  const [jobFamily, setJobFamily] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("Development Engineering");
  const storeFamilyGroup = useSelector(
    (state) => state?.JobArchitecture?.jobFamilyGroup
  );
  const storeFamily = useSelector((state) => state?.JobArchitecture?.jobFamily);
  useEffect(() => {
    dispatch(getJobFamilyGroup(userData?.userId));
  }, []);

  useEffect(() => {
    getXAxisData();
  }, [selectedGroup]);

  const getXAxisData = () => {
    let data1;
    if (selectedGroup == "Development Engineering") {
      data1 = [
        "Software Development",
        "Hardware Development",
        "Embedded Systems Engineering",
        "Product Development",
        "Systems Engineering",
        "Application Development",
        "Quality Assurance Engineering",
      ];
    } else if (selectedGroup == "Engineering") {
      data1 = [
        "Civil Engineering",
        "Mechanical Engineering",
        "Electrical Engineering",
        "Chemical Engineering",
        "Industrial Engineering",
        "Structural Engineering",
        "Aerospace Engineering",
      ];
    } else if (selectedGroup == "IT") {
      data1 = [
        "Network Engineering",
        "Systems Administration",
        "Database Administration",
        "IT Support/Helpdesk",
        "Cybersecurity",
        "Cloud Computing",
        "Data Analysis",
      ];
    } else if (
      selectedGroup == "Mech" ||
      selectedGroup == "Production & Skilled Trades"
    ) {
      data1 = [
        "Mechanical Design Engineering",
        "Automotive Engineering",
        "HVAC Engineering",
        "Robotics Engineering",
        "Manufacturing Engineering",
        "Aerospace Engineering",
        "Marine Engineering",
      ];
    } else if (selectedGroup == "Technology") {
      data1 = [
        "Software Development",
        "Hardware Engineering",
        "Robotics Engineering",
        "User Experience Design",
        "Technical Support",
        "Technology Consulting",
        "Systems Architecture",
      ];
    }
    setXAxisData(data1);
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex overflow-hidden">
          <h5 className="mb-0 flex-grow-1">Job Family</h5>
          <ReactSelect
            id="group"
            className=" mb-0 w-50"
            name="group"
            value={{ label: selectedGroup, value: selectedGroup }}
            options={buildOptions(
              storeFamilyGroup?.familyGroup,
              "familyGroup",
              "groupId"
            )}
            onChange={(event) => {
              setSelectedGroup(event?.label);
            }}
          />
        </CardHeader>

        <CardBody>
          <GroupedBarChart
            dataColors='["--vz-primary", "--vz-success"]'
            xAxisData={xAxisData || []}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default GroupedBarVisual;
