import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllJobDescriptions as getAllJobDescriptionsApi,
  getJobDescriptionByID as getJobDescriptionByIDApi,
} from "../../helpers/custom_backend_helper";

export const getJobDescriptions = createAsyncThunk(
  "jobDescription/getAllJobDescriptions",
  async (userId) => {
    try {
      const response = await getAllJobDescriptionsApi(userId);
      const data = await response;
      return data;
    } catch (error) {
      // return error;
      return Promise.reject(error);
    }
  }
);
export const getJobDescriptionById = createAsyncThunk(
  "jobDescription/getJobDescriptionById",
  async (id) => {
    try {
      const response = await getJobDescriptionByIDApi(id);
      const data = await response.data;
      return data;
    } catch (error) {
      // return error;
      return Promise.reject(error);
    }
  }
);
export const getJobDescriptionsAll = createAsyncThunk(
  "jobDescription/jobDescriptionsAll",
  async (userId) => {
    try {
      const response = await getAllJobDescriptionsApi(userId);
      const data = await response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);