import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ExportPDF from "../Exports/PDFExport";
import ExcelExport from "../Exports/ExcelExport";
import TemplateExport from "../Exports/ExportTemplate";
import { useLocation } from "react-router-dom";
import { showExportTemplateBtn } from "../../utils/displayOptions";

const Export = ({
  columns,
  list,
  fileName,
  exportHeader,
  infoList,
  isInfo,
}) => {
  const route = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="col-sm-auto ms-auto me-2  ">
      <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          className="btn btn-light rounded-pill d-flex align-items-center px-3 "
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bx bxs-download fs-6 me-1"></i>
          <p className="mb-0">Download</p>
        </DropdownToggle>
        {dropdownOpen && (
          <DropdownMenu className="mt-2">
            <li className="px-0">
              <DropdownItem>
                <ExportPDF
                  className="dropdown-item"
                  columns={columns}
                  fileName={fileName}
                  list={list}
                  isInfo={isInfo}
                  infoList={infoList}
                />
              </DropdownItem>
            </li>
            <li className="px-0">
              <DropdownItem>
                <ExcelExport
                  className="dropdown-item"
                  columns={columns}
                  fileName={fileName}
                  list={list}
                />
              </DropdownItem>
            </li>
            {showExportTemplateBtn?.includes(route?.pathname) && (
              <li className="px-0">
                <DropdownItem>
                  <TemplateExport
                    className="dropdown-item"
                    header={exportHeader}
                  />
                </DropdownItem>
              </li>
            )}
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    </div>
  );
};

export default Export;
