import React from "react";
import withRouter from "../../Components/Common/withRouter";
import { Col, Row } from "reactstrap";
import logo from "../../assets/images/logo.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ParticlesAuth = ({ children }) => {
  return (
    <React.Fragment>
      {/* <div className="auth-page-wrapper pt-5">
                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay"></div>

                    <div className="shape">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 1440 120"
                        >
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg>
                    </div>
                    {children}
                </div>

                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">
                                        &copy; {new Date().getFullYear()} Compscale. Crafted with{" "}
                                        <i className="mdi mdi-heart text-danger"></i> by Doitup
                                        Innovative Technologies
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div> */}
      <div className="auth-page-wrapper">
        <Row className="mx-0">
          <Col md={8}>
            <div
              className="auth-one-bg-position auth-one-bg"
              id="auth-particles"
            >
              <div className="bg-overlay"></div>
              {/* <div className="text-center m-auto">
                                <img src={logo} alt="" height="20" />
                            </div> */}

              <div className="d-flex h-100 flex-column-reverse">
                <div className="mb-3">
                  <i className="ri-double-quotes-l display-4 text-success"></i>
                </div>

                <Carousel
                  showThumbs={false}
                  autoPlay={true}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop={true}
                  showIndicators={false}
                  className="slide"
                  id="qoutescarouselIndicators"
                >
                  <div className="carousel-inner text-center text-white-50 pb-5 px-5">
                    <div className="item">
                      <p className="fs-18 fst-italic">
                        " Empower Your Workforce with Precision: Navigate the Compensation Landscape Seamlessly with our Cutting-Edge Compensation Scale Tool! "
                      </p>
                    </div>
                  </div>
                  <div className="carousel-inner text-center text-white-50 pb-5 px-5">
                    <div className="item">
                      <p className="fs-18 fst-italic">
                        "Strategize Success, Compensate with Precision: Elevate Your Workforce with Our Dynamic Compensation Scale Tool – Where Fairness Meets Fulfillment!"
                      </p>
                    </div>
                  </div>
                  <div className="carousel-inner text-center text-white-50 pb-5 px-5">
                    <div className="item">
                      <p className="fs-18 fst-italic">
                        "Beyond Numbers, Beyond Limits: Unleash the Power of Precision Compensation with Our Advanced Scale Tool – Where Every Value Finds Its Worth!"
                      </p>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </Col>
          <Col className="p-0"> {children}</Col>
        </Row>

        {/* <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-light">
                                        &copy; {new Date().getFullYear()} Compscale. Crafted with{" "}
                                        <i className="mdi mdi-heart text-danger"></i> by Doitup
                                        Innovative Technologies
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(ParticlesAuth);
