import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../../Components/Custom/ReactSelect";
import { buildOptions } from "../../../utils/dataFormat";
import { isEmpty } from "lodash";
import {
  postPayzone,
  putPayzone,
} from "../../../helpers/custom_backend_helper";
import { pushPayzone, updatePayzone } from "../../../slices/Payzone/reducer";
import { toasterError, toasterSuccess } from "../../../utils/toaster";
import { IdView } from "../../../Components/Custom/IdView";
import { getPayzoneById } from "../../../slices/thunks";
import Payzone from ".";

const defaultValues = {
  payZone: "",
  geoDiff: "",
};
export const AddPayzone = ({ onCloseClick, isOpen, id }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const dispatch = useDispatch();
  const storePayzoneById = useSelector((state) => state?.Payzone?.PayzoneById);

  const validateSchema = Yup.object().shape({
    payZone: Yup.mixed().required("Payzone Group is required"),
    geoDiff: Yup.mixed().required("Geo Diff is required"),
  });

  useEffect(() => {
    if (id && isOpen) {
      dispatch(getPayzoneById(id));
    }
  }, [isOpen]);

  useEffect(() => {
    if (storePayzoneById && id) {
      setInitialValues(storePayzoneById?.data);
    } else {
      setInitialValues(defaultValues);
    }
  }, [id, storePayzoneById]);

  const handleSubmit = (values) => {
    let reqBody = {
      payZone: values?.payZone,
      geoDiff: values?.geoDiff,
    };
    let body = id ? { ...reqBody, ["payZoneId"]: id } : reqBody;
    setLoading(true);
    let request = id ? putPayzone : postPayzone;
    let successMsg = id ? "Updated Payzone!" : "Added Payzone!";
    let errorMsg = id ? "Unable to update Payzone!" : "Unable to add Payzone!";

    request(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          const action = id ? updatePayzone(body) : pushPayzone(res?.data);
          dispatch(action);
          toasterSuccess(successMsg);
          onCloseClick();
        } else {
          toasterError(errorMsg);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toasterError(errorMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="jobfamily-modal">
      <Modal isOpen={isOpen} toggle={onCloseClick} centered={true}>
        <ModalHeader
          toggle={onCloseClick}
          className="bg-secondary p-3 px-4 modal-header"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-primary d-flex align-items-center">
              {id ? (
                <>
                  EDIT - Payzone <IdView id={id} />
                </>
              ) : (
                "ADD - Payzone"
              )}
            </span>
          </div>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validateSchema}
        >
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <ModalBody>
                  <Row>
                    <Col className="col-12 mb-4">
                      <Label for="payZone">Pay Zone</Label>
                      <Input
                        className="form-control"
                        id="payZone"
                        name="payZone"
                        type="text"
                        placeholder="ex. ZN1"
                        value={values?.payZone}
                        onChange={(event) => {
                          setFieldValue("payZone", event.target.value);
                        }}
                        onBlur={() => {
                          setFieldTouched("payZone", true);
                          setFieldError("payZone", true);
                        }}
                      />
                      <ErrorMessage
                        name="payZone"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                    <Col className="col-12 mb-4">
                      <Label for="geoDiff">Geo Diff</Label>
                      <Input
                        className="form-control"
                        id="geoDiff"
                        name="geoDiff"
                        type="text"
                        placeholder="ex. 110"
                        value={values?.geoDiff}
                        onChange={(event) => {
                          setFieldValue("geoDiff", event.target.value);
                        }}
                        onBlur={() => {
                          setFieldTouched("geoDiff", true);
                          setFieldError("geoDiff", true);
                        }}
                      />
                      <ErrorMessage
                        name="geoDiff"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                  </Row>{" "}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={onCloseClick}
                    className="btn theme-cancel-btn mx-2 px-4 btn btn-white"
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    className="btn px-4 btn btn-secondary"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddPayzone;
