import React, { useState, useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import JobFamilyGroup from "./jobFamilyGroup";
import JobFamily from "./jobFamily";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import { useDispatch } from "react-redux";
import {
  getJobFamily,
  getJobFamilyGroup,
} from "../../slices/jobArchitecture/thunk";
import GeoPayzone from "./GeoPayzone/index";
import Payzone from "./PayZone";
import PageHeader from "../../Components/Common/Header";

const data = [
  {
    jobFamilygroupId: 1,
    jobFamilygroupName: "Engineering",
  },
  {
    jobFamilygroupId: 2,
    jobFamilygroupName: "Marketing",
  },
  {
    jobFamilygroupId: 3,
    jobFamilygroupName: "Finance",
  },
  {
    jobFamilygroupId: 4,
    jobFamilygroupName: "Human Resources",
  },
  {
    jobFamilygroupId: 5,
    jobFamilygroupName: "Sales",
  },
  {
    jobFamilygroupId: 6,
    jobFamilygroupName: "Customer Support",
  },
  {
    jobFamilygroupId: 7,
    jobFamilygroupName: "Product Management",
  },
  {
    jobFamilygroupId: 8,
    jobFamilygroupName: "Legal",
  },
  {
    jobFamilygroupId: 9,
    jobFamilygroupName: "Research and Development",
  },
  {
    jobFamilygroupId: 10,
    jobFamilygroupName: "IT and Services",
  },
];

const jobFamilyData = [
  {
    jobFamilyId: 1,
    jobFamilygroupName: "Engineering",
    jobFamilyName: "Primary Engineering",
  },
  {
    jobFamilyId: 2,
    jobFamilygroupName: "Marketing",
    jobFamilyName: "Secondary Marketing",
  },
];

export const BusinessSetup = () => {
  const [familyGroupData, setFamilyGroupData] = useState(data);
  const [familyData, setFamilyData] = useState(jobFamilyData);
  const location = useLocation();
  const [customTab, setCustomTab] = useState("job-family-group");
  const dispatch = useDispatch();
  let navItems = [
    {
      text: "Job Family Group",
      to: "./job-family-group",
      activeClass: "job-family-group",
      toggleNumber: 1,
    },
    {
      text: "Job Family",
      to: "./job-family",
      activeClass: "job-family",
      toggleNumber: 2,
    },
    {
      text: "Geo Payzone",
      to: "./geo-payzone",
      activeClass: "geo-payzone",
      toggleNumber: 3,
    },
    {
      text: "Payzone",
      to: "./payzone",
      activeClass: "payzone",
      toggleNumber: 4,
    },
  ];

  useEffect(() => {
    const activeNavTab = navItems.find((item) =>
      location.pathname.includes(item?.activeClass)
    );
    if (activeNavTab) {
      setCustomTab(activeNavTab.activeClass);
    }
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setCustomTab(tab);
  };

  return (
    <div>
      <div className="page-content business-setup">
        <PageHeader
          pageTitle={"Business Setup"}
          isAddBtn={false}
          isImportBtn={false}
        />
        <Row>
          <Col>
            <Card>
              <CardBody className="p-2">
                <Nav pills className="business-nav" role="tablist">
                  {navItems?.map((item, index) => {
                    return (
                      <NavItem key={index}>
                        <Link
                          to={item.to}
                          key={index}
                          className={`me-3 business-navitem  ${
                            item.activeClass === customTab
                              ? "active bg-soft-primary  text-primary "
                              : ""
                          }`}
                          onClick={() => {
                            handleTabClick(item.activeClass);
                          }}
                        >
                          {item.text}
                        </Link>
                      </NavItem>
                    );
                  })}
                </Nav>
              </CardBody>
            </Card>
            <Routes>
              <Route index element={<Navigate to="job-family-group" />} />
              <Route
                path="job-family-group"
                element={<JobFamilyGroup familyGroupData={familyGroupData} />}
              />
              <Route
                path="job-family"
                element={
                  <JobFamily
                    familyGroupData={familyGroupData}
                    jobFamilyData={familyData}
                  />
                }
              />
              <Route path="geo-payzone" element={<GeoPayzone />} />
              <Route path="payzone" element={<Payzone />} />
            </Routes>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BusinessSetup;
