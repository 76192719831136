import React from "react";
import { Label } from "reactstrap";
const ViewItem = ({
    heading,
    description,
    isHeading,
}) => {
    return (
        <>
            {isHeading && <Label className="text-black fw-semibold">{heading}</Label>}
            <p className={``}>
                {description}
            </p>
        </>
    );
};

export default ViewItem;
