import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllDashboardCount as getAllDashboardCountApi,
} from "../../helpers/custom_backend_helper";

export const getDashboardCount = createAsyncThunk(
    "dashboard/getDashboardCount",
    async () => {
        try {
            const response = await getAllDashboardCountApi();
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);
