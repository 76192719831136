import React from "react";

export const TableHeader = ({ tableHeader }) => {
    return (
        <tr className="">
            {tableHeader?.map((ele) => {
                return (
                    <th colSpan={ele?.colSpan} className={"bg-primary text-center " + ele.classNames}>
                        {ele?.header}
                    </th>
                );
            })}
        </tr>
    );
};
