import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColors";
import ReactSelect from "../Custom/ReactSelect";
import { Col, Label, Row } from "reactstrap";
import { MetricOneOptions, MetricTwoOptions } from "../../utils/dataOptions";

const LinewithDataLabels = ({ dataColors }) => {
  const [metricOne, setMetricOne] = useState("Departments");
  const [metricTwo, setMetricTwo] = useState("Compa-Ratio");
  const [seriesDataOne, setSeriesDataOne] = useState([
    26, 24, 32, 36, 33, 31, 33,
  ]);
  const [seriesDataTwo, setSeriesDatatwo] = useState([
    14, 11, 16, 12, 17, 13, 12,
  ]);

  useEffect(() => {
    getXAxisData();
  }, [metricOne, metricTwo]);

  const getXAxisData = () => {
    let data1, data2;
    if (metricOne == "Departments") {
      data1 = [26, 24, 32, 36, 33, 31, 33];
    } else if (metricOne == "State") {
      data1 = [20, 15, 32, 36, 33, 31, 33];
    } else if (metricOne == "City") {
      data1 = [26, 24, 22, 36, 39, 31, 33];
    } else if (metricOne == "Manager") {
      data1 = [26, 24, 32, 26, 33, 31, 33];
    }
    if (metricTwo == "Departments") {
      data2 = [14, 20, 16, 12, 17, 13, 12];
    } else if (metricTwo == "State") {
      data2 = [20, 15, 32, 36, 33, 31, 33];
    } else if (metricTwo == "City") {
      data2 = [20, 11, 16, 39, 17, 13, 12];
    }
    else if (metricOne == "Manager") {
      data2 = [26, 24, 32, 26, 33, 31, 33];
    }
    setSeriesDataOne(data1);
    setSeriesDatatwo(data2);
  }

  var LinewithDataLabelsColors = getChartColorsArray(dataColors);
  var series = [
    {
      //   name: "High - 2018",
      data: seriesDataOne,
    },
    {
      //   name: "Low - 2018",
      data: seriesDataTwo,
    },
  ];
  var options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: LinewithDataLabelsColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    // title: {
    //   text: "Average High & Low Temperature",
    //   align: "left",
    //   style: {
    //     fontWeight: 500,
    //   },
    // },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: metricTwo,
      },
    },
    yaxis: {
      title: {
        text: metricOne,
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Label className="mb-1">Metric One:</Label>
          <ReactSelect
            id="groupId"
            className=" mb-0"
            name="groupId"
            value={{ label: metricOne, value: metricOne }}
            options={MetricOneOptions}
            onChange={(event) => {
              setMetricOne(event?.label);
            }}
          />
        </Col>
        <Col md={6}>
          <Label className="mb-1">Metric Two:</Label>
          <ReactSelect
            id="groupId1"
            className=" mb-0"
            name="groupId1"
            value={{ label: metricTwo, value: metricTwo }}
            options={MetricOneOptions}
            onChange={(event) => {
              setMetricTwo(event?.label);
            }}
          />
        </Col>
      </Row>

      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="380"
        className="apex-charts"
      />
    </React.Fragment>
  );
};
export default LinewithDataLabels;
