// LOGIN
export const POST_LOGIN = "/user/login";

// DEPARTMENTS
export const GET_DEPARTMENTS = "/department/all";
export const GET_DEPARTMENT_BY_ID = "/department/id";
export const POST_DEPARTMENT = "/department/add";

// JOB_DESCRIPTION
export const GET_JOB_DESCRIPTIONS = "/jd/all";
export const GET_JOB_DESCRIPTION_BY_ID = "/jd/id";
export const POST_JOB_DESCRIPTION = "/jd/add";
export const PUT_JOB_DESCRIPTION = "/jd/update";
export const DELETE_JOB_DESCRIPTION = "/jd/delete";
export const POST_JD_FILE_IMPORT = "/jd/upload";

// JOB ARCHITECTURE
export const POST_JOB_ARC_FILE_IMPORT = "/arc/upload";
export const GET_JOB_ARCHITECTURES = "/arc/all";
export const GET_ALL_JOB_ARCHITECTURES = "/arc/all";
export const POST_JOB_FAMILY = "/arc/addfamily";
export const POST_JOB_FAMILY_GROUP = "/arc/addfamilygroup";
export const GET_JOB_FAMILY_GROUP = "/arc/allgroups";
export const GET_JOB_FAMILY = "/arc/get/allfamily";
export const POST_JOB_PROFILE = "/arc/addprofile";
export const GET_JOB_PROFILE_BY_ID = "/arc/profile";
export const PUT_JOB_PROFILE = "/arc/updateprofile";

// LEVEL MAPPING
export const GET_LEVEL_MAPPING_BY_USER = "/level/mapping";
export const POST_LEVEL_MAPPING = "/level/mapping";
export const GET_DEFAULT_MAPPING = "/level/default";

// EXTERNAL LEVELS
export const GET_LEVEL_MAPPING = "/level/all";
export const GET_LEVELS_BY_USER = "/level/all";

// INTERNAL LEVELS
export const GET_GENERAL_LEVELS = "/level/allgenerallevels";
export const GET_GENERAL_LEVELS_BY_USER = "/level/allgenerallevels";
export const POST_CUSTOM_INTERNAL_LEVEL = "/level/customlevel";

// BENCH MARKING
export const GET_AI_BENCH_MARKING = "/ai/benchmark";
export const APPLY_BENCHMARKING = "/jd/applyjob";
export const GET_BENCHMARKING = "/benchmark/all";

// Market Pricing
export const GET_MARKET_PRICING = "/market/get";
export const GET_MARKET_DATA = "/jd/marketdata";
export const PUT_MARKET_PRICING = "/jd/marketdata";

// Market Data
export const GET_MARKET_RANGES = "/jd/marketrange";

//Incumbent Impact Analysis
export const GET_EMPLOYEES = "/emp/all";
export const POST_EMP_FILE_IMPORT = "/emp/upload";
export const GET_EMPLOYEE_BY_ID = "/emp/employee";

//Dashboard
export const GET_DASHBOARD_COUNT = "/dashboard/cards";

// Survey Data
export const GET_EXTERNAL_SURVEY_DATA = "/external/get/all";
export const GET_EXTERNAL_SURVEY_DATA_BY_ID = "/external/get/by";
export const UPLOAD_EXTERNAL_SURVEY_DATA = "/external/upload";
export const PUT_EXTERNAL_SURVEY_DATA_MAPPER = "/external/updateMapper";


// INTERNAL FIELDS
export const GET_INTERNAL_FIELDS = "external/get/interfields"


// PAYZONE
export const GET_PAYZONES = "/payzone/get/all";
export const GET_PAYZONE_BY_ID = "/payzone/get";
export const POST_PAYZONE = "/payzone/add";
export const PUT_PAYZONE = "/payzone/update";
export const DELETE_PAYZONE = "/payzone/delete";

// GEO-PAYZONE
export const GET_GEO_PAYZONES = "/geopayzone/get/all";
export const GET_GEO_PAYZONE_BY_ID = "/geopayzone/get/by";
export const GET_GEO_PAYZONE_BY_PAYZONEID = "/geopayzone/get";
export const POST_GEO_PAYZONE = "/geopayzone/add";
export const PUT_GEO_PAYZONE = "/geopayzone/update";
export const DELETE_GEO_PAYZONE = "/geopayzone/delete";


// ---------------------------------------------------------------------------------------------------------------------- //

//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";
