import React from "react";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import error from "../../assets/images/404.svg";

export const ErrorPage = () => {
  return (
    <React.Fragment>
      <div className="auth-page-content mt-lg-5">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={12}>
              <div className="text-center pt-4">
                <div className="">
                  <img
                    src={error}
                    alt=""
                    className="error-basic-img move-animation"
                    width={"30%"}
                  />
                </div>
                <div className="mt-4">
                  <h5 className="text-muted mb-1 ">
                    Sorry, the page you are looking for is unavailable .
                  </h5>
                  <h5 className="text-muted mb-4">
                    We suggest you to back to Home or Visit our Help center
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
