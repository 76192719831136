import React from "react";
import Dropzone from "react-dropzone";
import Loader from "./Loader";
import { RiseLoader } from "react-spinners";
const FileDropzone = ({
    handleAcceptedFiles,
    className,
    name,
    setFieldValue,
    index,
    loading,
}) => {
    return (
        <Dropzone
            width="100px"
            height="100px"
            onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles, setFieldValue, index);
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <div className={"dropzone dz-clickable " + className}>
                    <div className="dz-message needsclick my-0" {...getRootProps()}>
                        {loading ? (
                            <div className="my-4">
                                <RiseLoader color={"#08569a"} className="p-3" />
                            </div>
                        ) : (
                            <div className="p-2">
                                <i
                                    className="display-4 text-primary ri-upload-cloud-2-fill"
                                    role="button"
                                />
                                <h5 className="text-primary">Attach a File to Import</h5>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Dropzone>
    );
};
export default FileDropzone;
