import React, { useEffect } from "react";
import { Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import Pagination from "./Pagination";

function PaginationContainer({
  pageIndex = 1,
  goTo,
  pageRecords,
  totalRecords = 20,
  totalPages = 10,
  //   dispatchFilter,
}) {
  const route = useLocation();
  //   useEffect(() => {
  //     dispatchFilter(route?.search);
  //   }, [pageIndex, dispatchFilter, route?.search]);

  return (
    <Row className="align-items-center g-3 text-center text-sm-start mt-2">
      <div className="col-sm">
        <div className="text-muted">
          Showing<span className="fw-semibold ms-1">{pageRecords}</span> of{" "}
          <span className="fw-semibold">{totalRecords}</span> Results
        </div>
      </div>

      <Pagination
        canPreviousPage={pageIndex > 1}
        previousPage={() => goTo(pageIndex - 1)}
        pageOptions={[...Array(totalPages).keys()]}
        pageIndex={pageIndex === 1 ? 0 : pageIndex - 1}
        gotoPage={(page) => goTo(page + 1)}
        canNextPage={pageIndex < totalPages - 1}
        nextPage={() => goTo(parseInt(pageIndex) + 1)}
      />
    </Row>
  );
}

export default PaginationContainer;
