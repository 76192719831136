import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getJobFamily, getJobFamilyGroup } from "../../../slices/thunks";
import { buildOptions, getValues } from "../../../utils/dataFormat";
import ReactSelect from "../../../Components/Custom/ReactSelect";
import { getUserData } from "../../../utils/auth";
import SimplePie from "../../../Components/Charts/SimplePieChart";

const PieChartVisual = () => {
  document.title = "Compscale";
  const dispatch = useDispatch();
  const userData = getUserData();
  const [jobFamily, setJobFamily] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [metricOne, setMetricOne] = useState("IT");
  const [metricTwo, setMetricTwo] = useState("Within Range");
  const [seriesData, setSeriesData] = useState();
  const storeFamilyGroup = useSelector(
    (state) => state?.JobArchitecture?.jobFamilyGroup
  );

  useEffect(() => {
    dispatch(getJobFamilyGroup(userData?.userId));
  }, []);

  useEffect(() => {
    getXAxisData();
  }, [metricOne, metricTwo]);

  const getXAxisData = () => {
    let data1;
    if (
      (metricOne == "IT" && metricTwo == "Within Range") ||
      (metricOne == "Engineering" && metricTwo == "Compa-Ratio < 100%")
    ) {
      data1 = [30, 70];
    } else if (
      (metricOne == "Engineering" && metricTwo == "Within Range") ||
      (metricOne == "Production & Skilled Trades" &&
        metricTwo == "Compa-Ratio < 100%")
    ) {
      data1 = [45, 55];
    } else if (
      (metricOne == "Production & Skilled Trades" &&
        metricTwo == "Within Range") ||
      (metricOne == "Technology" && metricTwo == "Compa-Ratio > 100%")
    ) {
      data1 = [74, 25];
    } else if (
      metricOne == "Development Engineering" &&
      metricTwo == "Within Range"
    ) {
      data1 = [60, 40];
    } else if (metricOne == "Mech" && metricTwo == "Within Range") {
      data1 = [40, 60];
    } else if (metricOne == "Technology" && metricTwo == "Within Range") {
      data1 = [45, 55];
    }
    if (metricTwo == "Within Range") {
      data1 = [30, 70];
    } else if (metricTwo == "Compa-Ratio < 100%") {
      data1 = [66, 34];
    } else if (metricTwo == "Compa-Ratio > 100%") {
      data1 = [74, 25];
    }
    setSeriesData(data1);
  };

  const dataOptionsTwo = [
    {
      label: "Compa-Ratio > 100%",
      value: "Compa-Ratio > 100%",
    },
    { label: "Compa-Ratio < 100%", value: "Compa-Ratio < 100%" },
    { label: "Within Range", value: "Within Range" },
  ];

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex overflow-hidden">
          <h5 className="mb-0 flex-grow-1">Job Family</h5>
          <ReactSelect
            id="group"
            className=" mb-0 w-50 me-2"
            name="group"
            value={{ label: metricOne, value: metricOne }}
            options={buildOptions(
              storeFamilyGroup?.familyGroup,
              "familyGroup",
              "groupId"
            )}
            onChange={(event) => {
              setMetricOne(event?.label);
            }}
          />
          <ReactSelect
            id="group"
            className=" mb-0 w-50"
            name="group"
            value={{ label: metricTwo, value: metricTwo }}
            options={dataOptionsTwo}
            onChange={(event) => {
              setMetricTwo(event?.label);
            }}
          />
        </CardHeader>
        <CardBody>
          <SimplePie
            dataColors='["#C90076", "#2986cc"]'
            seriesData={seriesData || []}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PieChartVisual;
