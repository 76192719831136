import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//pages
import Starter from "../pages/Pages/Starter/Starter";
import Maintenance from "../pages/Pages/Maintenance/Maintenance";
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import JobDescription from "../pages/JobDescription";
import JobArchitecture from "../pages/JobArchitecture";
import LevelMapping from "../pages/LevelMapping";
import LevelMappingFlowChart from "../pages/LevelMapping/FlowChart";
import LevelChart from "../pages/LevelMapping/levelChart";
import ExternalLevels from "../pages/ExternalLevels";
import SurveyData from "../pages/SurveyData";
import Benchmarking from "../pages/Benchmarking";
import MarketPricing from "../pages/MarketPricing/index";
import Ranges from "../pages/Ranges";
import IncumbentImpactAnalysis from "../pages/IncumbentImpactAnalysis";
import BusinessSetup from "../pages/BusinessSetup";
import { ErrorPage } from "../pages/Error";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  //Pages
  { path: "/pages-starter", component: <Starter /> },

  // Job Description
  { path: "/job-description", component: <JobDescription /> },

  // Job Family
  { path: "/job-architecture", component: <JobArchitecture /> },

  // Level Mapping
  { path: "/level-mapping", component: <LevelMapping /> },

  // Flow chart
  { path: "/level-mapping-flow-chart", component: <LevelMappingFlowChart /> },

  // Level Chart
  { path: "/level-chart", component: <LevelChart /> },

  //External Levels
  { path: "/external-levels", component: <ExternalLevels /> },

  //Survey data
  { path: "/survey-data", component: <SurveyData /> },

  // Business Setup
  { path:"/business-setup/*", component: <BusinessSetup />},
 
  // Market Pricing
  { path: "/market-pricing", component: <MarketPricing /> },

  // Benchmarking
  { path: "/benchmarking", component: <Benchmarking /> },

  //Ranges
  { path: "/ranges", component: <Ranges /> },

  //Employee
  { path: "/Incumbent-Impact-Analysis", component: <IncumbentImpactAnalysis /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/404", component: <ErrorPage /> },


  //AuthenticationInner pages
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
