import { createSlice } from "@reduxjs/toolkit";
import { getDefaulLeveltMapping, getLevelMappingByUser } from "./thunk";

export const initialState = {
    defaultMapping: { mapping: [], error: {}, loading: false },
    mappingByUser: { mapping: [], error: {}, loading: false },
};

const LevelMappingSlice = createSlice({
    name: "LevelMapping",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDefaulLeveltMapping.pending, (state, action) => {
            state.defaultMapping.mapping = [];
            state.defaultMapping.loading = true;
        });
        builder.addCase(getDefaulLeveltMapping.fulfilled, (state, action) => {
            state.defaultMapping.mapping = action.payload;
            state.defaultMapping.loading = false;
        });
        builder.addCase(getDefaulLeveltMapping.rejected, (state, action) => {
            state.defaultMapping.error = action?.error?.message || null;
            state.defaultMapping.loading = false;
        });
        builder.addCase(getLevelMappingByUser.pending, (state, action) => {
            state.mappingByUser.mapping = [];
            state.mappingByUser.loading = true;
        });
        builder.addCase(getLevelMappingByUser.fulfilled, (state, action) => {
            state.mappingByUser.mapping = action.payload;
            state.mappingByUser.loading = false;
        });
        builder.addCase(getLevelMappingByUser.rejected, (state, action) => {
            state.mappingByUser.error = action?.error?.message || null;
            state.mappingByUser.loading = false;
        });
    },
});

export const { } = LevelMappingSlice.actions;

export default LevelMappingSlice.reducer;
