import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColors";
import { formatSalary } from "../../utils/dataFormat";

const ColumnCharts = ({ dataColors, data }) => {
  var chartColumnDatatalabelColors = getChartColorsArray(dataColors);

  const getBarColors = () => {
    let colors;
    if (data[0] == data[1]) {
      colors = ["#faaa1d", "#faaa1d", "#143D59"];
    }else if (data[1] == data[2]) {
      colors = ["#143D59", "#faaa1d", "#faaa1d"];
    }
    else {
      colors=["#143D59", "#faaa1d", "#143D59"]
    }
    return colors
  };


  const series = [
    {
      name: "Internal Equity Analysis",
      data: data.map((value, index) => ({
        x: ["Maximum", "Current Salary", "Minimum"][index],
        y: value,
        fillColor: getBarColors()[index],
      })),
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
          offsetY: -20,
        },
        colors: {
          ranges: [],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "$" + formatSalary(val);
      },
      offsetY: -30,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: ["Maximum", "Current Salary", "Minimum"],
      position: "bottom",
      labels: {
        offsetY: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return "$" + formatSalary(val);
        },
      },
    },
    // title: {
    //   text: "Internal Equity Analysis",
    //   floating: true,
    //   offsetY: 0,
    //   align: "center",
    //   style: {
    //     color: "#444",
    //     fontWeight: 500,
    //     display:"none"
    //   },
    // },
  };

  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={310}
    />
  );
};

export default ColumnCharts;
