import React, { useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Custom/Loader";
import { getLevelMapping } from "../../slices/thunks";

export const LevelMapping = () => {
  const storeLevelMapping = useSelector((state) => state?.Levels);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLevelMapping());
  }, []);

  const columns = [
    {
      Header: "Level ",
      accessor: "internalLevel",
      filterable: true,
      visible: true,
    },
    {
      Header: "Generic Job Title",
      accessor: "generalJobType",
      filterable: true,
      visible: true,
    },
    {
      Header: "Survey Level",
      accessor: "externalLevel",
      filterable: true,
      visible: true,
    },
    {
      Header: "Job Type",
      accessor: "jobType",
      filterable: true,
      visible: true,
    },
    {
      Header: "Management Type",
      accessor: "management",
      filterable: true,
      visible: true,
    },
  ];

  const tableHeader = [
    { header: "Internal Job Levels", colSpan: 2, classNames: "bg-danger" },
    { header: "External Job Levels", colSpan: 3, classNames: "bg-info" },
  ];

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          {/* <BreadCrumb title="Level Mapping" pageLink="/level-mapping" /> */}
          <PageHeader
            headerName={"Level Mapping"}
            isAddBtn={false}
            isImportBtn={false}
          />
          <Row>
            <Col md={12} lg={12} xxl={12}>
              <Card>
                <CardBody className="pt-0">
                  <div className="col-sm-auto ms-auto">
                    <TableContainer
                      loading={false}
                      columns={columns}
                      data={storeLevelMapping?.levels || []}
                      isGlobalFilter={true}
                      columnFilter={false}
                      isAddUserList={false}
                      customPageSize={50}
                      showPagination={false}
                      divClass="table-responsive table-card mb-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                      SearchPlaceholder="Search for..."
                      showButton={false}
                      exportButton={false}
                      importButton={false}
                      isDefaultPagination={false}
                      isTableHeader={true}
                      tableHeader={tableHeader}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {storeLevelMapping?.loading && <Loader />}
      </React.Fragment>
    </div>
  );
};
export default LevelMapping;
