module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "https://api-node.themesbrand.website",
    // API_URL: "http://localhost:8000/api/v1",
    // API_BASE_URL: "http://localhost:8000",
    API_URL: "https://api.compscale.com/api/v1",
    API_BASE_URL:"https://api.compscale.com/api/v1"
  }
};
