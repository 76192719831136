import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLevelMapping as getAllLevelMappingApi, getAllLevelsByUser as getAllLevelsByUserApi } from "../../helpers/custom_backend_helper";
export const getLevelMapping = createAsyncThunk(
    "levelMapping/getLevelMapping",
    async () => {
        try {
            const response = await getAllLevelMappingApi();
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
); export const getLevelsByUser = createAsyncThunk(
    "levelMapping/getLevelsByUser",
    async (id) => {
        try {
            const response = await getAllLevelsByUserApi(id);
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);