import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllExternalSurveyData as getAllExternalSurveyDataApi,
  getAllExternalSurveyDataByID as getAllExternalSurveyDataByIDApi,
} from "../../helpers/custom_backend_helper";

export const getExternalSurveyData = createAsyncThunk(
  "SurveyData/getExternalSurveyData",
  async () => {
    try {
      const response = await getAllExternalSurveyDataApi();
      const data = await response?.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getExternalSurveyDataByID = createAsyncThunk(
  "SurveyData/getExternalSurveyDataByID",
  async (id) => {
    try {
      const response = await getAllExternalSurveyDataByIDApi(id);
      const data = await response?.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
