import React, { useEffect } from "react";
import { Col, Label, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import ViewItem from "../../Components/Custom/ViewItem";
import { IdView } from "../../Components/Custom/IdView";
import { useDispatch, useSelector } from "react-redux";
import { getJobDescriptionById } from "../../slices/thunks";
import DownloadView from "../../Components/Custom/Download";
import { Print } from "../../Components/Custom/Print";
import SimpleBar from "simplebar-react";
import moment from "moment/moment";

export const View = ({ isOpen, onCloseClick, id }) => {
  const dispatch = useDispatch();
  const storeJobDescription = useSelector(
    (state) => state.JobDescription?.jobDescriptionById?.jobDescription
  );

  const getJsonData = () => {
    const result = {
      "Job Description": storeJobDescription?.jobDescription,
      "Internal Job title": storeJobDescription?.internalJobtitle,
      Department: storeJobDescription?.department,
      "Pay Range": storeJobDescription?.payRange,
      Location: storeJobDescription?.location,
      "Job Type": storeJobDescription?.jobType,
      "Report Job Title": storeJobDescription?.reportJobTitile,
      "Work Type": storeJobDescription?.workType,
      "About Company": storeJobDescription?.aboutCompany,
      Responsibilities: storeJobDescription?.responsibilities,
      "Skills & Abilities": storeJobDescription?.skillsAbilities,
      Education: storeJobDescription?.education,
      Experience: storeJobDescription?.experience,
      "Additional Information": storeJobDescription?.additionalInformation,
      Level: storeJobDescription?.level,
    };
    return result;
  };

  useEffect(() => {
    if (isOpen && id) {
      dispatch(getJobDescriptionById(id));
    }
  }, [isOpen]);

  const renderViewItem = (heading, descriptionKey, colSize, data) => (
    <Col md={colSize} lg={colSize} xl={colSize} className="mb-4">
      <ViewItem
        heading={heading}
        isHeading={true}
        description={storeJobDescription[descriptionKey]}
      />
    </Col>
  );

  return (
    <div>
      <Modal
        fade={true}
        isOpen={isOpen}
        toggle={onCloseClick}
        centered={true}
        size="lg"
        modalClassName="flip"
      >
        <ModalHeader
          className="bg-secondary p-3 px-4"
          toggle={() => onCloseClick(!isOpen)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-white d-flex align-items-center">
              VIEW - Job Description{" "}
              <IdView id={storeJobDescription?.jobDescriptionId} />
            </span>
            <DownloadView
              id={"modal-body"}
              isOpen={isOpen}
              fileName={`Job Description - ${storeJobDescription?.jobDescriptionId}`}
              JsonData={getJsonData()}
              isWordDoc={true}
            />
            {/* <Print /> */}
          </div>
        </ModalHeader>
        <SimpleBar className="modalbody-scroll">
          <ModalBody className="p-4" id="modal-body">
            <Row>
              {renderViewItem("Internal Job Title", "internalJobtitle", 4)}
              {renderViewItem("Department", "department", 4)}
              {renderViewItem("Job Level", "level", 4)}
              {renderViewItem("Reports to Job title", "reportJobTitile", 4)}
              {renderViewItem("Mode of Work", "workType", 4)}
              {renderViewItem("Employment Type", "jobType", 4)}
              {renderViewItem("Education", "education", 4)}
              {renderViewItem("Experience", "experience", 4)}
              {renderViewItem("Expected Pay range", "payRange", 4)}
              {renderViewItem("Location", "location", 4)}
              <Col md={4} className="mb-4">
                <ViewItem
                  isHeading={true}
                  heading={"Country"}
                  description={storeJobDescription?.country?.name || ""}
                />
              </Col>
              <Col md={4} className="mb-4">
                <ViewItem
                  isHeading={true}
                  heading={"Updated Date"}
                  description={moment(storeJobDescription?.timeStamps?.updatedAt ).format("Do MMM YY")|| ""}
                />
              </Col>
              <Col md={12} className="mb-4">
                <Label className="fw-semibold">
                  Required Skills & Abilities
                </Label>
                <div className="d-flex mb-2 flex-wrap">
                  {storeJobDescription?.skillsAbilities?.map((ele) => {
                    return (
                      <p className="bg-light me-2 p-1 px-2 rounded">{ele}</p>
                    );
                  })}
                </div>
              </Col>
              {renderViewItem("Brief about the Company", "aboutCompany", 12)}
              {renderViewItem(
                "Job Description / Summary about the job",
                "jobDescription",
                12
              )}
              {renderViewItem("Responsibilities", "responsibilities", 12)}
              {renderViewItem(
                "Additional Information",
                "additionalInformation",
                12
              )}
            </Row>
          </ModalBody>
        </SimpleBar>
      </Modal>
    </div>
  );
};
export default View;
