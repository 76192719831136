import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import Add from "./Add";
import ActionButton from "../../Components/Common/ActionButton";
import View from "./View";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardCount,
  getJobDescriptions,
  getJobDescriptionsAll,
} from "../../slices/thunks";
import Loader from "../../Components/Custom/Loader";
import {
  PostJDFile,
  deleteJobDescription,
} from "../../helpers/custom_backend_helper";
import { deleteAJobDescription } from "../../slices/jobDescription/reducer";
import DeleteModal from "../../Components/Common/DeleteModal";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import StatusBadge from "../../Components/Custom/StatusBadge";
import { FileImport } from "../../Components/Custom/FileImport";
import { getUserData } from "../../utils/auth";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import useUpdateRouteWithQueryParams from "../../Components/Hooks/queryParams";
import JDFilters from "./Filters";
import { useLocation } from "react-router-dom";

export const JobDescription = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = getUserData();
  const storeJobDescription = useSelector((state) => state.JobDescription);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeId, setActiveId] = useState();
  const [actionType, setActionType] = useState("Add");
  const [loading, setLoading] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageIndex, setPageIndex] = useState();
  const updateRouteWithParams = useUpdateRouteWithQueryParams();
  const queryParams = parseQueryParams();
  const storeCount = useSelector((state) => state?.Dashboard?.count);
  const [filterModal, setFilterModal] = useState(false);
  const { page, limit, ...filterQueryParams } = queryParams;
  const toggleFilterModal = () => setFilterModal(!filterModal);

  const columns = [
    {
      Header: "S.No",
      accessor: "jobDescriptionId",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          <h6 className="text-primary fw-semibold mb-0">{cellProps?.value}</h6>
        );
      },
    },
    {
      Header: "Job Level",
      accessor: "level",
      filterable: true,
      visible: true,
    },
    {
      Header: "Job Title",
      accessor: "internalJobtitle",
      filterable: true,
      visible: true,
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return <StatusBadge status={cellProps.value} />;
      },
    },
    {
      Header: "Pay Range",
      accessor: "payRange",
      filterable: true,
      visible: false,
    },
    {
      Header: "Department",
      accessor: "department",
      filterable: true,
      visible: false,
    },
    {
      Header: "Location",
      accessor: "location",
      filterable: true,
      visible: false,
    },
    {
      Header: "Job Type",
      accessor: "jobType",
      filterable: true,
      visible: false,
    },
    {
      Header: "Work Type",
      accessor: "workType",
      filterable: true,
      visible: false,
    },
    {
      Header: "Reports to Job Title",
      accessor: "reportJobTitile",
      filterable: true,
      visible: false,
    },
    {
      Header: "Actions",
      accessor: "Actions",
      filterable: false,
      visible: true,
      Cell: (cellProps) => {
        return (
          <div>
            <ul className="list-inline hstack gap-1 mb-0">
              <li className="list-inline-item">
                <ActionButton
                  action="read"
                  onClick={() => {
                    setViewModal(!viewModal);
                    setActiveId(cellProps.row.original?.jobDescriptionId);
                    setActionType("View");
                  }}
                />
              </li>
              <li className="list-inline-item edit">
                <ActionButton
                  action="edit"
                  onClick={() => {
                    setAddModal(!addModal);
                    setActiveId(cellProps.row.original?.jobDescriptionId);
                    setActionType("Edit");
                  }}
                />
              </li>
              <li className="list-inline-item">
                <ActionButton
                  action="delete"
                  onClick={() => {
                    setActiveId(cellProps.row.original?.jobDescriptionId);
                    setShowDeleteModal(!showDeleteModal);
                  }}
                />
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  const downloadColumns = [
    {
      Header: "Job Description",
      accessor: "jobDescription",
      visible: true,
    },
  ];

  const exportHeader = [
    {
      header: "InternalJobtitle",
      dataKey: "InternalJobtitle",
    },
    {
      header: "Department",
      dataKey: "Department",
    },
    {
      header: "Level",
      dataKey: "Level",
    },
    {
      header: "JobDescription",
      dataKey: "JobDescription",
    },
    {
      header: "ReportJobTitile",
      dataKey: "reportJobTitile",
    },
    {
      header: "PayRange",
      dataKey: "PayRange",
    },
    {
      header: "Location",
      dataKey: "Location",
    },
    {
      header: "JobType",
      dataKey: "JobType",
    },
    {
      header: "WorkType",
      dataKey: "WorkType",
    },
    {
      header: "Education",
      dataKey: "Education",
    },
    {
      header: "Experience",
      dataKey: "Experience",
    },
    {
      header: "SkillsAbilities",
      dataKey: "SkillsAbilities",
    },
    {
      header: "Responsibilities",
      dataKey: "Responsibilities",
    },
    {
      header: "AboutCompany",
      dataKey: "AboutCompany",
    },
    {
      header: "AdditionalInformation",
      dataKey: "AdditionalInformation",
    },
    {
      header: "JobFamilyGroup",
      dataKey: "JobFamilyGroup",
    },
    {
      header: "JobFamily",
      dataKey: "JobFamily",
    },
  ];

  useEffect(() => {
    if (Object?.entries(storeCount?.list)?.length == 0) {
      dispatch(getDashboardCount());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      let query = getQueryString(queryParams);
      let body = `${userData?.userId}${query}`;
      dispatch(getJobDescriptions(body));
      setPageIndex(queryParams?.page);
    } else {
      dispatch(getJobDescriptions(userData?.userId));
    }
  }, []);

  useEffect(() => {
    if (Object.entries(filterQueryParams)?.length > 0) {
      let query = getQueryString({
        ...filterQueryParams,
        limit: storeJobDescription?.meta?.count,
      });
      let body = `${userData?.userId}${query}`;
      dispatch(getJobDescriptionsAll(body));
    } else if (
      !storeCount.loading &&
      storeCount?.list?.jobDescriptionCount &&
      Object.entries(filterQueryParams)?.length == 0
    ) {
      dispatch(
        getJobDescriptionsAll(
          `${userData?.userId}?limit=${storeCount?.list?.jobDescriptionCount}`
        )
      );
    }
  }, [storeCount, storeJobDescription?.meta]);

  const handlePageClick = (value) => {
    if (value) {
      setPageIndex(value);
      let queryObj = { ...queryParams, page: value, limit: 10 };
      let query = getQueryString(queryObj);
      let body = `${userData?.userId}${query}`;
      updateRouteWithParams(queryObj);
      dispatch(getJobDescriptions(body));
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deleteJobDescription({ jobDescriptionId: activeId })
      .then((res) => {
        if (res?.metadata?.status === 200) {
          toasterSuccess("Job Description Deleted Successfully!");
          dispatch(deleteAJobDescription(activeId));
          setLoading(false);
          setShowDeleteModal(!showDeleteModal);
        } else {
          toasterError("Unable to delete Job Description");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toasterError("Unable to delete Process");
      });
  };

  function handleAcceptedFiles(acceptedFiles) {
    const file = acceptedFiles[0];
    if (file) {
      const formData = new FormData();
      formData.append("userId", userData?.userId);
      Object.keys(file).forEach((key) => {
        formData.append(`jdxl`, file);
      });
      setLoader(true);
      PostJDFile(formData)
        .then((res) => {
          if (
            res?.metadata?.status === 200 ||
            res?.metadata?.message == "Success"
          ) {
            setImportModal(!importModal);
            setLoader(false);
            dispatch(getJobDescriptions(userData?.userId));
          }
        })
        .catch((error) => {
          console.error(error);
          setLoader(false);
        });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col md={12} lg={12} xxl={12}>
            <div className="">
              <TableContainer
                loading={storeJobDescription?.loading}
                columns={columns}
                data={storeJobDescription?.jobDescriptions}
                isGlobalFilter={true}
                columnFilter={true}
                isAddUserList={false}
                customPageSize={10}
                showPagination={false}
                divClass="table-responsive table-card mb-0 p-3"
                tableClass="align-middle table-wrap"
                theadClass="table-light"
                SearchPlaceholder="Search for..."
                addAction={() => {}}
                buttonAction={() => {
                  setAddModal(!addModal);
                  setActionType("Add");
                  setActiveId(null);
                }}
                buttonText={"Add Job Description"}
                showButton={true}
                exportButton={true}
                downloadFileName={"Job-Description"}
                importButton={true}
                importAction={() => {
                  setImportModal(!importModal);
                }}
                exportHeader={exportHeader}
                isDefaultPagination={false}
                pageTitle={"Job Description"}
                isPageHeader={true}
                isCustomPagination={true}
                customPaginationMeta={{
                  ...storeJobDescription?.meta,
                  pageRecords: storeJobDescription?.jobDescriptions?.length,
                  pageIndex: pageIndex,
                }}
                handlePageClick={handlePageClick}
                isFilterBtn={true}
                filterAction={() => {
                  setFilterModal(!filterModal);
                }}
                dispatchTableData={getJobDescriptions}
                downloadList={storeJobDescription?.allList}
                rowSelectionID={"jobDescriptionId"}
                filtersObj={filterQueryParams}
                downloadColumns={downloadColumns}
                isUserId={true}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Add
        isOpen={addModal}
        onCloseClick={() => setAddModal(false)}
        id={activeId}
        actionType={actionType}
      />
      <View
        isOpen={viewModal}
        onCloseClick={() => setViewModal(false)}
        id={activeId}
        actionType={actionType}
      />
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={setShowDeleteModal}
        onDeleteClick={handleDelete}
        activeDeleteId={activeId}
        loading={loading}
      />
      <FileImport
        show={importModal}
        onCloseClick={setImportModal}
        onClick={() => {}}
        handleAcceptedFiles={handleAcceptedFiles}
        loading={loader}
      />
      <JDFilters show={filterModal} onCloseClick={toggleFilterModal} />
      {storeJobDescription?.loading && <Loader />}
    </React.Fragment>
  );
};
export default JobDescription;
