import { useLocation } from "react-router-dom";

const getQueryString = (values) => {
  let queryString = Object.keys(values)
    .map((key) => {
      const value = values[key];
      if (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        value.length != 0
      ) {
        if (Array.isArray(value)) {
          return `${encodeURIComponent(key)}=${value
            .map((v) => encodeURIComponent(v))
            .join(",")}`;
        } else if (
          key == "assignedTo" ||
          key == "currency" ||
          key == "customerId" ||
          key == "salesPersonId"
        ) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            value?.value
          )}`;
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      }
      return null;
    })
    .filter((param) => param !== null)
    .join("&");

  return "?" + queryString;
};
const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params = {};
  queryParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

const getQueryValueFromString = (search,name) => {
  const queryParams = new URLSearchParams(search);
  const params = {};
  queryParams.forEach((value, key) => {
    params[key] = value;
  });
  return params[name];
};

function parseQueryParams() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const result = {};
  for (const [key, value] of params.entries()) {
    const isArrayValue = value.includes(",");
    if (
      isArrayValue ||
      key == "leadStatus" ||
      key == "leadValue" ||
      key == "customerStatus" ||
      key == "customerType" ||
      key == "estimationStatus" ||
      key == "proposalStatus" ||
      key == "related" ||
      key == "invoiceStatus"
    ) {
      result[key] = value.split(",").map((item) => item.trim());
    } else {
      result[key] = value;
    }
  }
  return result;
}
const updateQueryParams = (newParams) => {
  const searchParams = new URLSearchParams(location.search);
  for (const paramKey of Object.keys(newParams)) {
    if (newParams[paramKey] === undefined) {
      searchParams.delete(paramKey);
    } else {
      searchParams.set(paramKey, newParams[paramKey]);
    }
  }
  const newQueryString = searchParams.toString();
  navigate(`${location.pathname}${newQueryString ? `?${newQueryString}` : ""}`);
};

const useRemoveQueryParam = (paramKey) => {
  const queryParams = parseQueryParams();
  const newParams = { ...queryParams };
  delete newParams[paramKey];
  updateQueryParams(newParams);
};

const getURLObject = (obj) => {
  const urlObj = {};
  for (const key in obj) {
    if (
      key == "assignedTo" ||
      key == "currency" ||
      key == "customerId" ||
      key == "salesPersonId"
    ) {
      urlObj[key] = obj[key]?.label;
    } else {
      urlObj[key] = obj[key];
    }
  }
  return urlObj;
};
function removeUndefinedValues(obj) {
  const filteredObject = {};

  for (const key in obj) {
    if (obj[key] !== undefined || obj[key] !== null) {
      filteredObject[key] = obj[key];
    }
  }

  return filteredObject;
}
function clearAllQueryParams() {
  var currentURL = window.location.href;
  var baseURL = currentURL.split("?")[0];
  history.replaceState(null, "", baseURL);
}
export {
  getQueryString,
  useQueryParams,
  parseQueryParams,
  updateQueryParams,
  useRemoveQueryParam,
  getURLObject,
  clearAllQueryParams,
  removeUndefinedValues,
  getQueryValueFromString
};
