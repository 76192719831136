import { createSlice } from "@reduxjs/toolkit";
import { getExternalSurveyData, getExternalSurveyDataByID, getSurveyData } from "./thunk";

export const initialState = {
  SurveyData: {
    List: [],
    error: {},
    loading: false,
  },
  SurveyDataById: {
    List: {},
    error: {},
    loading: false,
  },
};

const SurveyDataSlice = createSlice({
  name: "SurveyData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExternalSurveyData.pending, (state, action) => {
      state.SurveyData.List = [];
      state.SurveyData.loading = true;
    });
    builder.addCase(getExternalSurveyData.fulfilled, (state, action) => {
      state.SurveyData.List = action.payload;
      state.SurveyData.loading = false;
    });
    builder.addCase(getExternalSurveyData.rejected, (state, action) => {
      state.SurveyData.error = action?.error?.message || null;
      state.SurveyData.loading = false;
    });
    builder.addCase(getExternalSurveyDataByID.pending, (state, action) => {
      state.SurveyDataById.List = {};
      state.SurveyDataById.loading = true;
    });
    builder.addCase(getExternalSurveyDataByID.fulfilled, (state, action) => {
      state.SurveyDataById.List = action.payload;
      state.SurveyDataById.loading = false;
    });
    builder.addCase(getExternalSurveyDataByID.rejected, (state, action) => {
      state.SurveyDataById.error = action?.error?.message || null;
      state.SurveyDataById.loading = false;
    });
  },
});

export const {} = SurveyDataSlice.actions;

export default SurveyDataSlice.reducer;
