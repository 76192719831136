import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllPayzones as getAllPayzonesApi,
  getPayzoneByID as getPayzoneByIDApi,
} from "../../helpers/custom_backend_helper";

export const getPayzones = createAsyncThunk(
  "payzone/getAllPayzones",
  async () => {
    try {
      const response = await getAllPayzonesApi();
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getPayzoneById = createAsyncThunk(
  "payzone/getAllPayzoneById",
  async (id) => {
    try {
      const response = await getPayzoneByIDApi(id);
      const data = await response.data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
