import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import {
  EmploymentTypeOptions,
  workModeOptions,
  countriesList,
} from "../../utils/dataOptions";
import {
  buildMultiOptions,
  buildOptions,
  getOption,
  getOptionByValue,
  getValues,
} from "../../utils/dataFormat";
import { Creatable } from "../../Components/Custom/CreatableSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneralLevels,
  getGeneralLevelsByUser,
  getJobDescriptionById,
} from "../../slices/thunks";
import { getUserData } from "../../utils/auth";
import { isEmpty } from "lodash";
import {
  postJobDescription,
  putJobDescription,
} from "../../helpers/custom_backend_helper";
import { IdView } from "../../Components/Custom/IdView";
import {
  pushJobDescription,
  updateJobDescription,
} from "../../slices/jobDescription/reducer";
import ColumnLoader from "../../Components/Custom/ColumnLoader";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { toasterError, toasterSuccess } from "../../utils/toaster";
import * as Yup from "yup";
import SimpleBar from "simplebar-react";

const userData = getUserData();

const defaultValues = {
  jobDescription: "",
  internalJobtitle: "",
  department: "",
  payRange: "",
  location: "",
  jobType: "",
  reportJobTitile: "",
  workType: "",
  aboutCompany: "",
  skillsAbilities: [],
  education: "",
  experience: "",
  responsibilities: "",
  additionalInformation: "",
  location: "",
  level: "",
  userId: userData?.userId,
};

export const Add = ({ onCloseClick, isOpen, id, actionType }) => {
  const dispatch = useDispatch();
  const storeJobDescription = useSelector((state) => state.JobDescription);
  const storeJobDescriptionById =
    storeJobDescription?.jobDescriptionById?.jobDescription;
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [parentValue, setParentValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parentInputValue, setParentInputValue] = useState("");
  const storeLevel = useSelector((state) => state?.Levels);
  const storeLevels = useSelector((state) => state.GeneralLevels);

  useEffect(() => {
    if (actionType == "Edit" && id && isOpen) {
      dispatch(getJobDescriptionById(id));
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getGeneralLevels());
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      id &&
      actionType == "Edit" &&
      Object.entries(storeJobDescriptionById)?.length > 0
    ) {
      setParentValue(
        buildMultiOptions(storeJobDescriptionById?.skillsAbilities) || []
      );
      let activeValues = {
        ...storeJobDescriptionById,
        jobType: getOptionByValue(storeJobDescriptionById?.jobType),
        workType: getOptionByValue(storeJobDescriptionById?.workType),
        level: getOptionByValue(storeJobDescriptionById?.level),
        country: getOption(
          storeJobDescriptionById?.country?.name,
          storeJobDescriptionById?.country?.code
        ),
      };
      setInitialValues(activeValues);
    } else {
      setInitialValues(defaultValues);
      setParentValue([]);
    }
  }, [id, storeJobDescriptionById]);

  const validateSchema = Yup.object().shape({
    internalJobtitle: Yup.mixed().required(
      "Internal Job title group is required"
    ),
    department: Yup.mixed().required("Department is required"),
    level: Yup.mixed().required("Job Level is required"),
    reportJobTitile: Yup.mixed().required("Report to Job Title is required"),
    workType: Yup.mixed().required("Mode of Work is required"),
    jobType: Yup.mixed().required("Employement type group is required"),
    education: Yup.mixed().required("Education is required"),
    experience: Yup.mixed().required("Experience is required"),
    payRange: Yup.mixed().required("Expected Pay is required"),
    location: Yup.mixed().required("Location is required"),
    skillsAbilities: Yup.array()
      .of(Yup.mixed())
      .required("Skills & Abilities are required")
      .min(1, "Atleast one Skill/Ability is required"),
    aboutCompany: Yup.mixed().required("About Company is required"),
    jobDescription: Yup.mixed().required("Job Description is required"),
    responsibilities: Yup.mixed().required("Responsibilties is required"),
    additionalInformation: Yup.mixed().required(
      "Additional Information is required"
    ),
    country: Yup.mixed().required("Country is required"),
  });

  const handleSubmit = (values) => {
    let body = {
      ...values,
      level: values?.level?.label,
      jobType: values?.jobType?.label,
      status: "Draft",
      workType: values?.workType?.label,
      userId: userData?.userId,
      reportJobTitile: values?.reportJobTitile,
      skillsAbilities: getValues(parentValue),
      country: { code: values?.country?.value, name: values?.country?.label },
    };
    const request = id ? putJobDescription : postJobDescription;
    const successMessage = `Job Description ${
      id ? "Updated" : "Added"
    } Successfully!`;
    const errorMessage = `Unable to ${id ? "update" : "add"} Job Description !`;
    setLoading(true);
    request(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          const action =
            id && actionType == "Edit"
              ? updateJobDescription(res.data)
              : pushJobDescription({
                  ...res.data,
                });
          dispatch(action);
          toasterSuccess(successMessage);
          setLoading(false);
        } else {
          toasterError(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        onCloseClick();
      });
  };

  return (
    <div>
      {/* <Offcanvas
        direction="end"
        isOpen={isOpen}
        id="offcanvas-lg"
        toggle={onCloseClick}
        className="border-0"
      >
        <OffcanvasHeader
          className="d-flex align-items-center bg-primary p-3 offcanvas-header-dark"
          toggle={onCloseClick}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-white d-flex align-items-center">
              {id ? (
                <>
                  EDIT - Job Description{" "}
                  <IdView id={storeJobDescriptionById?.jobDescriptionId} />
                </>
              ) : (
                "ADD - Job Description"
              )}
            </span>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody
          className={
            storeJobDescription?.jobDescriptionById?.loading || loading
              ? "loader-container p-0"
              : " p-0"
          }
        > */}
      <Modal
        fade={true}
        isOpen={isOpen}
        toggle={onCloseClick}
        centered={true}
        size="lg"
        modalClassName="flip"
      >
        {" "}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validateSchema}
        >
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <ModalHeader
                  className="bg-secondary p-3 px-4"
                  toggle={() => onCloseClick(!isOpen)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="m-0 me-2 text-primary d-flex align-items-center">
                      {id ? (
                        <>
                          EDIT - Job Description{" "}
                          <IdView
                            id={storeJobDescriptionById?.jobDescriptionId}
                          />
                        </>
                      ) : (
                        "ADD - Job Description"
                      )}
                    </span>
                  </div>
                </ModalHeader>
                <SimpleBar className="modalbody-scroll">
                  <ModalBody className="p-4">
                    <div className="">
                      <Row>
                        <Col md={4} className="mb-4">
                          <Label>Internal Job Title</Label>
                          <Input
                            className="form-control"
                            id="internalJobtitle"
                            name="internalJobtitle"
                            type="text"
                            value={values?.internalJobtitle}
                            onChange={(event) => {
                              setFieldValue(
                                "internalJobtitle",
                                event.target.value
                              );
                            }}
                            onBlur={() => {
                              setFieldTouched("internalJobtitle", true);
                              setFieldError("internalJobtitle", true);
                            }}
                          />
                          <ErrorMessage
                            name="internalJobtitle"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Department</Label>
                          <Input
                            className="form-control"
                            id="department"
                            name="department"
                            type="text"
                            value={values?.department}
                            onBlur={() => {
                              setFieldTouched("department", true);
                              setFieldError("department", true);
                            }}
                            onChange={(event) => {
                              setFieldValue("department", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="department"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Job Level</Label>
                          <ReactSelect
                            id="level"
                            className=" mb-0"
                            name="level"
                            value={values?.level}
                            onChange={(event) => {
                              setFieldValue("level", event);
                            }}
                            options={buildOptions(
                              storeLevels?.levels,
                              "levelCode",
                              "levelCode"
                            )}
                            onBlur={() => {
                              setFieldTouched("level", true);
                              setFieldError("level", true);
                            }}
                          />
                          <ErrorMessage
                            name="level"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Reports to Job title</Label>
                          <Input
                            id="reportJobTitile"
                            name="reportJobTitile"
                            type="text"
                            className="form-control"
                            value={values?.reportJobTitile}
                            onBlur={() => {
                              setFieldTouched("reportJobTitile", true);
                              setFieldError("reportJobTitile", true);
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                "reportJobTitile",
                                event.target.value
                              );
                            }}
                          />
                          <ErrorMessage
                            name="reportJobTitile"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Mode of Work</Label>
                          <ReactSelect
                            id="workType"
                            className=" mb-0"
                            name="workType"
                            value={values?.workType}
                            onChange={(event) => {
                              setFieldValue("workType", event);
                            }}
                            options={workModeOptions}
                            onBlur={() => {
                              setFieldTouched("workType", true);
                              setFieldError("workType", true);
                            }}
                          />
                          <ErrorMessage
                            name="workType"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Employment Type</Label>
                          <ReactSelect
                            id="jobType"
                            className=" mb-0"
                            name="jobType"
                            value={values?.jobType}
                            onChange={(event) => {
                              setFieldValue("jobType", event);
                            }}
                            options={EmploymentTypeOptions}
                            onBlur={() => {
                              setFieldTouched("jobType", true);
                              setFieldError("jobType", true);
                            }}
                          />
                          <ErrorMessage
                            name="jobType"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>

                        <Col md={4} className="mb-4">
                          <Label>Education</Label>
                          <Input
                            className="form-control"
                            id="education"
                            name="education"
                            type="text"
                            value={values?.education}
                            onBlur={() => {
                              setFieldTouched("education", true);
                              setFieldError("education", true);
                            }}
                            onChange={(event) => {
                              setFieldValue("education", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="education"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Experience</Label>
                          <Input
                            className="form-control"
                            id="experience"
                            name="experience"
                            type="text"
                            value={values?.experience}
                            onBlur={() => {
                              setFieldTouched("experience", true);
                              setFieldError("experience", true);
                            }}
                            onChange={(event) => {
                              setFieldValue("experience", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="experience"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Expected Pay range</Label>
                          <Input
                            className="form-control"
                            id="payRange"
                            name="payRange"
                            value={values?.payRange}
                            onBlur={() => {
                              setFieldTouched("payRange", true);
                              setFieldError("payRange", true);
                            }}
                            onChange={(event) => {
                              setFieldValue("payRange", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="payRange"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={8} className="mb-4">
                          <Label>Location</Label>
                          <Input
                            className="form-control"
                            id="location"
                            name="location"
                            type="text"
                            value={values?.location}
                            onBlur={() => {
                              setFieldTouched("location", true);
                              setFieldError("location", true);
                            }}
                            onChange={(event) => {
                              setFieldValue("location", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="location"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={4} className="mb-4">
                          <Label>Country</Label>
                          <ReactSelect
                            id="groupId"
                            className=" mb-0"
                            name="groupId"
                            value={values?.country}
                            options={countriesList}
                            onChange={(event) => {
                              console.log(event, "event");
                              setFieldValue("country", event);
                            }}
                            onBlur={() => {
                              setFieldTouched("country", true);
                              setFieldError("country", true);
                            }}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={12} className="mb-4">
                          <Label>Required Skills & Abilities</Label>
                          <Creatable
                            value={parentValue}
                            setValue={setParentValue || []}
                            setInputValue={setParentInputValue}
                            onBlur={() => {
                              setFieldError("skillsAbilities", true);
                              setFieldTouched("skillsAbilities", true);
                            }}
                            id="skillsAbilities"
                            setFieldValue={setFieldValue}
                            name={"Skills"}
                          />
                          <ErrorMessage
                            name="skillsAbilities"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>

                        <Col md={12} className="mb-4">
                          <Label>Brief about the Company </Label>
                          <TextareaAutosize
                            id="aboutCompany"
                            name="aboutCompany"
                            minRows={3}
                            maxRows={8}
                            className="form-control "
                            value={values?.aboutCompany}
                            onBlur={() => {
                              setFieldTouched("aboutCompany", true);
                              setFieldError("aboutCompany", true);
                            }}
                            onChange={(event) => {
                              setFieldValue("aboutCompany", event.target.value);
                            }}
                          />
                          <ErrorMessage
                            name="aboutCompany"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={12} className="mb-4">
                          <Label>Job Description / Summary about the job</Label>
                          <TextareaAutosize
                            id="jobDescription"
                            name="jobDescription"
                            minRows={3}
                            maxRows={8}
                            className="form-control"
                            value={values?.jobDescription}
                            onBlur={() => {
                              setFieldTouched("jobDescription", true);
                              setFieldError("jobDescription", true);
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                "jobDescription",
                                event.target.value
                              );
                            }}
                          />
                          <ErrorMessage
                            name="jobDescription"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={12} className="mb-4">
                          <Label>Responsibilities</Label>
                          <TextareaAutosize
                            id="responsibilities"
                            name="responsibilities"
                            minRows={3}
                            maxRows={8}
                            className="form-control "
                            value={values?.responsibilities}
                            onBlur={() => {
                              setFieldTouched("responsibilities", true);
                              setFieldError("responsibilities", true);
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                "responsibilities",
                                event.target.value
                              );
                            }}
                          />
                          <ErrorMessage
                            name="responsibilities"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                        <Col md={12} className="">
                          <Label>Additional Information</Label>
                          <TextareaAutosize
                            id="additionalInformation"
                            name="additionalInformation"
                            minRows={3}
                            maxRows={8}
                            className="form-control "
                            placeholder="such as Benefits offered, Company culture, Physical attributes etc."
                            value={values?.additionalInformation}
                            onBlur={() => {
                              setFieldTouched("additionalInformation", true);
                              setFieldError("additionalInformation", true);
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                "additionalInformation",
                                event.target.value
                              );
                            }}
                          />
                          <ErrorMessage
                            name="additionalInformation"
                            component="div"
                            className="error text-danger"
                          />
                        </Col>
                      </Row>
                    </div>{" "}
                    {/* {(storeJobDescription?.jobDescriptionById?.loading || loading) && (
            <ColumnLoader />
          )} */}
                  </ModalBody>
                </SimpleBar>
                <ModalFooter>
                  <div className=" float-end ">
                    <Button
                      className="btn theme-cancel-btn mx-2 px-4"
                      color="white"
                      onClick={(e) => {
                        onCloseClick();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn px-4"
                      color="secondary"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(values);
                      }}
                      disabled={
                        isSubmitting || !isEmpty(errors) || !dirty || loading
                      }
                    >
                      Save
                    </Button>
                  </div>
                </ModalFooter>{" "}
              </>
            );
          }}
        </Formik>{" "}
      </Modal>
    </div>
  );
};
export default Add;
