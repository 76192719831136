import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  jobCode: Yup.string().required('Job Code is required'),
  providerName: Yup.string().required('Provider Name is required'),
  dataCut: Yup.string().required('Data Cut is required'),
  jobTitle: Yup.string().required('Job Title is required'),
  jobLevel: Yup.string().required('Job Level is required'),
  jobDescription: Yup.string().required('Job Description is required'),
  jobFamilyGroup: Yup.string().required('Job Family Group is required'),
  jobFamily: Yup.string().required('Job Family is required'),
  jobArea: Yup.string().required('Job Area is required'),
  jobCategory: Yup.string().required('Job Category is required'),
  effectiveDate: Yup.string().required('Effective Date is required'),
  countryName: Yup.string().required('Country Name is required'),
  peerGroupName: Yup.string().required('Peer Group Name is required'),
  monetaryUnit: Yup.string().required('Monetary Unit is required'),
  salaryCFYAvg: Yup.string().required('Salary CFY Avg is required'),
  undefined: Yup.string().required('Undefined is required'),
  '10thPercentile': Yup.object().shape({
    salaryCFYAvg: Yup.string().required('Salary CFY Avg is required'),
    fixedCompCFYAll: Yup.string().required('Fixed Comp CFY All is required'),
    targetIncentivesReporting: Yup.string().required('Target Incentives Reporting is required'),
    targetIncentivesPercent: Yup.string().required('Target Incentives Percent is required'),
    CFYBaseTargetAll: Yup.string().required('CFY Base Target All is required'),
    fixedCompensationCFYAll: Yup.string().required('Fixed Compensation CFY All is required'),
    newHireGuidelines: Yup.string().required('New Hire Guidelines is required'),
    newHireActualGrants: Yup.string().required('New Hire Actual Grants is required'),
    ongoingGuidelines: Yup.string().required('Ongoing Guidelines is required'),
    ongoingActualGrants: Yup.string().required('Ongoing Actual Grants is required'),
    newHireActualGrantsOptions: Yup.string().required('New Hire Actual Grants Options is required'),
    ongoingActualGrantsOptions: Yup.string().required('Ongoing Actual Grants Options is required'),
    totalDirectCompTargetAll: Yup.string().required('Total Direct Comp Target All is required'),
    totalDirectCompActualAll: Yup.string().required('Total Direct Comp Actual All is required'),
  }),
  '25thPercentile': Yup.object().shape({
    salaryCFYAvg: Yup.string().required('Salary CFY Avg is required'),
    fixedCompCFYAll: Yup.string().required('Fixed Comp CFY All is required'),
    targetIncentivesReporting: Yup.string().required('Target Incentives Reporting is required'),
    targetIncentivesPercent: Yup.string().required('Target Incentives Percent is required'),
    CFYBaseTargetAll: Yup.string().required('CFY Base Target All is required'),
    fixedCompensationCFYAll: Yup.string().required('Fixed Compensation CFY All is required'),
    newHireGuidelines: Yup.string().required('New Hire Guidelines is required'),
    newHireActualGrants: Yup.string().required('New Hire Actual Grants is required'),
    ongoingGuidelines: Yup.string().required('Ongoing Guidelines is required'),
    ongoingActualGrants: Yup.string().required('Ongoing Actual Grants is required'),
    newHireActualGrantsOptions: Yup.string().required('New Hire Actual Grants Options is required'),
    ongoingActualGrantsOptions: Yup.string().required('Ongoing Actual Grants Options is required'),
    totalDirectCompTargetAll: Yup.string().required('Total Direct Comp Target All is required'),
    totalDirectCompActualAll: Yup.string().required('Total Direct Comp Actual All is required'),
  }),
  '50thPercentile': Yup.object().shape({
    salaryCFYAvg: Yup.string().required('Salary CFY Avg is required'),
    fixedCompCFYAll: Yup.string().required('Fixed Comp CFY All is required'),
    targetIncentivesReporting: Yup.string().required('Target Incentives Reporting is required'),
    targetIncentivesPercent: Yup.string().required('Target Incentives Percent is required'),
    CFYBaseTargetAll: Yup.string().required('CFY Base Target All is required'),
    fixedCompensationCFYAll: Yup.string().required('Fixed Compensation CFY All is required'),
    newHireGuidelines: Yup.string().required('New Hire Guidelines is required'),
    newHireActualGrants: Yup.string().required('New Hire Actual Grants is required'),
    ongoingGuidelines: Yup.string().required('Ongoing Guidelines is required'),
    ongoingActualGrants: Yup.string().required('Ongoing Actual Grants is required'),
    newHireActualGrantsOptions: Yup.string().required('New Hire Actual Grants Options is required'),
    ongoingActualGrantsOptions: Yup.string().required('Ongoing Actual Grants Options is required'),
    totalDirectCompTargetAll: Yup.string().required('Total Direct Comp Target All is required'),
    totalDirectCompActualAll: Yup.string().required('Total Direct Comp Actual All is required'),
  }),
  '75thPercentile': Yup.object().shape({
    salaryCFYAvg: Yup.string().required('Salary CFY Avg is required'),
    allowancesReporting: Yup.string().required('Allowances Reporting is required'),
    fixedCompCFYAll: Yup.string().required('Fixed Comp CFY All is required'),
    targetIncentivesReporting: Yup.string().required('Target Incentives Reporting is required'),
    targetIncentivesPercent: Yup.string().required('Target Incentives Percent is required'),
    CFYBaseTargetAll: Yup.string().required('CFY Base Target All is required'),
    fixedCompensationCFYAll: Yup.string().required('Fixed Compensation CFY All is required'),
    newHireGuidelines: Yup.string().required('New Hire Guidelines is required'),
    newHireActualGrants: Yup.string().required('New Hire Actual Grants is required'),
    ongoingGuidelines: Yup.string().required('Ongoing Guidelines is required'),
    ongoingActualGrants: Yup.string().required('Ongoing Actual Grants is required'),
    newHireActualGrantsOptions: Yup.string().required('New Hire Actual Grants Options is required'),
    ongoingActualGrantsOptions: Yup.string().required('Ongoing Actual Grants Options is required'),
    totalDirectCompTargetAll: Yup.string().required('Total Direct Comp Target All is required'),
    totalDirectCompActualAll: Yup.string().required('Total Direct Comp Actual All is required'),
  }),
  '90thPercentile': Yup.object().shape({
    salaryCFYAvg: Yup.string().required('Salary CFY Avg is required'),
    allowancesReporting: Yup.string().required('Allowances Reporting is required'),
    fixedCompCFYAll: Yup.string().required('Fixed Comp CFY All is required'),
    targetIncentivesReporting: Yup.string().required('Target Incentives Reporting is required'),
    targetIncentivesPercent: Yup.string().required('Target Incentives Percent is required'),
    CFYBaseTargetAll: Yup.string().required('CFY Base Target All is required'),
    fixedCompensationCFYAll: Yup.string().required('Fixed Compensation CFY All is required'),
    newHireGuidelines: Yup.string().required('New Hire Guidelines is required'),
    newHireActualGrants: Yup.string().required('New Hire Actual Grants is required'),
    ongoingGuidelines: Yup.string().required('Ongoing Guidelines is required'),
    ongoingActualGrants: Yup.string().required('Ongoing Actual Grants is required'),
    newHireActualGrantsOptions: Yup.string().required('New Hire Actual Grants Options is required'),
    ongoingActualGrantsOptions: Yup.string().required('Ongoing Actual Grants Options is required'),
    totalDirectCompTargetAll: Yup.string().required('Total Direct Comp Target All is required'),
    totalDirectCompActualAll: Yup.string().required('Total Direct Comp Actual All is required'),
  }),
});

export default validationSchema;
