import React from "react";
import Export from "../Custom/Export";
import { parseQueryParams } from "../../utils/queryParams";

export const PageHeader = ({
  pageTitle,
  buttonText,
  isAddBtn,
  isImportBtn,
  importAction,
  buttonAction,
  exportButton,
  visibleColumns,
  data,
  downloadFileName,
  exportHeader,
  isCaption,
  captionText,
  selectedRows,
  rowSelectionID,
  selectAllRows,
  isFilterBtn,
  filterAction,
}) => {
  const queryParams = parseQueryParams();
  const { page, limit, ...filterQueryParams } = queryParams;
  const selectedRowsData =
    selectedRows?.length > 0
      ? data?.filter((ele) => selectedRows?.includes(ele[rowSelectionID]))
      : [];

  return (
    <div className="page-header">
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <h4 className="text-uppercase mb-0 text-primary">{pageTitle}</h4>
        </div>
        <div className="d-flex align-items-center">
          {isImportBtn && (
            <button
              type="button"
              className={"btn btn-light rounded-pill add-btn  px-3 me-2 "}
              id="create-btn"
              onClick={importAction}
            >
              <i className="bx bx-import me-2"></i>
              Import File
            </button>
          )}
          {isFilterBtn && (
            <div
              className="icon-container text-nowrap d-flex align-items-center bg-light p-2 px-3 rounded-pill me-2"
              role="button"
              onClick={filterAction}
            >
              <span className="">
                <span className="fw-bold me-1 text-primary">
                  {Object?.entries(filterQueryParams)?.length}{" "}
                </span>
                {"  "}Filters
              </span>
            </div>
          )}
          {exportButton && (
            <Export
              columns={visibleColumns}
              list={
                !selectAllRows && selectedRows?.length == 0
                  ? data
                  : selectedRowsData?.length > 0
                  ? selectedRowsData
                  : data
              }
              fileName={downloadFileName}
              exportHeader={exportHeader}
            />
          )}
          {isAddBtn && (
            <button
              type="button"
              className={"btn btn-primary rounded-pill add-btn  px-3"}
              id="create-btn"
              onClick={buttonAction}
            >
              <i className="ri-add-line align-bottom me-1"></i>
              {buttonText}
            </button>
          )}
        </div>
      </div>
      {isCaption && <p className="caption-text">{captionText}</p>}
    </div>
  );
};
export default PageHeader;
