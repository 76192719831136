// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// export const handleDownloadPDF = (element, isOpen, name) => {
//     const offCanvasElement = document.getElementById(element);

//     if (offCanvasElement && isOpen) {
//         const totalHeight = offCanvasElement.scrollHeight;
//         html2canvas(offCanvasElement, {
//             windowHeight: totalHeight,
//             scrollY: window.scrollY,
//             scrollX: window.scrollX,
//             width: offCanvasElement.clientWidth,
//             height: totalHeight,
//         })
//             .then((canvas) => {
//                 const imgData = canvas.toDataURL("image/png");
//                 const pdf = new jsPDF();
//                 const imgWidth = 210;
//                 const imgHeight = (canvas.height * imgWidth) / canvas.width;
//                 pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
//                 pdf.save(`${name}.pdf`);
//             })
//             .catch((error) => {
//                 console.error("Error capturing Offcanvas content:", error);
//             });
//     }
// };

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import companyLogo from "../../assets/images/logo-light.png";

const header = (doc, name) => {
  doc.setFillColor("#08569a"); // Header background color
  doc.rect(0, 0, doc.internal.pageSize.width, 20, "F");

  // Add logo on the left
  const logoImg = new Image();
  logoImg.src = companyLogo; // Replace with the path to your logo
  doc.addImage(logoImg, "PNG", 10, 3, 50, 13);

  // Page Name
  const pageName = name;
  doc.setTextColor("#FFFFFF");
  doc.setFontSize(10);
  doc.text(pageName, doc.internal.pageSize.width - 10, 8, { align: "right" });

  // Date
  const date = `Date: ${new Date().toLocaleDateString()}`;
  doc.setFontSize(8);
  doc.text(date, doc.internal.pageSize.width - 10, 15, { align: "right" });
};

export const handleDownloadPDF = async (element, isOpen, name) => {
  const offCanvasElement = document.getElementById(element);

  if (offCanvasElement && isOpen) {
    const totalHeight = offCanvasElement.scrollHeight;

    try {
      const canvas = await html2canvas(offCanvasElement, {
        scale: 2, // Double the resolution of the canvas
        windowHeight: totalHeight,
        scrollY: window.scrollY,
        scrollX: window.scrollX,
        width: offCanvasElement.clientWidth,
        height: totalHeight,
      });

      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF("p", "mm", "a4"); // Create a new PDF

      let heightLeft = imgHeight;
      let position = 0;
      let pageCount = 1;

      while (heightLeft > 0) {
        // Add a new page for every iteration except the first
        if (position !== 0) {
          pdf.addPage();
        }

        // Add the header for every page
        header(pdf, name);

        const pageCanvas = document.createElement("canvas");
        const pageContext = pageCanvas.getContext("2d");

        pageCanvas.width = canvas.width;
        pageCanvas.height = canvas.height;

        // Draw the part of the original canvas that corresponds to the current page
        pageContext.drawImage(
          canvas,
          0,
          position, // Source x, y
          canvas.width,
          (pageHeight * canvas.width) / imgWidth, // Source width, height
          0,
          0, // Destination x, y
          canvas.width,
          (pageHeight * canvas.width) / imgWidth // Destination width, height
        );

        const pageData = canvas.toDataURL("image/png", 1.0); // Use full quality for PNG

        // Add content after the header
        pdf.addImage(pageData, "PNG", 0, 20, imgWidth, pageHeight - 20); // Adjust position for the header height

        heightLeft -= pageHeight;
        position += (pageHeight * canvas.width) / imgWidth;
        pageCount++;
      }

      // Save the PDF
      pdf.save(`${name}.pdf`);
    } catch (error) {
      console.error("Error capturing Offcanvas content:", error);
    }
  }
};
