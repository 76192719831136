import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { buildOptions, getOptionByValue } from "../../utils/dataFormat";
import { Formik } from "formik";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import {
  getGeneralLevels,
  getJobArchitectures,
  getJobDescriptions,
  getMarketData,
} from "../../slices/thunks";
import { getUserData } from "../../utils/auth";
import SimpleBar from "simplebar-react";

const defaultValues = {
  jobTitle: "",
  positionTitle: "",
  level: "",
};

const MarketPricingFilters = ({ show, onCloseClick }) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = parseQueryParams();
  const storeJobDescription = useSelector((state) => state.JobDescription);
  const storeLevels = useSelector((state) => state.GeneralLevels);
  const userData = getUserData();
  const storeJobArchitecture = useSelector((state) => state.JobArchitecture);

  useEffect(() => {
    if (storeLevels?.levels?.length == 0) {
      dispatch(getGeneralLevels());
    }
    if (storeJobDescription?.jobDescriptions?.length == 0) {
      dispatch(getJobDescriptions(userData?.userId));
    }
    if (storeJobArchitecture?.jobArchitectures?.length == 0) {
      dispatch(getJobArchitectures(userData?.userId));
    }
    if (Object?.entries(queryParams).length > 0 && show) {
      setInitialValues({ ...initialValues, ...queryParams });
    } else setInitialValues(defaultValues);
  }, [show]);

  const applyFilters = (values) => {
    let data = {
      jobTitle: values?.jobTitle,
      positionTitle: values?.positionTitle,
      level: values?.level,
    };
    const queryString = getQueryString(data);
    const urlWithParams = `/market-pricing${queryString}`;
    navigate(urlWithParams);
    dispatch(getMarketData(`${queryString}`));
    onCloseClick();
  };

  const clearFilters = (e) => {
    e.preventDefault();
    onCloseClick();
    navigate("/market-pricing");
    dispatch(getMarketData(""));
    setInitialValues(initialValues);
    setFilterValues({});
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader
        className="bg-soft-primary"
        toggle={() => onCloseClick(!show)}
      >
        <h5 className=" text-primary mb-0 "> FILTERS - MarketPricing</h5>
      </OffcanvasHeader>

      <OffcanvasBody>
        <Formik enableReinitialize initialValues={initialValues}>
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {
            return (
              <>
                <Row>
                  <SimpleBar className="modalbody-scroll">
                    <Col md={12} className="mb-4">
                      <Label>Position Title</Label>
                      <ReactSelect
                        id="positionTitle"
                        className=" mb-0"
                        name="positionTitle"
                        value={
                          values?.positionTitle
                            ? getOptionByValue(values?.positionTitle)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("positionTitle", event?.label);
                        }}
                        options={buildOptions(
                          storeJobArchitecture?.jobArchitectures,
                          "positionTitle",
                          "positionTitle"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Job Title</Label>
                      <ReactSelect
                        id="jobTitle"
                        className=" mb-0"
                        name="jobTitle"
                        value={
                          values?.jobTitle
                            ? getOptionByValue(values?.jobTitle)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("jobTitle", event?.label);
                        }}
                        options={buildOptions(
                          storeJobDescription?.jobDescriptions,
                          "internalJobtitle",
                          "internalJobtitle"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Job Level</Label>
                      <ReactSelect
                        id="level"
                        className=" mb-0"
                        name="level"
                        value={
                          values?.level ? getOptionByValue(values?.level) : null
                        }
                        onChange={(event) => {
                          setFieldValue("level", event?.label);
                        }}
                        options={buildOptions(
                          storeLevels?.levels,
                          "levelCode",
                          "levelCode"
                        )}
                      />
                    </Col>
                  </SimpleBar>
                </Row>

                <div className=" d-flex justify-content-end position-absolute bottom-0 py-4 pe-4 bg-white w-100">
                  <Button
                    className="btn theme-cancel-btn me-2 px-4 w-100"
                    color="white"
                    onClick={(e) => {
                      clearFilters(e);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn px-4 w-100 me-3"
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      applyFilters(values);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default MarketPricingFilters;
