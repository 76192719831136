import { createSlice } from "@reduxjs/toolkit";
import { getLevelMapping, getLevelsByUser } from "./thunk";

export const initialState = {
    levels: [],
    error: {},
    loading: false,
    levelsByUser: { levels: [], error: {}, loading: false },
};

const LevelsSlice = createSlice({
    name: "Levels",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLevelMapping.pending, (state, action) => {
            state.levels = [];
            state.loading = true;
        });
        builder.addCase(getLevelMapping.fulfilled, (state, action) => {
            state.levels = action.payload;
            state.loading = false;
        });
        builder.addCase(getLevelMapping.rejected, (state, action) => {
            state.error = action?.error?.message || null;
            state.loading = false;
        });
        builder.addCase(getLevelsByUser.pending, (state, action) => {
            state.levelsByUser.levels = [];
            state.levelsByUser.loading = true;
        });
        builder.addCase(getLevelsByUser.fulfilled, (state, action) => {
            state.levelsByUser.levels = action.payload;
            state.levelsByUser.loading = false;
        });
        builder.addCase(getLevelsByUser.rejected, (state, action) => {
            state.levelsByUser.error = action?.error?.message || null;
            state.levelsByUser.loading = false;
        });
    },
});

export const { } = LevelsSlice.actions;

export default LevelsSlice.reducer;
