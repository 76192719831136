import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import {
  applyBenchmarking,
  fetchAIBenchMarking,
} from "../../helpers/custom_backend_helper";
import { getUserData } from "../../utils/auth";
import ColumnLoader from "../../Components/Custom/ColumnLoader";
import { useNavigate } from "react-router-dom";
import { PulseLoader, RiseLoader } from "react-spinners";
import {
  getBenchmarking,
  getJobDescriptionById,
  getMarketPricing,
} from "../../slices/thunks";
import Skeleton from "react-loading-skeleton";
import { useQueryParams } from "../../utils/queryParams";
import ReactSelect from "react-select";
import { buildOptions } from "../../utils/dataFormat";
import { pushMarketPricing } from "../../slices/marketPricing/reducer";

export const ApplyBenchMarking = ({
  onCloseClick,
  isOpen,
  activeValues,
  actionType,
}) => {
  const storeActiveJobDescription = useSelector(
    (state) => state.JobDescription?.jobDescriptionById?.jobDescription
  );
  const storeJobArchitecture = useSelector(
    (state) => state.JobArchitecture?.jobArchitectureById?.jobArchitecture
  );
  const storeLevels = useSelector((state) => state.GeneralLevels);
  const [data, setData] = useState([]);
  const [loadingAI, setLoadingAI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [records, setRecords] = useState([]);
  const userData = getUserData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [applyLevels, setApplyLevels] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getAIBenchMarkingData();
    }
    setApplyLevels(false);
    setChecked([]);
  }, [isOpen]);

  useEffect(() => {
    let code = [];
    checked?.map((ele) => {
      code.push(ele?.recordId);
    });
    setRecords(code);
  }, [checked]);

  // useEffect(() => {
  //   if (queryParams?.jdId && isOpen) {
  //     dispatch(getJobDescriptionById(queryParams?.jdId));
  //   }
  // }, [isOpen]);

  const getAIBenchMarkingData = (selectedLevel) => {
    let prompt = {
      jobDescription:
        activeValues?.jobDescription ||
        storeActiveJobDescription?.jobDescription,
      userId: userData?.userId,
      level: selectedLevel ? selectedLevel : activeValues?.level || storeActiveJobDescription?.level,
      internalJobtitle:
        activeValues?.jobTitle || storeActiveJobDescription?.internalJobtitle,
      jobDescription:
        activeValues?.jobDescription ||
        storeActiveJobDescription?.jobDescription,
      responsibilities:
        activeValues?.responsibilities ||
        storeActiveJobDescription?.responsibilities,
      skillsAbilities:
        activeValues?.skills_abilities ||
        storeActiveJobDescription?.skills_abilities,
    };
    setLoadingAI(true);
    fetchAIBenchMarking(prompt)
      .then((resp) => {
        if (resp?.data && resp?.data.length) {
          setData(resp.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingAI(false);
      });
  };

  const handleSelect = (ele, isChecked) => {
    setChecked((prevItems) => {
      if (isChecked) {
        return [...prevItems, ele];
      } else {
        return prevItems.filter((item) => item?.recordId !== ele?.recordId);
      }
    });
  };

  const handleSubmit = () => {
    let body = {
      jobDescriptionId: activeValues?.jdID,
      jobCode: records,
      allLevels: applyLevels ? true : false,
    };
    setLoading(true);
    applyBenchmarking(body)
      .then((res) => {
        if (res?.metadata?.status === 200) {
          onCloseClick();
          dispatch(getBenchmarking(userData?.userId));
          setLoading(false);
          dispatch(pushMarketPricing(res?.data?.marketData))
            // dispatch(
            //   getMarketPricing({
            //     jobCode: records,
            //   })
            // );
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {});
  };

  return (
    <div>
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        id="offcanvas-lg"
        toggle={onCloseClick}
        className="benchmarking-offcanvas border-0 bg-light"
      >
        <OffcanvasHeader
          className="d-flex align-items-center bg-primary p-3 offcanvas-header-dark"
          toggle={onCloseClick}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="m-0 me-2 text-white d-flex align-items-center">
              Benchmarking
            </span>
            <ReactSelect
              id="level"
              className=" mb-0 me-4"
              name="level"
              onChange={(event) => {
                getAIBenchMarkingData(event?.label);
              }}
              options={buildOptions(
                storeLevels?.levels,
                "levelCode",
                "levelCode"
              )}
              placeholder={"Select Level.."}
            />
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="p-2">
          <div className="">
            <div className={!loadingAI && checked ? " mb-4 pb-2 " : ""}>
              {!loadingAI && (
                <>
                  {data?.map((ele, ind) => {
                    return (
                      <div
                        className="bg-white mb-2 rounded position-relative"
                        key={ind}
                      >
                        <button className="btn btn-sm pt-2 position-absolute end-0">
                          <input
                            type="checkbox"
                            className="btn-check"
                            id={`btn-check-outlined-${ind}`}
                            checked={checked.some(
                              (item) => item.recordId === ele.recordId
                            )}
                            onChange={(event) => {
                              handleSelect(ele, event.target.checked);
                            }}
                          />
                          <label
                            className="btn btn-outline-primary px-3 p-1"
                            htmlFor={`btn-check-outlined-${ind}`}
                          >
                            Select
                          </label>
                        </button>
                        <div className="p-2">
                          <p className="title mb-1">
                            <span className="fw-semibold">Job Title : </span>
                            {ele?.recordDetails["surveyJobTitle"]}
                          </p>
                          <p className="mb-1">
                            <span className="fw-semibold">Job Code : </span>
                            {ele?.recordId}
                          </p>
                          <p className="mb-1">
                            <span className="fw-semibold">
                              Match Percentage :{" "}
                            </span>
                            {parseInt(ele?.matchPercentage)}%
                          </p>

                          <p className="mb-1">
                            <span className="fw-semibold">
                              Job Description :{" "}
                            </span>
                            {ele?.recordDetails["surveyJobDescription"]}
                          </p>
                          <Row>
                            <Col>
                              <div>
                                <p className="mb-1">
                                  <span className="fw-semibold">
                                    Provider Name :
                                  </span>
                                  {ele?.recordDetails["surveyProviderName"]}
                                </p>
                                <p className="mb-1">
                                  <span className="fw-semibold">Datacut :</span>
                                  {ele?.recordDetails["surveyDataCut"]}
                                </p>
                                <p className="mb-1">
                                  <span className="fw-semibold">
                                    Job Family Group :
                                  </span>
                                  {ele?.recordDetails["surveyJobFamilyGroup"]}
                                </p>
                                <p className="mb-1">
                                  <span className="fw-semibold">
                                    Job Family :{" "}
                                  </span>
                                  {ele?.recordDetails["surveyJobFamily"]}
                                </p>
                                <p className="mb-1">
                                  <span className="fw-semibold">
                                    Job Level :{" "}
                                  </span>
                                  {ele?.recordDetails["surveyJobLevel"]}
                                </p>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <p className="mb-1">
                                  <span className="fw-semibold">
                                    Job Area :{" "}
                                  </span>
                                  {ele?.recordDetails["surveyJobProfile"]}
                                </p>
                                <p className="mb-1">
                                  <span className="fw-semibold">
                                    Job Category :{" "}
                                  </span>
                                  {ele?.recordDetails["surveyJobCategory"]}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                  {data?.length > 0 && (
                    <div className="text-center">
                      <Button
                        className="btn btn-sm btn-outline-primary w-25 mb-5 "
                        color="primary"
                      >
                        Load more...
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            {checked?.length > 0 && (
              <div
                className="sticky-footer bg-light float-end pe-4 d-flex justify-content-end
                   "
              >
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className="checkbox mt-0  me-1"
                      id={"checkbox"}
                      checked={applyLevels}
                      onChange={(event) => {
                        setApplyLevels(event.target.checked);
                      }}
                    />
                    <Label className="me-4 mb-0">Apply for all Levels</Label>
                  </div>

                  <Button
                    className="btn theme-cancel-btn px-4"
                    color="white"
                    onClick={(e) => {
                      setChecked([]);
                      onCloseClick();
                    }}
                  >
                    Cancel
                  </Button>
                  {/* test */}
                  <Button
                    className="btn mx-2 px-4 "
                    color="primary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {loading ? (
                      <PulseLoader size={8} color="#ffffff" />
                    ) : (
                      "Apply"
                    )}
                  </Button>
                </div>
              </div>
            )}
            {!loadingAI && data?.length == 0 && (
              <p className="text-center fw-semibold fs-5 p-4">
                No matching records found
              </p>
            )}
            {loadingAI && (
              <Skeleton
                baseColor={"#e6eef5"}
                height={250}
                count={5}
                className={"mb-2"}
              />
            )}
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};
export default ApplyBenchMarking;
