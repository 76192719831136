import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import TableContainer from "../../Components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { FileImport } from "../../Components/Custom/FileImport";
import { getUserData } from "../../utils/auth";
import {
  getDashboardCount,
  getEmployees,
  getEmployeesAll,
} from "../../slices/thunks";
import IncumbentView from "./View";
import Loader from "../../Components/Custom/Loader";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import useUpdateRouteWithQueryParams from "../../Components/Hooks/queryParams";
import RangeSpread from "../../Components/Custom/RangeSpread";
import IncumbentImpactAnalysisFilters from "./Filters";

export const IncumbentImpactAnalysis = () => {
  const dispatch = useDispatch();
  const userData = getUserData();
  const [viewModal, setViewModal] = useState(false);
  const [activeId, setActiveId] = useState();
  const [importModal, setImportModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const storeIncumbentAnalysis = useSelector(
    (state) => state?.IncumbentAnalysis?.employees
  );
  const [pageIndex, setPageIndex] = useState();
  const updateRouteWithParams = useUpdateRouteWithQueryParams();
  const queryParams = parseQueryParams();
  const [filterModal, setFilterModal] = useState(false);
  const storeCount = useSelector((state) => state?.Dashboard?.count);
  const { page, limit, ...filterQueryParams } = queryParams;
  const toggleFilterModal = () => setFilterModal(!filterModal);

  // useEffect(() => {
  //   if (storeIncumbentAnalysis?.allList?.length == 0) {
  //     dispatch(getEmployeesAll(`?limit=45`));
  //   }
  // }, []);

  useEffect(() => {
    if (Object?.entries(storeCount?.list)?.length == 0) {
      dispatch(getDashboardCount());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      let query = getQueryString(queryParams);
      dispatch(getEmployees(query));
      setPageIndex(queryParams?.page);
    } else {
      dispatch(getEmployees(""));
    }
  }, []);

  useEffect(() => {
    if (
      Object.entries(filterQueryParams)?.length > 0 &&
      !storeIncumbentAnalysis?.loading &&
      storeIncumbentAnalysis?.meta?.count
    ) {
      let query = getQueryString({
        ...filterQueryParams,
        limit: storeIncumbentAnalysis?.meta?.count,
      });
      dispatch(getEmployeesAll(query));
    } else if (
      !storeCount.loading &&
      storeCount?.list?.employeesCount &&
      Object.entries(filterQueryParams)?.length == 0
    ) {
      dispatch(getEmployeesAll(`?limit=${storeCount?.list?.employeesCount}`));
    }
  }, [storeCount, storeIncumbentAnalysis?.meta]);

  const handlePageClick = (value) => {
    if (value) {
      setPageIndex(value);
      let query = { page: value, limit: 10 };
      let body = `?page=${value}&limit=10`;
      updateRouteWithParams(query);
      dispatch(getEmployees(body));
    }
  };

  const columns = [
    {
      Header: "Emp ID",
      accessor: "empID",
      filterable: true,
      visible: true,
      Cell: (cellProps) => {
        return (
          <h6 className="text-primary fw-semibold mb-0">{cellProps?.value}</h6>
        );
      },
    },
    {
      Header: "Name",
      accessor: "empName",
      filterable: true,
      visible: true,
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      Header: "Gender",
      accessor: "gender",
      filterable: true,
      visible: true,
    },
    {
      Header: "Job Code",
      accessor: "jobCode",
      filterable: true,
      visible: true,
    },
    {
      Header: "Job Title",
      accessor: "jobTitle",
      filterable: true,
      visible: true,
      minWidth: "150px",
      maxWidth: "150px",
    },
    {
      Header: "Manager",
      accessor: "manager",
      filterable: true,
      visible: true,
    },
    {
      Header: "Department",
      accessor: "department",
      filterable: true,
      visible: true,
    },
    {
      Header: "Current Salary",
      accessor: "currentSalary",
      filterable: true,
      visible: true,
      minWidth: "100px",
      Cell: (cellProps) => {
        return <>$ {cellProps?.value}</>;
      },
    },
    {
      Header: "City",
      accessor: "city",
      filterable: true,
      visible: true,
    },
    {
      Header: "State",
      accessor: "state",
      filterable: true,
      visible: true,
      minWidth: "130px",
    },
    {
      Header: "Level",
      accessor: "level",
      filterable: true,
      visible: false,
    },
    {
      Header: "Base Composite",
      accessor: "baseComposite",
      filterable: true,
      visible: false,
    },
    {
      Header: "Min Composite",
      accessor: "minComposite",
      filterable: true,
      visible: false,
    },
    {
      Header: "Max Composite",
      accessor: "maxComposite",
      filterable: true,
      visible: false,
    },
    {
      Header: "Comp-Ratio",
      accessor: "compRationEE",
      filterable: true,
      visible: false,
    },
    {
      Header: "Range Spread",
      accessor: "rangeSpread",
      filterable: true,
      visible: true,
      minWidth: "200px",
      Cell: (cellProps) => {
        return (
          <RangeSpread
            min={parseInt(cellProps?.row?.original?.minimum)}
            max={parseInt(cellProps?.row?.original?.maximum)}
            mid={parseInt(cellProps?.row?.original?.midPoint)}
            salary={cellProps?.row?.original?.currentSalary}
            percentage={cellProps?.row?.original?.compRationEE}
          />
        );
      },
    },
    {
      Header: "Outlier",
      accessor: "outlier",
      filterable: true,
      visible: true,
      minWidth: "120px",
      Cell: (cellProps) => {
        return (
          <h6
            className={
              cellProps?.row?.original?.outlier == "Within Range"
                ? "text-success mb-0 fw-semibold text-center"
                : "text-danger mb-0 fw-semibold text-center"
            }
          >
            {cellProps?.row?.original?.outlier || "-"}
          </h6>
        );
      },
    },
  ];

  const exportHeader = [
    {
      header: "empID",
      dataKey: "empID",
    },
    {
      header: "EmpName",
      dataKey: "EmpName",
    },
    {
      header: "JobCode",
      dataKey: "JobCode",
    },
    {
      header: "JobTitle",
      dataKey: "JobTitle",
    },
    {
      header: "manager",
      dataKey: "manager",
    },
    {
      header: "department",
      dataKey: "department",
    },
    {
      header: "currentSalary",
      dataKey: "currentSalary",
    },
    {
      header: "city",
      dataKey: "city",
    },
    {
      header: "state",
      dataKey: "state",
    },
  ];

  function handleAcceptedFiles(acceptedFiles) {
    const file = acceptedFiles[0];
    if (file) {
      const formData = new FormData();
      formData.append("userId", userData?.userId);
      Object.keys(file).forEach((key) => {
        formData.append(`empxl`, file);
      });
      setLoader(true);
      PostEmployeeFile(formData)
        .then((res) => {
          if (
            res?.metadata?.status === 200 ||
            res?.metadata?.message == "Success"
          ) {
            setImportModal(!importModal);
            setLoader(false);
            dispatch(getEmployees(userData?.userId));
          }
        })
        .catch((error) => {
          console.error(error);
          setLoader(false);
        });
    }
  }

  const rowOnclickAction = (id) => {
    setViewModal(!viewModal);
    setActiveId(id);
  };

  return (
    <React.Fragment>
      <div className="page-content incumbent-impact-table">
        <Row>
          <Col md={12} lg={12} xxl={12}>
            <TableContainer
              loading={storeIncumbentAnalysis?.loading}
              columns={columns}
              data={storeIncumbentAnalysis?.employees}
              isGlobalFilter={true}
              columnFilter={true}
              isAddUserList={false}
              customPageSize={10}
              showPagination={true}
              divClass="table-responsive table-card mb-0 p-3"
              tableClass="align-middle table-wrap"
              theadClass="table-light"
              SearchPlaceholder="Search for..."
              showButton={false}
              exportButton={true}
              downloadFileName={"Incumbent Impact Analysis"}
              importButton={true}
              importAction={() => {
                setImportModal(!importModal);
              }}
              exportHeader={exportHeader}
              isDefaultPagination={false}
              isRowOnclick={true}
              rowIdOnclick={"empId"}
              rowOnclickAction={rowOnclickAction}
              pageTitle={"Incumbent Impact Analysis"}
              isCustomPagination={true}
              customPaginationMeta={{
                ...storeIncumbentAnalysis?.meta,
                pageRecords: storeIncumbentAnalysis?.employees?.length,
                pageIndex: pageIndex,
              }}
              handlePageClick={handlePageClick}
              isPageHeader={true}
              downloadList={storeIncumbentAnalysis?.allList}
              rowSelectionID={"empID"}
              isFilterBtn={true}
              filterAction={() => {
                setFilterModal(!filterModal);
              }}
              dispatchTableData={getEmployees}
              isUserId={false}
            />
          </Col>
        </Row>
      </div>
      <IncumbentView
        isOpen={viewModal}
        onCloseClick={() => setViewModal(false)}
        id={activeId}
      />
      <FileImport
        show={importModal}
        onCloseClick={setImportModal}
        onClick={() => {}}
        handleAcceptedFiles={handleAcceptedFiles}
        loading={loader}
      />
      <IncumbentImpactAnalysisFilters
        show={filterModal}
        onCloseClick={toggleFilterModal}
      />
      {storeIncumbentAnalysis?.loading && <Loader />}
    </React.Fragment>
  );
};
export default IncumbentImpactAnalysis;
