export const buildOptions = (list, labelName, valueName) => {
  if (!list?.length) {
    return [];
  }
  let res = [];
  list?.forEach((element) => {
    res.push({ label: element[labelName], value: element[valueName] });
  });
  return res;
};
export const buildOptionsWithId = (list, labelName, valueName, id) => {
  if (!list?.length) {
    return [];
  }
  let res = [];
  list?.forEach((element) => {
    res.push({
      label: `${element[id]} - ${element[labelName]}`,
      value: element[valueName],
    });
  });
  return res;
};
export const getOption = (name, value) => {
  return {
    label: name,
    value: value,
  };
};
export const getValues = (list, name) => {
  let res = [];
  list?.forEach((element) => {
    if (name) {
      res.push(element[name]);
    } else res.push(element.value);
  });
  return res;
};
export const getOptionByValue = (value) => {
  return {
    label: value,
    value: value,
  };
};
export const buildMultiOptions = (list) => {
  if (!list?.length) {
    return [];
  }
  let res = [];
  list.forEach((element) => {
    res.push({ label: element, value: element });
  });
  return res;
};
export const UTCToLocalDateTime = (utcTime, time = false) => {
  if (!utcTime) {
    return "-";
  }
  const localTime = moment.utc(utcTime).local();
  const formattedLocalDate = new Date(localTime).toLocaleDateString();
  if (time) {
    return formattedLocalDate + " " + moment(localTime).format("LT");
  } else {
    return formattedLocalDate;
  }
};
export const formatSalary = (salary) => {
  if (!salary) return "";
  return parseInt(salary).toLocaleString("en-US");
};