import Select from "react-select";
import makeAnimated from "react-select/animated";

const ReactSelect = ({ ...props }) => {
  const baseHeight = 33.5;
  const animatedComponents = makeAnimated();
  const reactSelectStyles = () => ({
    option: (provided) => ({
      ...provided,
    }),
    control: (provided) => {
      return {
        ...provided,
        minHeight: baseHeight,
        backgroundColor:
          props?.isFilledColor && props.value?.value
            ? "#e6eef5"
            : provided.backgroundColor,
        borderColor:
          props?.isFilledColor && props.value?.value
            ? "#e6eef5"
            : provided.borderColor,
      };
    },
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  });

  const reactSelectTheme = (theme) => {
    let primaryColor = "#08569a";

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: primaryColor,
      },
      spacing: {
        ...theme.spacing,
        controlHeight: baseHeight,
        baseUnit: 3,
      },
    };
  };

  return (
    <Select
      {...props}
      components={animatedComponents}
      theme={(theme) => reactSelectTheme(theme)}
      className={"custom-react-select " + (props?.className || "")}
      menuPortalTarget={document.body}
      styles={reactSelectStyles()}
      placeholder={props?.placeholder ? props?.placeholder : "Select"}
    />
  );
};

export default ReactSelect;
