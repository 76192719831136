import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
      <React.Fragment>
        <footer className="footer bg-light">
          <Container fluid>
            <Row>
              <Col sm={6}>{new Date().getFullYear()} © Compscale.</Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                  Created & Owned by Planet C LLC
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
};

export default Footer;