import { Position } from "reactflow";

export const nodeColor = (node) => {
    switch (node?.type) {
        case "input":
            return "#e6eef5";
        case "output":
            return "#feeed2";
        default:
            return "#eeeeee";
    }
};

const nodeDefaults = {
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
};
const internalLevelStyle = {
    style: {
        backgroundColor: "#e6eef5",
        color: "primary",
        border: "transparent",
        fontWeight: "bold",
        width: 250
    },
};
const externalLevelStyle = {
    style: {
        backgroundColor: "#feeed2",
        color: "primary",
        border: "transparent",
        fontWeight: "bold",
        width: 250
    },
};

let markerStyle = {
    markerEnd: {
        width: 20,
        height: 20,
        color: "#08569a",
    },
    style: {
        strokeWidth: 1,
        stroke: "#08569a",
    },
};

export const getInitialNodes = (internalData, externalData) => {
    let internalNodes = [],
        externalNodes = [];
    if (internalData?.length > 0 && externalData?.length > 0) {
        internalData?.forEach((ele, ind) => {
            let internalObj = {
                id: `I-${ele?.levelCode}`,
                type: "input",
                data: { label: `${ele?.levelCode} - ${ele?.management}` },
                position: { x: 10, y: 50 * ind },
                ...nodeDefaults,
                ...internalLevelStyle,
            };
            internalNodes?.push(internalObj);
        });
        externalData?.forEach((ele, ind) => {
            let externalObj = {
                id: `E-${ele?.externalLevel}`,
                type: "output",
                data: { label: `${ele?.externalLevel} - ${ele?.jobType}` },
                position: { x: 500, y: 50 * ind },
                ...nodeDefaults,
                ...externalLevelStyle,
            };
            externalNodes?.push(externalObj);
        });
    }
    return [...internalNodes, ...externalNodes];
};
export const getInitialEdges = (edges) => {
    let allEdges = [];
    edges?.length > 0 &&
        edges?.forEach((ele) => {
            let obj = {
                id: `I-${ele?.internalLevel}->E-${ele?.externalLevel}`,
                source: `I-${ele?.internalLevel}`,
                target: `E-${ele?.externalLevel}`,
                ...markerStyle,
                type: "buttonedge",
            };
            allEdges?.push(obj);
        });
    return allEdges;
};

export const getNodes = (data, type) => {
    let nodes = [];
    data?.forEach((ele, ind) => {
        let obj = {
            id: `I-${ele?.name}`,
            type: "input",
            data: { label: `${ele?.name}` },
            position: { x: 10, y: 50 * ind },
            ...nodeDefaults,
        };
        type == "input"
            ? (obj["style"] = internalLevelStyle?.style)
            : (obj["style"] = externalLevelStyle?.style);
        nodes?.push(obj);
    });
    return nodes;
};
export const getOutputNodes = (data, type) => {
    let nodes = [];
    data?.forEach((ele, ind) => {
        let obj = {
            id: `E-${ele}`,
            type: "output",
            data: { label: `${ele}` },
            position: { x: 500, y: 60 * ind },
            ...nodeDefaults,
            ...externalLevelStyle,
        };

        nodes?.push(obj);
    });
    return nodes;
};
