import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../Components/Custom/ReactSelect";
import { buildOptions, getOptionByValue } from "../../utils/dataFormat";
import { GenderOptions, OutlierOptions } from "../../utils/dataOptions";
import { Formik } from "formik";
import { getQueryString, parseQueryParams } from "../../utils/queryParams";
import {
  getEmployees,
} from "../../slices/thunks";
import { getUserData } from "../../utils/auth";
import SimpleBar from "simplebar-react";

const defaultValues = {
  gender: "",
  manager: "",
  department: "",
  outlier: "",
  jobCode: "",
  jobTitle: "",
};

const IncumbentImpactAnalysisFilters = ({ show, onCloseClick }) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [filterValues, setFilterValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = parseQueryParams();

  const userData = getUserData();
  const storeIncumbentAnalysis = useSelector(
    (state) => state.IncumbentAnalysis.employees
  );


  useEffect(() => {
    if (Object?.entries(queryParams).length > 0 && show) {
      setInitialValues({ ...initialValues, ...queryParams });
    } else setInitialValues(defaultValues);
  }, [show]);

  const applyFilters = (values) => {
    console.log(values, "values");

    let data = {
      jobTitle: values?.jobTitle,
      jobCode: values?.jobCode,
      manager: values?.manager,
      department: values?.department,
      gender: values?.gender,
      outlier: values?.outlier,
    };
    const queryString = getQueryString(data);
    const urlWithParams = `/Incumbent-Impact-Analysis${queryString}`;
    navigate(urlWithParams);
    dispatch(getEmployees(`${queryString}`));
    onCloseClick();
  };

  const clearFilters = (e) => {
    e.preventDefault();
    onCloseClick();
    navigate("/Incumbent-Impact-Analysis");
    dispatch(getEmployees(""));
    setInitialValues(initialValues);
    setFilterValues({});
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader
        className="bg-soft-primary"
        toggle={onCloseClick}
        // toggle={() => onCloseClick(!show)}
      >
        <h5 className=" text-primary mb-0 ">
          {" "}
          FILTERS - Incumbent Impact Analysis
        </h5>
      </OffcanvasHeader>

      <OffcanvasBody>
        <Formik enableReinitialize initialValues={initialValues}>
          {({
            errors,
            handleChange,
            values,
            setFieldValue,
            isSubmitting,
            dirty,
            setFieldTouched,
            setFieldError,
          }) => {

            return (
              <>
                <Row>
                  <SimpleBar className="modalbody-scroll">
                    <Col md={12} className="mb-4">
                      <Label>Gender</Label>
                      <ReactSelect
                        id="gender"
                        className=" mb-0"
                        name="gender"
                        value={
                          values?.gender
                            ? getOptionByValue(values?.gender)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("gender", event?.value);
                        }}
                        options={GenderOptions}
                      />
                    </Col>
                    {/* <Col md={12} className="mb-4">
                      <Label>Job Title</Label>
                      <ReactSelect
                        id="jobTitle"
                        className=" mb-0"
                        name="jobTitle"
                        value={
                          values?.jobTitle
                            ? getOptionByValue(values?.jobTitle)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("jobTitle", event?.label);
                        }}
                        options={buildOptions(
                          storeIncumbentAnalysis?.allList,
                          "jobTitle",
                          "jobTitle"
                        )}
                      />
                    </Col> */}
                    <Col md={12} className="mb-4">
                      <Label>Job Code</Label>
                      <ReactSelect
                        id="jobCode"
                        className=" mb-0"
                        name="jobCode"
                        value={
                          values?.jobCode
                            ? getOptionByValue(values?.jobCode)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("jobCode", event?.label);
                        }}
                        options={buildOptions(
                          storeIncumbentAnalysis?.allList,
                          "jobCode",
                          "jobCode"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Manager</Label>
                      <ReactSelect
                        id="manager"
                        className=" mb-0"
                        name="manager"
                        value={
                          values?.manager
                            ? getOptionByValue(values?.manager)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("manager", event?.label);
                        }}
                        options={buildOptions(
                          storeIncumbentAnalysis?.allList,
                          "manager",
                          "manager"
                        )}
                      />
                    </Col>
                    <Col md={12} className="mb-4">
                      <Label>Department</Label>
                      <ReactSelect
                        id="department"
                        className=" mb-0"
                        name="department"
                        value={
                          values?.department
                            ? getOptionByValue(values?.department)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("department", event?.label);
                        }}
                        options={buildOptions(
                          storeIncumbentAnalysis?.allList,
                          "department",
                          "department"
                        )}
                      />
                    </Col>

                    {/* <Col md={12} className="mb-4">
                      <Label>Outlier</Label>
                      <ReactSelect
                        id="outlier"
                        className=" mb-0"
                        name="outlier"
                        value={
                          values?.outlier
                            ? getOptionByValue(values?.outlier)
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("outlier", event?.label);
                        }}
                        options={OutlierOptions}
                      />
                    </Col> */}
                  </SimpleBar>
                </Row>

                <div className=" d-flex justify-content-end position-absolute bottom-0 py-4 pe-4 bg-white w-100">
                  <Button
                    className="btn theme-cancel-btn me-2 px-4 w-100"
                    color="white"
                    onClick={(e) => {
                      clearFilters(e);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn px-4 w-100 me-3"
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      applyFilters(values);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>{" "}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default IncumbentImpactAnalysisFilters;