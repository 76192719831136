import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllGeneralLevels as getAllGeneralLevelsApi,
    getAllGernalLevelsByUser as getGeneralLevelsByUserApi,
} from "../../helpers/custom_backend_helper";
export const getGeneralLevels = createAsyncThunk(
    "level/getGeneralLevels",
    async () => {
        try {
            const response = await getAllGeneralLevelsApi();
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);
export const getGeneralLevelsByUser = createAsyncThunk(
    "level/getGeneralLevelsByUser",
    async (id) => {
        try {
            const response = await getGeneralLevelsByUserApi(id);
            const data = await response.data;
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);
