import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColors";

const GroupedBarChart = ({ dataColors,xAxisData }) => {
  var chartGroupbarColors = getChartColorsArray(dataColors);
  const series = [
    { name: "No: of Jobs", data: [44, 55, 41, 64, 22, 43, 21] },
    {
      name: "No: of Employees",
      data: [53, 32, 33, 52, 13, 44, 32],
    },
  ];

  var options = {
    chart: {
      type: "bar",
      height: 410,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: xAxisData,
    },
    colors: chartGroupbarColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bar"
        height={370}
      />
    </React.Fragment>
  );
};
export default GroupedBarChart;
