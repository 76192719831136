import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useUpdateRouteWithQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateRouteWithParams = (paramsObj) => {
    const queryParams = new URLSearchParams(location.search);

    for (const key in paramsObj) {
      if (paramsObj.hasOwnProperty(key)) {
        queryParams.set(key, paramsObj[key]);
      }
    }

    const newSearch = queryParams.toString();
    navigate({ search: `?${newSearch}` });
  };

  return updateRouteWithParams;
};

export default useUpdateRouteWithQueryParams;
